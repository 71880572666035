import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  TextField,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Flex from 'components/Flex';
import Link from 'components/Link';
import {
  DEFAULT_LOCATION,
  LocationField,
  LocationSearchField,
} from 'components/LocationField';
import OrganizationForm from 'components/OrganizationForm';
import PaymentsHistoryTable from 'components/PaymentsHistoryTable';
import UserPlanStatus from 'components/UserPlanStatus';
import { Title2 } from 'components/Text';
import { useOrganization } from 'contexts/Organization';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ORGANIZATION_TYPES, VALIDATORS } from 'utils/constants';
import Spinner from 'components/Spinner';

const DISABLE_MULTIPLE_ORGS =
  process.env.REACT_APP_DISABLE_MULTIPLE_ORGS === 'true';

const getDefaultValues = (user) => ({
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  company: user?.company || '',
  phone: user?.phone || '',
  email: user?.email || '',
  address: user?.address || DEFAULT_LOCATION,
  organizations: user?.organizations,
});

const UserForm = ({
  user,
  userPlans,
  paymentsHistory,
  onSubmit,
  isLoading,
  isFetchingPaymentHistory,
  isFetchingUserPlans,
}) => {
  const { organizations, refreshOrganizations, isFetching } = useOrganization();
  const [expandedOrg, setExpandedOrg] = useState();
  const [newOrg, setNewOrg] = useState();
  const methods = useForm({
    defaultValues: getDefaultValues(user),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = methods;

  const handleAddOrganization = () => {
    const orgId = uuidv4();
    setExpandedOrg(orgId);
    setNewOrg({
      id: orgId,
      name: '',
      mission: '',
      programDesign: '',
      expectedResults: '',
      organizationType: ORGANIZATION_TYPES.NON_PROFIT,
      statementOfNeed: '',
      annualBudget: '',
      employeeInternalId: '',
    });
  };

  const handleOnCancelOrg = (isNew) => {
    if (isNew) {
      setNewOrg(undefined);
    } else {
      refreshOrganizations();
    }
    setExpandedOrg(undefined);
  };

  const handleOnOrgExpand = (orgId) => {
    setExpandedOrg(orgId);
  };

  useEffect(() => {
    reset(getDefaultValues(user));
  }, [user]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box component={Card} width={{ xs: '100%', md: 568 }} mb={6}>
        <CardContent>
          <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Flex alignItems="center" justifyContent="space-between" mb={3}>
                <Title2 fontFamily="Playfair Display" my={0} color="ui">
                  Basic Info
                </Title2>
                <Link href="/change-password">Change my password</Link>
              </Flex>
              <Box mb={6}>
                <Box mb={2}>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        placeholder="youremail@domain.com"
                        label="Email"
                        disabled
                        error={!!errors?.email}
                        helperText={errors?.email?.message}
                        labelProps={{
                          my: 0,
                          color: 'ui',
                        }}
                      />
                    )}
                  />
                </Box>
                <Box mb={2}>
                  <Controller
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'First name is required',
                      },
                    }}
                    name="firstName"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="First name"
                        placeholder="John"
                        error={!!errors?.firstName}
                        helperText={errors?.firstName?.message}
                        labelProps={{
                          my: 0,
                          color: 'ui',
                        }}
                      />
                    )}
                  />
                </Box>
                <Box mb={2}>
                  <Controller
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Last name is required',
                      },
                    }}
                    name="lastName"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        placeholder="Doe"
                        label="Last name"
                        error={!!errors?.lastName}
                        helperText={errors?.lastName?.message}
                        labelProps={{
                          my: 0,
                          color: 'ui',
                        }}
                      />
                    )}
                  />
                </Box>
                <Box mb={2}>
                  <Controller
                    control={control}
                    rules={VALIDATORS.phone}
                    name="phone"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Phone"
                        error={!!errors?.phone}
                        helperText={errors?.phone?.message}
                        labelProps={{
                          my: 0,
                          color: 'ui',
                        }}
                      />
                    )}
                  />
                </Box>
                <Box mb={2}>
                  <Controller
                    control={control}
                    name="company"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Company"
                        error={!!errors?.company}
                        helperText={errors?.company?.message}
                        labelProps={{
                          my: 0,
                          color: 'ui',
                        }}
                      />
                    )}
                  />
                </Box>
                <Box mb={2}>
                  <LocationField>
                    <Controller
                      name="address"
                      control={control}
                      render={({ field }) => (
                        <LocationSearchField
                          onMapSelect={(location) => {
                            field.onChange(location);
                          }}
                          location={field.value}
                        />
                      )}
                    />
                  </LocationField>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                  size="large"
                  sx={{ px: 6 }}
                >
                  Save changes
                  {isLoading && (
                    <Box
                      component={CircularProgress}
                      ml={2}
                      size={16}
                      color="inherit"
                    />
                  )}
                </Button>
              </Box>
            </Box>
          </FormProvider>
        </CardContent>
      </Box>
      <Box component={Card} width={{ xs: '100%', md: 568 }} mb={6}>
        <CardContent>
          <Flex alignItems="center" justifyContent="space-between" mb={2}>
            <Title2 fontFamily="Playfair Display" my={0} color="ui">
              Organization Information
            </Title2>
            {!DISABLE_MULTIPLE_ORGS && (
              <IconButton onClick={() => handleAddOrganization()}>
                <Add color="success" />
              </IconButton>
            )}
          </Flex>
          {isFetching ? (
            <Spinner />
          ) : (
            <>
              {organizations?.map((org) => (
                <OrganizationForm
                  key={org.id}
                  userId={user?.id}
                  organization={org}
                  expanded={org.id === expandedOrg}
                  onExpand={handleOnOrgExpand}
                  onCancel={handleOnCancelOrg}
                  onSuccessDelete={handleOnCancelOrg}
                  onSuccessSave={handleOnCancelOrg}
                />
              ))}
              {newOrg && (
                <OrganizationForm
                  organization={newOrg}
                  userId={user?.id}
                  isNew
                  expanded={newOrg.id === expandedOrg}
                  onExpand={handleOnOrgExpand}
                  onCancel={handleOnCancelOrg}
                  onSuccessDelete={handleOnCancelOrg}
                  onSuccessSave={handleOnCancelOrg}
                />
              )}
            </>
          )}
        </CardContent>
      </Box>

      <Box component={Card} width={{ xs: '100%', md: 568 }} mb={6}>
        <CardContent>
          <Flex alignItems="center" justifyContent="space-between" mb={2}>
            <Title2 fontFamily="Playfair Display" my={0} color="ui">
              User Plan
            </Title2>
          </Flex>
          <UserPlanStatus
            isFetching={isFetchingUserPlans}
            userPlans={userPlans}
          />
        </CardContent>
      </Box>
      <Box component={Card} width={{ xs: '100%', md: 568 }}>
        <CardContent>
          <Flex alignItems="center" justifyContent="space-between" mb={2}>
            <Title2 fontFamily="Playfair Display" my={0} color="ui">
              Payment History
            </Title2>
          </Flex>
          <PaymentsHistoryTable
            isFetching={isFetchingPaymentHistory}
            paymentsHistory={paymentsHistory}
          />
        </CardContent>
      </Box>
    </Box>
  );
};

export default UserForm;
