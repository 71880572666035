import GrantsList from 'components/GrantsList';
import { GRANTS_TYPES } from 'utils/constants';

const LettersPage = () => (
  <GrantsList
    type={GRANTS_TYPES.LETTER}
    path="letters"
    header="Letters"
    subheader="The Grant Suite search bar can help you in finding the most recent letters for your organization or business. Use interests, focuses, and keywords to narrow your search."
    listHeader="Featured Letters"
    enableOverlay={false}
    cardProps={{ height: 215 }}
  />
);

export default LettersPage;
