import Flex from 'components/Flex';
import { Large, Title2 } from 'components/Text';

const NotFoundPage = () => (
  <Flex center flexDirection="column" height="100vh" width="100%">
    <Title2 mt={0} mb={3} fontFamily="Playfair Display">
      Page not found
    </Title2>
    <Large my={0}>
      The page you are looking has either moved or has been removed.
    </Large>
  </Flex>
);

export default NotFoundPage;
