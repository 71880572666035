import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Box, Container, Hidden, IconButton } from '@mui/material';
import Flex from 'components/Flex';
import { useLayout } from 'contexts/Layout';
import useValidateUserAccess from 'hooks/useValidateUserAccess';
import { Outlet } from 'react-router-dom';
import Drawer from './Drawer';
import FaqDrawer from './FaqDrawer';
import MainContent from './MainContent';

export const DefaultContainer = ({ children, ...props }) => (
  <Box
    component={Container}
    bgcolor="common.white"
    py={6}
    height="100%"
    overflow="hidden"
    {...props}
  >
    {children}
  </Box>
);

const Layout = () => {
  const { drawerOpen, toggleDrawer, isFaqOpen, toggleFaq } = useLayout();
  const { isInitialized } = useValidateUserAccess();

  if (!isInitialized) return null;

  return (
    <Flex height="100vh" position="relative">
      <Box position="absolute">
        <IconButton onClick={toggleDrawer}>
          <MenuTwoToneIcon />
        </IconButton>
      </Box>
      <Drawer open={drawerOpen} onToggleSidebar={toggleDrawer} />
      <MainContent drawerOpen={drawerOpen} faqOpen={isFaqOpen}>
        <Flex minHeight="100%" width="100%">
          <Outlet />
        </Flex>
      </MainContent>
      <Hidden lgDown implementation="css">
        <FaqDrawer open={isFaqOpen} onToggle={toggleFaq} />
      </Hidden>
    </Flex>
  );
};

export default Layout;
