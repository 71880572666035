export function openPrintWindow({ title, content }) {
  const template = `
  <!DOCTYPE html>
    <html>
        <head><title>${title}</title></head>
        <body>${content}</body>
        <style>
          @media print {
            .pagebreak {
              page-break-after: always;
            }
            @page {
              size: A4 portrait;
              margin: 96px 72px 72px 72px;
            }
          }
        </style>
    </html>
    `;
  const printWin = window.open('', '');
  printWin.document.open();
  printWin.document.write(template);
  printWin.document.close();
  printWin.focus();
  printWin.print();
  printWin.close();
}

export function downloadFile(file, name) {
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
}

export function removeEmptyValuesFromArr(arr) {
  if (!arr) return [];
  return arr.map((i) => i?.trim()).filter((t) => t !== '' && t !== undefined);
}

export function removeEmptyValuesFromObj(obj) {
  if (!obj) return {};
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => {
      if (v === false) return true;
      return !!v;
    })
  );
}

export function replaceVariables(content, fields = []) {
  let body = content;
  fields.forEach((field) => {
    const fieldName = field.name.replace('?', '\\?');
    const reg = new RegExp(`\\{{${fieldName}\\}}`, 'g');
    if (field.type === 'file') {
      const val = `<img src="${
        typeof field.value === 'object'
          ? URL.createObjectURL(field.value)
          : field.value
      }" />`;
      body = body.replace(reg, val ?? `[${fieldName}]`);
    } else if (field.type === 'textlist') {
      let template =
        '<ul style="list-style-type: none; padding: 0; margin: 0;">';
      const value = Array.isArray(field.value) ? field.value : [field.value];
      value.forEach((item) => {
        template += `<li>${item}</li>`;
      });
      template += '</ul>';
      body = body.replace(reg, template ?? `[${fieldName}]`);
    } else {
      body = body.replace(reg, field.value ?? `[${fieldName}]`);
    }
  });
  return body;
}

// returns unix time in milliseconds
export function getUnixDate(date) {
  const dt = date instanceof Date ? date : new Date();
  return Math.round(dt.getTime());
}
