import GrantsList from 'components/GrantsList';
import { GRANTS_TYPES } from 'utils/constants';

const OpportunitiesPage = () => (
  <GrantsList
    type={GRANTS_TYPES.LINK}
    path="opportunities"
    header="Search for grant opportunities"
    subheader="The Grant Suite search bar can help you in finding the most recent grants for your organization or business. Use interests, focuses, and keywords to narrow your search."
    listHeader="Featured Opportunities"
    hasContentFilter
  />
);

export default OpportunitiesPage;
