import callApi from 'api/callApi';

const KEYS = {
  config: () => ['config'],
};

export const getConfig = () =>
  callApi('/config', {
    method: 'GET',
    needsAuthToken: true,
  });

export const getConfigQuery = () => ({
  queryKey: KEYS.config(),
  queryFn: getConfig,
  cacheTime: 0,
});

export const updateConfig = (id, data) =>
  callApi(`/config/${id}`, {
    method: 'PATCH',
    data,
    needsAuthToken: true,
  });
