import { Box, Button, Container, styled } from '@mui/material';
import Flex from 'components/Flex';
import { Body, Title2 } from 'components/Text';
import { useAuth } from 'contexts/Auth';
import Bg1 from 'images/bg-1.png';
import { useEffect } from 'react';

const Overlay = styled(Box)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.common.white} 20%, rgba(0, 0, 0, 0) 90%), url(${Bg1})`,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  borderRadius: theme.spacing(4),
}));

const NotFullyPaid = () => {
  const { signOut } = useAuth();

  useEffect(() => {
    signOut(false);
  }, []);

  const handleStart = () => {
    signOut();
  };

  return (
    <Container>
      <Box
        width={{ xs: '100%', md: 640 }}
        height={{ xs: 'auto', md: 520 }}
        position="relative"
        bgcolor="common.white"
        borderRadius={5}
        boxShadow={'rgb(0 0 0 / 24%) 0px 6px 16px'}
      >
        <Overlay />
        <Flex
          position="relative"
          px={{ xs: 4, md: 7.25 }}
          py={{ xs: 4, md: 6.375 }}
          height="100%"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box maxWidth={317} mb={{ xs: 5, md: 0 }}>
            <Title2
              mt={0}
              fontFamily="Playfair Display"
              maxWidth={{ xs: '100%', md: 271 }}
            >
              You're almost there!
            </Title2>
            <Body mt={0} mb={2}>
              You will receive an email to access your membership account.
            </Body>
            <Body mt={0} mb={2}>
              Click the link start logging in.
            </Body>
          </Box>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'background.button.dark' }}
            onClick={handleStart}
          >
            Login
          </Button>
        </Flex>
      </Box>
    </Container>
  );
};

export default NotFullyPaid;
