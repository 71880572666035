import { Clear } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Body, Large, Title2 } from 'components/Text';
import { useMemo, useState } from 'react';

const DISABLE_SEARCH = false;

const OVERVIEW = {
  title: 'Product Overview',
  content: [
    'Looking for a grant letter generator that will wow your recipients? The Grant Suite is the only place to go!',
    'You may write the ideal letter in a few easy minutes with our cutting-edge automated grant letter. All you need to do is enter your company’s information; the software will do the rest.',
    'What makes the Grant Suite the best option for your personalized grant letter?',
    'To begin with, our established letter is sure to impress. It contains all the key points your recipients need to be aware of and is written in a polished, sincere manner that captures the spirit of your organization. Additionally, our letter may be adjusted to meet your demands.',
    'Why then wait? Sign up right away to receive your unlimited access automatic grant letter!',
  ],
};

const FEATURES = {
  title: 'Software Features',
  content: [
    'Automatic Grant & Letter Automation',
    'Attach Important Supporting Documents',
    'Broad Grant Scope Opportunities',
    'Download and Print Letters Directly through the software',
    'Tips and Guides on writing',
  ],
};

const FAQS = [
  {
    question: 'Do we need to install it?',
    answer: [
      'You don’t have to install the software. Just log in to www.grantsuite.com and you can now use the Grant Suite Web App anytime.',
    ],
    group: 'software',
  },
  {
    question: 'Can I use my phone and tablet to access the software?',
    answer: [
      'We recommend using the software on your desktop computer. The Grant Suite Web App is best used with wide-screen devices.',
    ],
    group: 'software',
  },
  {
    question: 'What are the system requirements for the software?',
    answer: [
      'As long as you can use any other social media on your computer, you can also use the Grant Suite Web App!',
    ],
    group: 'software',
  },
  {
    question:
      'Can I print generated grant application letters directly on the system?',
    answer: [
      'Yes, you can! Generated application letters can be printed directly on your browser or downloaded to be used at a later time.',
    ],
    group: 'software',
  },
  {
    question: 'How can I reset my password?',
    answer: [
      'On the login page, click the forgot password link.',
      'Enter your email address to get a link to reset your password.',
      'Once the reset is complete you can now access your account with your new password!',
      'If you are having trouble, please email support@grantsuite.com',
    ],
    group: 'software',
  },
  {
    question: 'How can I update my personal information on my account?',
    answer: [
      'First, you need to be logged in to your account to update your personal information.',
      'Once logged in go to the sidebar section and scroll all the way to the bottom.',
      'Click Manage Profile.',
      'Enter your information. Save changes.',
    ],
    group: 'software',
  },
  {
    question: 'Can I apply for Grants available on the software?',
    answer: [
      'The Grant Suite Web App is used to create automatically generated grant application letters, and save, edit or print those letters. For the actual grant application, you need to submit your application letter to the company or organization that is awarding the grants.',
    ],
    group: 'software',
  },
  {
    question: 'Can I use the software to get Grants available in my area?',
    answer: [
      'Yes! Use the search a grant function and filter the results by location.',
    ],
    group: 'software',
  },
  {
    question: 'Can I edit my existing Grants?',
    answer: [
      'Yes! You can edit your existing grants by visiting Manage Grants page.',
    ],
    group: 'software',
  },
  {
    question: 'How much is the payment for subscription?',
    answer: [''],
    group: 'pricing',
  },
  {
    question: 'What is your mode of payment?',
    answer: [''],
    group: 'pricing',
  },
  {
    question: 'What are the pricing plans available?',
    answer: [''],
    group: 'pricing',
  },
  {
    question: 'How do I cancel my subscription?',
    answer: [''],
    group: 'pricing',
  },
  {
    question: 'Why does Grant Suite charge a fee?',
    answer: [''],
    group: 'pricing',
  },
  {
    group: 'grants',
    question: 'What is a grant?',
    answer: [
      'A grant proposal is a request for an investment in a non-profit or for-profit project. A grant is a tool nonprofits use to address important issues within their communities.\n\nA grant proposal is actually a call to action. It’s a request that a funder joins the nonprofit as a partner in achieving specific results.',
    ],
  },
  {
    group: 'grants',
    question: 'Who is eligible to apply for a grant?',
    answer: [
      'Each grant has its own set of eligibility requirements and criteria. You need to look at the grant description to see if you are qualified for a certain grant.',
    ],
  },
  {
    group: 'grants',
    question: 'What will grants pay for?',
    answer: [
      'Grants can pay for many things related to your non-profit or for-profit organization. It can be used to fund general operations expenses as well as program-related expenses. Grants can be used for indirect activities that support the work but are not direct work, such as rent, utilities, insurance, fees, audits, etc. Funding can also be used to construct, renovate, expand, and improve real property. Grants can also be used for equipment below $5000.',
    ],
  },
  {
    group: 'grants',
    question: 'Can I apply to numerous grants at the same time?',
    answer: [
      'You can apply for different grants at the same time. The general rule is that you can only apply for one funding opportunity at a time. You may submit multiple applications to various funding organizations.',
    ],
  },
  {
    group: 'grants',
    question: 'What is a letter of inquiry?',
    answer: [
      `In general, the letter of inquiry's purpose is to attract interest and a desire to learn more in a full proposal. The letter of inquiry format describes your organization and the programs for which you are seeking funding.`,
    ],
  },
  {
    group: 'grants',
    question: 'How much time does it take to prepare a grant application?',
    answer: [
      'In general, grant writing should take at least six to eight weeks. It is critical not to rush the grant writing process to ensure that you have enough time to write a high-quality proposal. With the Grant Suite, this timeframe will be minimized. You can finish and submit more applications within a shorter period of time.',
    ],
  },
  {
    group: 'grants',
    question: 'What do I do if my grant application is rejected?',
    answer: [
      `Call the funder and see if they can provide any insight as to why the proposal was rejected. Submit a request for a copy of the reviewer's comments. Seek clarification whether you can resubmit for the next funding cycle and how you can improve your proposal.`,
    ],
  },
];

const FAQLABELS = {
  software: 'SOFTWARE',
  pricing: 'PRICING & PLAN',
  grants: 'ABOUT GRANTS & LETTERS',
};

const Faq = () => {
  const [faqItems, setFaqItems] = useState(FAQS);
  const [search, setSearch] = useState('');
  const groupedFaqs = useMemo(() => {
    const groups = [];
    faqItems.forEach((item) => {
      const existing = groups.find((g) => g.title === FAQLABELS[item.group]);
      if (existing) {
        if (!existing.content) existing.content = [];
        existing.content.push(item);
      } else {
        const nGroup = { title: FAQLABELS[item.group], content: [item] };
        groups.push(nGroup);
      }
    });
    return groups;
  }, [faqItems]);

  const handleSearchFaqs = (e) => {
    const { value } = e.target;
    setSearch(value);

    if (!value) {
      setFaqItems(FAQS);
      return;
    }

    const items = FAQS.filter((item) => {
      if (item.question.toLowerCase().includes(value.toLowerCase())) {
        return true;
      }
      if (
        item.answer.some((s) => s.toLowerCase().includes(value.toLowerCase()))
      ) {
        return true;
      }
      return false;
    });

    setFaqItems(items);
  };

  return (
    <Box overflow="auto">
      <Box mb={3}>
        <Title2 fontFamily="Playfair Display">{OVERVIEW.title}</Title2>
        {OVERVIEW.content.map((text, i) => (
          <Body key={i}>{text}</Body>
        ))}
      </Box>
      <Box mb={3}>
        <Title2 fontFamily="Playfair Display">{FEATURES.title}</Title2>
        <ul>
          {FEATURES.content.map((text, i) => (
            <li key={i}>
              <Body>{text}</Body>
            </li>
          ))}
        </ul>
      </Box>
      <Box mb={3}>
        <Title2 fontFamily="Playfair Display">FAQs</Title2>
        {!DISABLE_SEARCH && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Search"
                fullWidth
                value={search}
                onChange={handleSearchFaqs}
                InputProps={{
                  endAdornment: !!search && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setSearch('');
                          setFaqItems(FAQS);
                        }}
                      >
                        <Clear color="gray.600" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        )}
        {groupedFaqs.map((faq, i) => (
          <Box mb={3} key={i}>
            <Large color="grey.600">{faq.title}</Large>
            <ol>
              {faq.content.map((content, j) => (
                <li key={j}>
                  <Box>
                    <Body>{content.question}</Body>
                    <ul>
                      {content.answer.map((answer, k) => (
                        <li key={k}>
                          <Body>{answer}</Body>
                        </li>
                      ))}
                    </ul>
                  </Box>
                </li>
              ))}
            </ol>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Faq;
