/* eslint-disable no-param-reassign */
import {
  COMMON_FIELDS,
  GRANTS_FIELD_TYPES,
  ORG_COMMON_FIELDS,
} from 'utils/constants';

export function getDefaultValue(grant, values, forceOverride = false) {
  if (!grant) return undefined;
  if (!grant.fields) grant.fields = [];
  if (!Array.isArray(grant?.fields)) {
    grant.fields = [grant.fields];
  }

  grant?.fields?.forEach((f) => {
    const field = f;
    if (!f.value || forceOverride) {
      const userField = values?.[f.name];
      if (field.type === GRANTS_FIELD_TYPES.DATE && !field.value) {
        field.value = new Date();
      } else {
        field.value = userField;
      }
    }
  });
  return grant;
}

export const getDefaultBoardMembers = (boardMembers) => {
  const mappedNames = boardMembers?.map((s) => s.name);
  if (mappedNames?.length) return mappedNames;
  return [''];
};

export function getDefaultUserValue(user, userOrg) {
  if (!user) return {};

  const commonFields = Object.keys(COMMON_FIELDS);
  const res = commonFields.reduce((result, current) => {
    const userDefaults = result;
    let userField;
    if (current === 'company') {
      userField = userOrg?.name;
    } else if (current === 'missionStatement') {
      userField = userOrg?.mission;
    } else if (current === 'programDesign') {
      userField = userOrg?.programDesign;
    } else if (current === 'employerInternalNumber') {
      userField = userOrg?.employeeInternalId;
    } else if (current === 'expectedResults') {
      userField = userOrg?.expectedResults;
    } else if (current === 'statementOfNeed') {
      userField = userOrg?.statementOfNeed;
    } else if (current === 'listOfBoardMembers') {
      userField = getDefaultBoardMembers(userOrg?.boardMembers);
    } else if (current === 'orgAddress') {
      userField = userOrg?.address;
    } else if (current === 'orgCity') {
      userField = userOrg?.city;
    } else if (current === 'orgState') {
      userField = userOrg?.state;
    } else if (current === 'orgZip') {
      userField = userOrg?.zipCode;
    } else if (current === 'orgCountry') {
      userField = userOrg?.country;
    } else if (current === 'phoneNumber') {
      userField = user?.phone;
    } else {
      userField = user?.[current];
    }

    if (userField != null) {
      userDefaults[current] = userField;
    }
    return result;
  }, {});

  return res;
}

export function userFieldsToObject(userFields, allowCommonFields = false) {
  const res = userFields.reduce((result, current) => {
    const tmp = { ...result };
    if (ORG_COMMON_FIELDS.includes(current.name) && !allowCommonFields)
      return tmp;
    tmp[current.name] = current.value;
    return tmp;
  }, {});
  return res;
}

export function mergeGrantAndOrgFields({
  isNew,
  user,
  userFields,
  organization,
  grant,
}) {
  const userFieldValues = isNew
    ? getDefaultUserValue(user, organization)
    : null;
  const fieldValues = userFieldsToObject(
    userFields?.reduce((r, f) => [...r, ...(f.items ?? [])], []) ?? [],
    true
  );
  const sanitized = Object.keys(fieldValues).reduce((result, key) => {
    const fieldValue = grant?.fields?.find((f) => f.name === key);
    return {
      ...result,
      [key]:
        typeof fieldValue?.value === 'undefined'
          ? fieldValues[key]
          : fieldValue?.value,
    };
  }, {});

  const updateDefaultValues = getDefaultValue(
    grant,
    {
      ...userFieldValues,
      ...sanitized,
    },
    true
  );
  return updateDefaultValues;
}
export const isArrayEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.sort();
  const sortedArr2 = arr2.sort();
  return sortedArr1.every((element, index) => element === sortedArr2[index]);
};

export const ORG_FIELDS_MAPPING = [
  { org: 'name', field: 'company', type: 'text', defaultValue: '' },
  {
    org: 'mission',
    field: 'missionStatement',
    type: 'text',
    defaultValue: '',
  },
  {
    org: 'programDesign',
    field: 'programDesign',
    type: 'text',
    defaultValue: '',
  },
  {
    org: 'expectedResults',
    field: 'expectedResults',
    type: 'text',
    defaultValue: '',
  },
  {
    org: 'employeeInternalId',
    field: 'employerInternalNumber',
    type: 'text',
    defaultValue: '',
  },
  {
    org: 'statementOfNeed',
    field: 'statementOfNeed',
    type: 'text',
    defaultValue: '',
  },
  { org: 'address', field: 'orgAddress', type: 'text', defaultValue: '' },
  { org: 'city', field: 'orgCity', type: 'text', defaultValue: '' },
  { org: 'state', field: 'orgState', type: 'text', defaultValue: '' },
  { org: 'zipCode', field: 'orgZip', type: 'text', defaultValue: '' },
  { org: 'country', field: 'orgCountry', type: 'text', defaultValue: '' },
  {
    org: 'boardMembers',
    field: 'listOfBoardMembers',
    type: 'textlist',
    defaultValue: [],
  },
];
