import { Box, lighten, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const Styled = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  '& .MuiDataGrid-columnHeaders': {
    border: 'none',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-row': {
    borderRadius: 16,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.95),
    },
  },
  '& .MuiDataGrid-row:nth-child(even)': {
    backgroundColor: theme.palette.background.row,
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.95),
    },
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
  },
}));

export default function CompactTable({ columns, rows, onRowClick }) {
  return (
    <Box height={600}>
      <Styled
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        onRowClick={onRowClick}
        rowHeight={72}
      />
    </Box>
  );
}
