import { Box, Button } from '@mui/material';
import Modal from 'components/Modal';
import { Body, Title2 } from 'components/Text';

export default function UnsavedContentDialog({
  open,
  onClose,
  type = 'download',
}) {
  return (
    <Modal maxWidth={538} open={open} onClose={onClose}>
      <Box mb={3}>
        <Title2 mt={0} mb={3} fontFamily="Playfair Display">
          Unsaved Changes
        </Title2>
        <Body my={0}>
          You have unsaved changes at the moment. Please save your work first
          before you {type === 'download' ? 'download' : 'print'} your
          application.
        </Body>
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <Box
          component={Button}
          variant="contained"
          px={4.5}
          py={2}
          sx={{ backgroundColor: 'background.button.dark' }}
          onClick={onClose}
          mr={2}
        >
          Ok
        </Box>
      </Box>
    </Modal>
  );
}
