import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  styled,
  SvgIcon,
  TextField,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import Flex from 'components/Flex';
import { Body, Large } from 'components/Text';
import { ReactComponent as TooltipIcon } from 'images/icons/tooltip.svg';
import { Controller, useFieldArray } from 'react-hook-form';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 6px 16px',
    fontSize: 11,
  },
}));

const OrgBoardMembers = ({ control, orgIndex, errors }) => {
  const {
    fields: boardMembers,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `organizations[${orgIndex}].boardMembers`,
  });

  const handleRemoveBoard = (index) => {
    remove(index);
  };

  const handleAddBoard = () => {
    append({ name: '' });
  };

  return (
    <Box>
      <Flex
        alignItems={{ xs: 'start', md: 'center' }}
        justifyContent="space-between"
        flexDirection={{ xs: 'column', md: 'row' }}
        mb={1.75}
      >
        <Body my={0} bold>
          List of Board Members
          <BootstrapTooltip title="Kindly input complete name of board member (first name, last name).">
            <SvgIcon
              sx={{
                height: 27,
                verticalAlign: 'middle',
                marginLeft: '10px',
              }}
            >
              <TooltipIcon />
            </SvgIcon>
          </BootstrapTooltip>
        </Body>
        <Button
          endIcon={<AddIcon />}
          onClick={() => handleAddBoard()}
          sx={{ width: { xs: '100%', md: 'auto' } }}
        >
          Add Board Member
        </Button>
      </Flex>
      {boardMembers.length === 0 && (
        <Flex center>
          <Large color="gray.600" fontFamily="Playfair Display">
            No board members added yet
          </Large>
        </Flex>
      )}
      {boardMembers?.map((bm, bmIndex) => (
        <Box key={bm.id}>
          <Controller
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Board member name is required',
              },
            }}
            name={`organizations[${orgIndex}].boardMembers.[${bmIndex}].name`}
            render={({ field }) => (
              <Flex alignItems="center" mb={2}>
                <Box
                  component={IconButton}
                  onClick={() => handleRemoveBoard(bmIndex)}
                  mr={1}
                >
                  <RemoveIcon color="error" />
                </Box>
                <Box marginRight={2} width="100%">
                  <TextField
                    {...field}
                    fullWidth
                    placeholder="Enter the name of board member here"
                    error={
                      !!errors?.organizations?.[orgIndex]?.boardMembers?.[
                        bmIndex
                      ]?.name
                    }
                    helperText={
                      errors?.organizations?.[orgIndex]?.boardMembers?.[bmIndex]
                        ?.name?.message
                    }
                  />
                </Box>
              </Flex>
            )}
          />
        </Box>
      ))}
    </Box>
  );
};

export default OrgBoardMembers;
