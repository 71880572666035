import { useAuth } from 'contexts/Auth';
import { useOrganization } from 'contexts/Organization';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export default function useValidateUserAccess() {
  const { user, isInitialized } = useAuth();
  const { organizations } = useOrganization();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (
      location.pathname.startsWith('/auth/') &&
      !location.pathname.includes('/auth/sign-in')
    )
      return;

    if (!isInitialized) return;

    if (!user) {
      const returnUrl = searchParams.get('returnUrl');
      if (returnUrl) {
        navigate(`/auth/sign-in?returnUrl=${returnUrl}`);
      } else {
        navigate('/auth/sign-in');
      }
      return;
    }

    // If user is a curator/admin, redirect to the dashboard
    if (user.roles?.includes('curator')) {
      return;
    }

    // If user has not paid yet, redirect to verify payment page
    if (!user.isFullyPaid) {
      navigate('/auth/verify-account');
    }

    // If user is required to reset password, redirect to reset password page
    if (user.forceResetPassword) {
      navigate('/auth/change-password');
    }

    // If user has no organizations yet, proceed to create organization page
    if (!!organizations && organizations.length === 0) {
      navigate('/auth/org-confirmation');
    }
  }, [user, isInitialized, location.pathname, organizations]);

  return { isInitialized };
}
