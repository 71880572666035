import * as qs from 'qs';
import callApi from 'api/callApi';

export const USER_QUERY_KEYS = {
  userById: (id) => [`user_${id}`, id],
  fields: (id) => [`user_${id}_fields`, id],
};

export const getUsers = (filters) => {
  const query = qs.stringify(filters);
  return callApi(`/users?${query}`, {
    method: 'GET',
    needsAuthToken: true,
  });
};

const getUserById = ({ queryKey }) => {
  const [, id] = queryKey;
  return callApi(`/users/${id}`, {
    method: 'GET',
    needsAuthToken: true,
  });
};

export const getUserByIdQuery = (id) => ({
  queryKey: USER_QUERY_KEYS.userById(id),
  queryFn: getUserById,
  cacheTime: 0,
});

export const updateUser = (id, data) =>
  callApi(`/users/${id}`, {
    method: 'PATCH',
    data,
    needsAuthToken: true,
  });

export const saveOrganization = (id, data) =>
  callApi(`/users/${id}/organizations`, {
    method: 'POST',
    data,
    needsAuthToken: true,
  });

export const getUserFields = ({ queryKey }) => {
  const [, id] = queryKey;
  return callApi(`/users/${id}/fields`, {
    method: 'GET',
    needsAuthToken: true,
  });
};

export const getUserFieldsQuery = (id) => ({
  cacheTime: 0,
  queryKey: USER_QUERY_KEYS.fields(id),
  queryFn: getUserFields,
  enabled: !!id,
});
