import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import TabContext from '@mui/lab/TabContext';
import MuiTabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  styled,
  Tab,
  TextField,
} from '@mui/material';
import DatePicker from 'components/DatePicker';
import DeleteDialog from 'components/DeleteDialog';
import DocumentEditor from 'components/DocumentEditor';
import Field from 'components/Field';
import Flex from 'components/Flex';
import {
  DEFAULT_LOCATION,
  LocationField,
  LocationSearchField,
} from 'components/LocationField';
import TagInput from 'components/TagInput';
import Text, { Body, Small } from 'components/Text';
import { addDays } from 'date-fns';
import { DiscussionEmbed } from 'disqus-react';
import { useRef, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import humanize from 'underscore.string/humanize';
import {
  COMMON_FIELDS,
  GRANTS_FIELD_TYPES,
  GRANTS_TYPES,
  GRANT_CATEGORIES,
  GRANT_STATUSES,
  GRANT_SUBCATEGORIES,
  SITE_URL,
} from 'utils/constants';
import AttachmentItem from './components/AttachmentItem';
import CustomTagDialog from './components/CustomTagDialog';
import FieldItem from './components/FieldItem';
import Section from './components/Section';

const DEFAULT_FIELDS = Object.keys(COMMON_FIELDS).map((key) => ({
  name: key,
  type:
    key === 'listOfBoardMembers'
      ? GRANTS_FIELD_TYPES.TEXT_LIST
      : GRANTS_FIELD_TYPES.TEXT,
}));

const DEFAULT_CATEGORY = '';
const DEFAULT_SUBCATEGORY = '';

const getDefaultData = (grant) => ({
  title: grant?.title || '',
  description: grant?.description || '',
  status: grant?.status || GRANT_STATUSES.DRAFT,
  url: grant?.url || '',
  type: grant?.type || GRANTS_TYPES.GRANT,
  category: grant?.category || DEFAULT_CATEGORY,
  subcategory: grant?.subcategory || DEFAULT_SUBCATEGORY,
  fields: grant?.fields?.length ? grant?.fields : DEFAULT_FIELDS,
  value: grant?.value ?? 0,
  tags: grant?.tags || [],
  expirationDate: grant?.expirationDate ?? addDays(30, new Date()),
  attachments: grant?.attachments?.length ? grant.attachments : [],
  pages: grant?.pages?.length ? grant?.pages : [],
  location: grant?.location ?? DEFAULT_LOCATION,
});

const TabList = styled(MuiTabList)(() => ({
  '& .MuiTabs-scroller': {
    overflow: 'hidden !important',
  },
}));

const GrantForm = ({
  grant,
  onSubmit,
  isSaving,
  onPreview,
  isDownloadingPreview,
}) => {
  const customTagResolver = useRef();
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    getValues,
    watch,
  } = useForm({ defaultValues: getDefaultData(grant) });
  const {
    fields: pages,
    append: addPage,
    remove: removePage,
  } = useFieldArray({
    control,
    name: 'pages',
  });
  const { append: addField, remove: removeField } = useFieldArray({
    control,
    name: 'fields',
  });
  const {
    fields: attachments,
    append: addAttachment,
    remove: removeAttachment,
  } = useFieldArray({
    control,
    name: 'attachments',
  });
  const watchedFields = watch('fields');
  const activeType = useWatch({ control, name: 'type' });
  const [activePage, setActivePage] = useState(0);
  const [isDeletePageDialogOpen, setIsDeletePageDialogOpen] = useState();
  const [isCustomTagDialogOpen, setIsCustomTagDialogOpen] = useState();

  const handleChange = (_, newValue) => {
    setActivePage(newValue);
  };

  const handleDeletePage = () => {
    removePage(activePage);
    setIsDeletePageDialogOpen(false);
    const currentPages = pages.filter((_, index) => index !== activePage);
    setActivePage(currentPages?.length ? currentPages.length - 1 : 0);
  };

  const handleAddPage = () => {
    addPage({ content: '', sortOrder: pages?.length ?? 0 });
    setActivePage(pages?.length ?? 0);
  };

  const handleAddField = () => {
    addField({ name: '', type: GRANTS_FIELD_TYPES.TEXT });
  };

  const handleRemoveField = (index) => {
    removeField(index);
  };

  const handleAddAttachment = () => {
    addAttachment({ label: '' });
  };

  const handleRemoveAttachment = (index) => {
    removeAttachment(index);
  };

  const handleDocumentTagEntered = (documentTag) => {
    if (!documentTag?.trim()) return;
    const currentValues = getValues('fields');
    if (currentValues?.some((f) => f.name === documentTag.trim())) return;
    const type =
      documentTag === 'listOfBoardMembers'
        ? GRANTS_FIELD_TYPES.TEXT_LIST
        : GRANTS_FIELD_TYPES.TEXT;
    addField({ name: documentTag, type });
  };

  const handleCustomTagSelected = (resolver) => {
    setIsCustomTagDialogOpen(true);
    customTagResolver.current = resolver;
  };

  const handleCustomTagSuccess = (tag) => {
    if (!tag?.trim() || !customTagResolver.current) return;
    customTagResolver.current(tag);
    setIsCustomTagDialogOpen(false);
  };

  return (
    <Flex
      flexDirection="column"
      flexGrow={1}
      width="100%"
      height="100%"
      overflow="auto"
      p={5}
    >
      <Box width="100%" component="form" onSubmit={handleSubmit(onSubmit)}>
        <Section title="Basic Information">
          <Field label="Title" mb={2}>
            <TextField
              error={!!errors?.title}
              helperText={errors?.title?.message}
              name="title"
              {...register('title', {
                required: {
                  value: true,
                  message: 'Title is required',
                },
              })}
              fullWidth
            />
          </Field>
          <Grid spacing={2} container sx={{ mb: 4 }}>
            <Grid xs={12} sm={4} item>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Field label="Type" mb={2}>
                    <Select
                      value={field.value || ''}
                      onChange={field.onChange}
                      fullWidth
                    >
                      {Object.values(GRANTS_TYPES).map((value) => (
                        <MenuItem key={value} value={value}>
                          {humanize(value)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Field>
                )}
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <Controller
                name="expirationDate"
                control={control}
                rules={{ required: 'Please set the expiration date' }}
                render={({ field }) => (
                  <Field sx={{ flexGrow: 1 }} label="Expiration Date">
                    <DatePicker value={field.value} onChange={field.onChange} />
                  </Field>
                )}
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <Field label="Value" sx={{ flexGrow: 1 }}>
                <TextField
                  name="value"
                  type="number"
                  error={!!errors?.value}
                  helperText={errors?.value?.message}
                  {...register('value', {
                    required: {
                      value: true,
                      message: 'Value is required',
                    },
                  })}
                  fullWidth
                />
              </Field>
            </Grid>
            <Grid xs={12} sm={4} item>
              <Field label="Status" sx={{ flexGrow: 1 }}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value || ''}
                      onChange={field.onChange}
                      fullWidth
                    >
                      {Object.values(GRANT_STATUSES).map((value) => (
                        <MenuItem key={value} value={value}>
                          {humanize(value)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Field>
            </Grid>
            <Grid xs={12} sm={4} item>
              <Field label="Type of Grant" sx={{ flexGrow: 1 }}>
                <Controller
                  name="category"
                  control={control}
                  rules={{
                    required: 'Please select the type of grant',
                  }}
                  render={({ field }) => (
                    <>
                      <Select
                        value={field.value || ''}
                        onChange={field.onChange}
                        fullWidth
                        error={!!errors?.category}
                        helperText={errors?.category?.message}
                      >
                        {GRANT_CATEGORIES.map((g) => (
                          <MenuItem key={g.value} value={g.value}>
                            {g.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.category?.message && (
                        <Small mt={1} mb={0} mx={1} color="error.main">
                          {errors.category.message}
                        </Small>
                      )}
                    </>
                  )}
                />
              </Field>
            </Grid>
            <Grid xs={12} sm={4} item>
              <Field label="Category" sx={{ flexGrow: 1 }}>
                <Controller
                  name="subcategory"
                  control={control}
                  rules={{
                    required: 'Please select a category for this grant',
                  }}
                  render={({ field }) => (
                    <>
                      <Select
                        value={field.value || ''}
                        onChange={field.onChange}
                        fullWidth
                        error={!!errors?.subcategory}
                      >
                        {GRANT_SUBCATEGORIES.map((g) => (
                          <MenuItem key={g.value} value={g.value}>
                            {g.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.subcategory?.message && (
                        <Small mt={1} mb={0} mx={1} color="error.main">
                          {errors.subcategory.message}
                        </Small>
                      )}
                    </>
                  )}
                />
              </Field>
            </Grid>
          </Grid>
          <Field label="Description" mb={2}>
            <TextField
              error={!!errors?.description}
              helperText={errors?.description?.message}
              name="description"
              {...register('description', {
                required: {
                  value: true,
                  message: 'Description is required',
                },
              })}
              rows={4}
              fullWidth
              multiline
            />
          </Field>
          <Field label="Tags" mb={2}>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <TagInput
                  onChange={field.onChange}
                  defaultValue={field.value}
                />
              )}
            />
          </Field>
          <Field label="Location" width="100%" mb={2}>
            <LocationField>
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <LocationSearchField
                    onMapSelect={(location) => {
                      field.onChange(location);
                    }}
                    location={field.value}
                  />
                )}
              />
            </LocationField>
          </Field>
          <Field label="Attachments" mb={2}>
            {attachments.map((attachment, index) => (
              <Controller
                key={attachment.id}
                name={`attachments.${index}`}
                control={control}
                render={({ field }) => (
                  <AttachmentItem
                    item={field.value}
                    onChange={field.onChange}
                    onRemove={() => handleRemoveAttachment(index)}
                    mt={2}
                  />
                )}
              />
            ))}
            <Button
              variant="outlined"
              sx={{ mt: 3 }}
              onClick={handleAddAttachment}
            >
              Add attachment
            </Button>
          </Field>
        </Section>
        {activeType === GRANTS_TYPES.LINK && (
          <Section title="Link Information">
            <Field label="Url" mb={2}>
              <TextField
                error={!!errors?.url}
                helperText={errors?.url?.message}
                name="url"
                {...register('url', {
                  required: {
                    value: true,
                    message: 'Url is required',
                  },
                })}
                fullWidth
              />
            </Field>
          </Section>
        )}
        <Section title="Template">
          <Field label="Fields" mb={5}>
            {watchedFields.map((fieldItem, index) => (
              <Controller
                key={fieldItem.id}
                name={`fields[${index}]`}
                control={control}
                render={({ field }) => (
                  <FieldItem
                    item={field.value}
                    onChange={field.onChange}
                    index={index}
                    onRemove={handleRemoveField}
                    mt={1}
                  />
                )}
              />
            ))}
            <Button variant="outlined" sx={{ mt: 3 }} onClick={handleAddField}>
              Add field
            </Button>
          </Field>
          <Box mb={2}>
            <Flex alignItems="center">
              <Body my={0}>Pages</Body>
              <IconButton onClick={handleAddPage}>
                {!pages?.length && (
                  <Text variant="subtitle1" sx={{ mr: 1 }}>
                    Add your first page
                  </Text>
                )}
                <AddCircleIcon color="primary" />
              </IconButton>
            </Flex>
          </Box>
          <TabContext value={activePage}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 1,
              }}
            >
              <TabList onChange={handleChange} variant="scrollable">
                {pages.map((page, index) => (
                  <Tab
                    key={`page-tab-${page.id}`}
                    label={`Page ${index + 1}`}
                    value={index}
                  />
                ))}
              </TabList>
            </Box>
            {pages.map((page, index) => (
              <TabPanel key={`page-panel-${page.id}`} value={index}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    width: '100%',
                    mb: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setIsDeletePageDialogOpen(true)}
                  >
                    Remove page
                  </Button>
                </Box>
                <Controller
                  name={`pages.${index}.content`}
                  control={control}
                  render={({ field }) => (
                    <DocumentEditor
                      onChange={field.onChange}
                      initialValue={field.value}
                      defaultTags={Object.keys(COMMON_FIELDS)}
                      onTagEntered={handleDocumentTagEntered}
                      onCustomTagSelected={handleCustomTagSelected}
                    />
                  )}
                />
              </TabPanel>
            ))}
          </TabContext>
        </Section>
        <Box display="flex" my={3}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSaving}
            loadingIndicator="Saving..."
          >
            Save
          </LoadingButton>
          <Box ml={2}>
            <LoadingButton
              variant="contained"
              loading={isDownloadingPreview}
              loadingIndicator="Downloading preview..."
              onClick={onPreview}
              color="secondary"
            >
              Download
            </LoadingButton>
          </Box>
        </Box>
        {grant?.id && (
          <DiscussionEmbed
            shortname="grants-app"
            config={{
              url: `${SITE_URL}/user-grants/${grant.id}`,
              identifier: grant.id,
              title: grant.title,
            }}
          />
        )}
        <DeleteDialog
          open={isDeletePageDialogOpen}
          onClose={() => setIsDeletePageDialogOpen(false)}
          objectName={'this page'}
          onDelete={handleDeletePage}
        />
        <CustomTagDialog
          open={isCustomTagDialogOpen}
          onClose={() => setIsCustomTagDialogOpen(false)}
          onSuccess={handleCustomTagSuccess}
        />
      </Box>
    </Flex>
  );
};

export default GrantForm;
