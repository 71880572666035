import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { getUserGrants } from 'api/services/userGrants';
import DataGrid from 'components/DataGrid';
import PageHeader from 'components/PageHeader';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { useDataGridQuery } from 'hooks';
import * as moment from 'moment';
import { useEffect } from 'react';
import { DEFAULT_DATAGRID_CONFIG, GRANTS_TYPES } from 'utils/constants';

const getTypesList = () =>
  Object.keys(GRANTS_TYPES).map((k) => ({
    id: GRANTS_TYPES[k],
    label: GRANTS_TYPES[k],
  }));

const FILTER_VALUE = [
  { name: 'firstName', operator: 'eq', type: 'string', value: '' },
  { name: 'lastName', operator: 'eq', type: 'string', value: '' },
  { name: 'title', operator: 'eq', type: 'string', value: '' },
  { name: 'type', operator: 'eq', type: 'select' },
  {
    name: 'createdDate',
    operator: 'eq',
    type: 'date',
    value: '',
  },
];

const COLUMNS = [
  {
    name: 'id',
    type: 'string',
    header: 'ID',
    defaultVisible: false,
  },
  {
    name: 'firstName',
    header: 'First Name',
    defaultFlex: 1,
  },
  {
    name: 'lastName',
    header: 'Last Name',
    defaultFlex: 1,
  },
  {
    id: 'title',
    name: 'grant',
    header: 'Title',
    defaultFlex: 1,
    render: ({ value }) => value.title,
  },
  {
    id: 'type',
    name: 'grant',
    header: 'Type',
    defaultFlex: 1,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'All',
      dataSource: getTypesList(),
    },
    render: ({ value }) => value.type,
  },
  {
    name: 'createdDate',
    header: 'Created',
    defaultFlex: 1,
    filterEditor: DateFilter,
    filterEditorProps: () => ({
      dateFormat: 'yyyy-MM-DD',
      cancelButton: false,
      highlightWeekends: true,
      weekNumbers: false,
    }),
    render: ({ value }) => moment(value).format('MMM DD, YYYY hh:mm A'),
  },
];

const ManageUserGrantsPage = () => {
  const { showSnackbar } = useSnackbar();
  const { dataSource, isLoading, error, updateConfig } = useDataGridQuery(
    'user-grants',
    getUserGrants,
    DEFAULT_DATAGRID_CONFIG
  );

  useEffect(() => {
    if (!error) return;
    showSnackbar({
      message: 'Unable to load user grants',
      variant: SNACKBAR_VARIANTS.ERROR,
    });
  }, [error]);

  return (
    <>
      <PageHeader title="User Grants" hideAction={true} />
      <DataGrid
        columns={COLUMNS}
        defaultFilterValue={FILTER_VALUE}
        defaultConfig={DEFAULT_DATAGRID_CONFIG}
        dataSource={dataSource}
        loading={isLoading}
        onConfigChange={updateConfig}
        canExport={true}
        canSearch={true}
        pagination
      />
    </>
  );
};

export default ManageUserGrantsPage;
