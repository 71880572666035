import { TabContext } from '@mui/lab';
import { Box, Card, CardContent, Container } from '@mui/material';
import { saveOrganization } from 'api';
import Flex from 'components/Flex';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { Body, Title2 } from 'components/Text';
import { useAuth } from 'contexts/Auth';
import { useOrganization } from 'contexts/Organization';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ORGANIZATION_TYPES } from 'utils/constants';
import ReactGA from 'react-ga4';
import OrgForm from './OrgForm';
import OrgSidebar from './OrgSidebar';

export const ORG_SIDEBAR_HEIGHT = 900;
const DISABLE_MULTIPLE_ORGS =
  process.env.REACT_APP_DISABLE_MULTIPLE_ORGS === 'true';

const OrgSetup = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { user, refreshUser, isLoading: authLoading } = useAuth();
  const { refreshOrganizations } = useOrganization();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      organizations: [
        {
          name: '',
          mission: '',
          programDesign: '',
          expectedResults: '',
          organizationType: ORGANIZATION_TYPES.NON_PROFIT,
          statementOfNeed: '',
          annualBudget: '',
          employeeInternalId: '',
          boardMembers: [],
        },
      ],
    },
  });
  const {
    fields: organizations,
    append: addOrg,
    remove: removeOrg,
  } = useFieldArray({
    control,
    name: 'organizations',
  });

  const [selectedOrg, setSelectedOrg] = useState(organizations[0]);
  const [tabList, setTabList] = useState(() =>
    organizations.map((o) => ({ id: o.id, name: o.name }))
  );

  useEffect(() => {
    if (organizations?.length) {
      setSelectedOrg(organizations[organizations.length - 1]);
      setTabList(organizations?.map((o) => ({ id: o.id, name: o.name } || [])));
    }
  }, [organizations]);

  useEffect(() => {
    if (authLoading) return;
    if (!user) {
      navigate('/auth/sign-in');
    }
  }, [user, authLoading]);

  const { mutate: saveOrgInfo, isLoading } = useMutation(
    (data) => saveOrganization(user.id, data?.organizations ?? []),
    {
      onSuccess: (data) => {
        if (!data) return;
        showSnackbar({
          variant: SNACKBAR_VARIANTS.SUCCESS,
          message: 'Successfully saved your organization info',
        });
        refreshOrganizations();
        ReactGA.event({
          category: 'User Actions',
          action: 'save_org_info',
          label: `Saved organization info for user ${user.id} on setup`,
        });
        setTimeout(() => {
          refreshUser('/');
        }, 2000);
      },
      onError: (err) => {
        showSnackbar({
          variant: SNACKBAR_VARIANTS.ERROR,
          message: err?.message || 'Failed to save your organization info',
        });
      },
    }
  );

  const handleInvalidFormData = (errorData) => {
    if (!errorData) return;
    const hasErrors = Object.keys(errorData)?.length > 0;
    if (!hasErrors) return;
    showSnackbar({
      variant: SNACKBAR_VARIANTS.ERROR,
      message:
        'Please fill-in all fields with information. All fields are required.',
    });
  };

  const onSubmit = async (data) => {
    saveOrgInfo(data);
  };

  const handleAddOrg = () => {
    addOrg({
      name: '',
      mission: '',
      programDesign: '',
      expectedResults: '',
      organizationType: ORGANIZATION_TYPES.NON_PROFIT,
      statementOfNeed: '',
      annualBudget: '',
      employeeInternalId: '',
    });
  };

  const orgProps = {
    onSubmit,
    setValue,
    control,
    isLoading,
    errors,
    handleSubmit,
    handleInvalidFormData,
    selectedOrg,
    setSelectedOrg,
    organizations,
    handleAddOrg,
    removeOrg,
    tabList,
    setTabList,
    disableMultipleOrgs: DISABLE_MULTIPLE_ORGS,
  };

  return (
    <Container>
      <Box mb={4} mx="auto" width={{ xs: '100%', md: 1000 }}>
        <Box component={Card}>
          <Box component={CardContent} px={{ xs: 3, md: 6.875 }} py={7.375}>
            <Box pl={{ xs: 0, md: 4 }}>
              <Title2 fontFamily="Playfair Display">
                Organization Information
              </Title2>
              <Body color="ui56" mt={0} mb={5}>
                The following information will help us.
              </Body>
            </Box>
            <Flex flexDirection={{ xs: 'column', md: 'row' }}>
              <TabContext value={selectedOrg?.id}>
                {!DISABLE_MULTIPLE_ORGS && (
                  <OrgSidebar {...orgProps}></OrgSidebar>
                )}
                <OrgForm {...orgProps} />
              </TabContext>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default OrgSetup;
