import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import Text from 'components/Text';
import Spinner from 'components/Spinner';

const UserPlanStatus = ({ isFetching, userPlans }) => {
  const status = useMemo(() => {
    const { items, total } = userPlans || {};

    if (total === 0 || !items) {
      return 'One Time Payment';
    }

    const [latestPlan] = items;

    if (latestPlan?.active) {
      return 'Has Paid Latest Recent Fee';
    }

    return 'Recurring Payment But Has Not Paid Recent Fee';
  }, [userPlans]);

  return (
    <>
      {isFetching ? (
        <Spinner />
      ) : (
        <Box>
          <Text variant="body2" color="black">
            {status}
          </Text>
        </Box>
      )}
    </>
  );
};

export default UserPlanStatus;
