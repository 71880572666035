import GrantsList from 'components/GrantsList';
import { GRANTS_TYPES } from 'utils/constants';

const TemplatesPage = () => (
  <GrantsList
    type={GRANTS_TYPES.GRANT}
    path="templates"
    header="Grant Templates"
    enableOverlay={false}
    subheader="The Grant Suite search bar can help you in finding the most recent grant templates for your organization or business. Use interests, focuses, and keywords to narrow your search."
    listHeader="Featured Templates"
  />
);

export default TemplatesPage;
