import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Autocomplete,
  lighten,
  styled,
  SvgIcon,
  TextField,
} from '@mui/material';
import { getGrantsQuery } from 'api';
import { Title2 } from 'components/Text';
import { ReactComponent as ArrowIcon } from 'images/icons/arrow.svg';
import { BRAND_COLORS } from 'theme';
import { getUnixDate } from 'utils/helpers/misc';

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiInputBase-root': {
    backgroundColor: `${BRAND_COLORS.white} !important`,
  },
  '& .MuiIconButton-root:hover': {
    background: 'transparent',
    svg: {
      color: lighten('#000', 0.5),
    },
  },
});

const GrantSelector = ({ type, defaultGrant, onChange }) => {
  const [selectedGrant, setSelectedGrant] = useState(defaultGrant);
  const filters = useMemo(
    () => ({
      filter: {
        type,
        status: 'published',
        expirationDate: getUnixDate(),
      },
      sort: { title: 1 },
      limit: 10000,
    }),
    [type]
  );
  const { data: grantsData } = useQuery(getGrantsQuery(filters));
  const options = useMemo(() => grantsData?.items ?? [], [grantsData?.items]);

  const handleChange = (_, value) => {
    setSelectedGrant(value);
    if (onChange) onChange(value);
  };

  return (
    <>
      <Title2
        fontFamily="Playfair Display"
        ml={2}
        mb={5.5}
        color={BRAND_COLORS.darkGray}
      >
        {`Write a ${type}`}
      </Title2>
      <StyledAutocomplete
        disablePortal
        options={options}
        getOptionLabel={(option) => option.title}
        value={selectedGrant}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label={`Type of ${type}`} fullWidth />
        )}
        popupIcon={
          <SvgIcon sx={{ height: 14.9 }}>
            <ArrowIcon />
          </SvgIcon>
        }
        sx={{ mb: 1 }}
      />
    </>
  );
};

export default GrantSelector;
