/* eslint-disable no-console */
import { getToken, getUserByToken, register as registerApi } from 'api';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import mixpanel from 'mixpanel-browser';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AUTH_TOKEN_KEY } from 'utils/constants/global';
import {
  removeAuthTokenFromCookie,
  setAuthCookie,
} from 'utils/helpers/auth.helpers';
import ReactGA from 'react-ga4';

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { showSnackbar } = useSnackbar();
  const [user, setUser] = useState();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [signupErrors, setSignupErrors] = useState();

  const fullName = useMemo(() => {
    if (!user) return 'Not available';
    const name = `${user.firstName} ${user.lastName}`;
    if (!name.trim()) return 'Not available';
    return name;
  }, [user]);

  const refreshUser = async (navigateTo) => {
    try {
      const userByToken = await getUserByToken();
      if (!userByToken) {
        navigate('/auth/sign-in');
        return;
      }
      setUser(userByToken);
      if (!userByToken.isFullyPaid) {
        navigate('/auth/verify-account');
        return;
      }
      if (hotjar.initialized()) {
        hotjar.identify(userByToken.id, userByToken);
      }
      ReactGA.set({ userId: userByToken.id, clientId: userByToken.id });
      ReactGA.event({
        category: 'User Actions',
        action: 'logged_in_account',
        label: `Logged in for ${userByToken.id}`,
      });
      if (navigateTo) navigate(navigateTo);
    } catch (e) {
      ReactGA.event({
        category: 'User Actions',
        action: 'unauthenticated_account_access',
      });
      ReactGA.set({ userId: undefined, clientId: undefined });
      if (
        location.pathname.startsWith('/auth/') &&
        !location.pathname.includes('/auth/sign-in')
      ) {
        return;
      }
      const returnUrl = `${location.pathname}${location.search}`;
      navigate(`/auth/sign-in?returnUrl=${returnUrl}`);
    } finally {
      if (!isInitialized) setIsInitialized(true);
    }
  };

  const signIn = async (email, password, navigateTo) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const res = await getToken(email, password);
      if (!res.access_token) throw new Error();
      setAuthCookie(res.access_token);
      const returnUrl = searchParams.get('returnUrl');
      await refreshUser(navigateTo || returnUrl || '/');
    } catch (err) {
      showSnackbar({
        message: err?.message ?? 'Invalid credentials',
        variant: SNACKBAR_VARIANTS.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = (redirect = true) => {
    setUser(undefined);
    removeAuthTokenFromCookie(AUTH_TOKEN_KEY);
    if (redirect) navigate('/auth/sign-in');
  };

  const { mutate: register, isLoading: isRegisterLoading } = useMutation(
    registerApi,
    {
      onMutate: () => {
        setSignupErrors(undefined);
      },
      onSuccess: async (_, data) => {
        if (!data) return;
        mixpanel.track('Sign up', data);
        navigate('/auth/verify-account');
        ReactGA.event({
          category: 'User Actions',
          action: 'register_account',
          label: `Registered account for ${data.email} user`,
        });
      },
      onError: (error) => {
        setSignupErrors((prev) => ({ ...prev, ...error }));
      },
    }
  );

  useEffect(() => {
    refreshUser();
  }, []);

  const defaultContext = {
    isInitialized,
    isLoading,
    register,
    isRegisterLoading,
    signupErrors,
    user,
    fullName,
    signIn,
    signOut,
    refreshUser,
  };

  return (
    <AuthContext.Provider value={defaultContext}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
