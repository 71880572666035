/* eslint-disable import/prefer-default-export */
import {
  PersonOutline,
  InboxOutlined,
  Article,
  Settings,
} from '@mui/icons-material';
import { ReactComponent as Dashboard } from 'images/icons/dashboard.svg';
import { ReactComponent as Application } from 'images/icons/application.svg';
import { ReactComponent as Letter } from 'images/icons/letter.svg';
import { ReactComponent as Opportunities } from 'images/icons/opportunities.svg';
import { ReactComponent as Faq } from 'images/icons/faq.svg';

export const NAV_LINKS = [
  { label: 'Home', href: '/', icon: <Dashboard /> },
  {
    label: 'Opportunities',
    href: '/opportunities',
    icon: <Opportunities />,
  },
  { label: 'Templates', href: '/templates', icon: <Opportunities /> },
  { label: 'Letters', href: '/letters', icon: <Letter /> },
  { label: 'Manage Letters', href: '/my-letters', icon: <Letter /> },
  { label: 'Manage Grants', href: '/my-grants', icon: <Application /> },
  { label: 'FAQs', href: '/faqs', icon: <Faq /> },
  {
    label: 'Submit a Report',
    href: 'https://api.leadconnectorhq.com/widget/form/sCeTZdwUvMuPqh3rXGxD?fbclid=IwAR2s53jVUikaYntYsibW_eihIuTPDJz0z-SNsFzbyYaF0xeY8Sn0mEpxcvE',
    isOutsideLink: true,
    icon: <Faq />,
  },
  {
    label: 'Admin',
    isAdmin: true,
    icon: <InboxOutlined />,
    children: [
      {
        label: 'Users',
        href: '/admin/manage-users',
        icon: <PersonOutline />,
      },
      {
        label: 'Grants',
        href: '/admin/manage-grants',
        icon: <Article />,
      },
      {
        label: 'User Grants',
        href: '/admin/manage-user-grants',
        icon: <Article />,
      },
      {
        label: 'Settings',
        href: '/admin/settings',
        icon: <Settings />,
      },
    ],
  },
];
