/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const urlRegex =
  /(https?|http?):\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const phoneRegex = /^\+?(?:[0-9]●?){6,14}[0-9]$/;

export const hasLowerCaseRegex = /^(?=.*[a-z]).+$/;

export const hasUpperCaseRegex = /^(?=.*[A-Z]).+$/;

export const hasNumericRegex = /^(?=.*\d).+$/;

export const hasSpecialCharacterRegex = /^(?=.*[^\da-zA-Z]).+$/;

export const VALIDATORS = {
  email: {
    required: {
      value: true,
      message: 'Email/username is required',
    },
    pattern: {
      value: emailRegex,
      message: 'Enter a valid email address',
    },
  },
  password: {
    required: {
      value: true,
      message: 'Password is required',
    },
  },
  url: {
    pattern: {
      value: urlRegex,
      message: 'Enter a valid link starting with http://',
    },
  },
  phone: {
    pattern: {
      value: phoneRegex,
      message: 'Enter a valid phone number',
    },
  },
};

export const PASSWORD_VALIDATION_RULES = {
  REQUIRED: {
    valid: (value) => !!value,
    message: 'Password is required',
  },
  MIN_LENGTH: {
    valid: (value) => value.length >= 8,
    message: 'At least 8 characters',
  },
  MAX_LENGTH: {
    valid: (value) => value.length <= 32,
    message: 'Maximum of 32 characters',
  },
  UPPER_CASE_AND_LOWER_CASE: {
    valid: (value) =>
      hasLowerCaseRegex.test(value) && hasUpperCaseRegex.test(value),
    message: 'Uppercase and lowercase',
  },
  NUMBER_AND_SYMBOL: {
    valid: (value) =>
      hasNumericRegex.test(value) && hasSpecialCharacterRegex.test(value),
    message: 'Contains number and symbol',
  },
};
