import { Add } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from '@mui/material';
import {
  getPaymentsHistoryQuery,
  getUserByIdQuery,
  getUserPlansQuery,
  updateUser,
} from 'api';
import Field from 'components/Field';
import Flex from 'components/Flex';
import OrganizationForm from 'components/OrganizationForm';
import PageHeader from 'components/PageHeader';
import PaymentsHistoryTable from 'components/PaymentsHistoryTable';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import Spinner from 'components/Spinner';
import Text, { Body, Large, Small } from 'components/Text';
import UserPlanStatus from 'components/UserPlanStatus';
import { useAuth } from 'contexts/Auth';
import { useOrganization } from 'contexts/Organization';
import { format, isValid } from 'date-fns';
import { useManageOrganizations } from 'hooks/useManageOrganizations';
import DefaultProfilePicture from 'images/default-profile.png';
import { uniqueId } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { ORGANIZATION_TYPES } from 'utils/constants';
import { v4 as uuidv4 } from 'uuid';
import ProfilePicture from './ProfilePicture';

const getDefaultData = (user) => ({
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  phone: user?.phone || '',
  isFullyPaid: user?.isFullyPaid || false,
  emailVerification: {
    isVerified: user?.emailVerification?.isVerified || false,
  },
  profilePictureUrl: user?.profilePictureUrl || DefaultProfilePicture,
  organizations: [
    ...(user?.organizations?.map((o) => ({ ...o, id: uniqueId() })) || []),
  ],
});

const EditUsersPage = () => {
  const { id } = useParams();
  const { showSnackbar } = useSnackbar();
  const [expandedOrg, setExpandedOrg] = useState();
  const { user: currentUser } = useAuth();
  const { refreshOrganizations: refreshOwnOrganizations } = useOrganization();
  const { organizations, refreshOrganizations, isFetching } =
    useManageOrganizations(id);
  const [newOrg, setNewOrg] = useState();
  const {
    data: user,
    isLoading: isInitLoading,
    refetch,
  } = useQuery(getUserByIdQuery(id));

  const { data: userPlans, isFetching: isFetchingUserPlans } = useQuery(
    getUserPlansQuery({
      limit: Number.MAX_SAFE_INTEGER,
      sort: { createdDate: -1 },
      filter: {
        userId: currentUser.id,
        active: true,
      },
    })
  );

  const { data: paymentsHistory, isFetching: isFetchingPaymentHistory } =
    useQuery(
      getPaymentsHistoryQuery({
        limit: Number.MAX_SAFE_INTEGER,
        sort: { createdDate: -1 },
        filter: {
          userId: currentUser.id,
        },
      })
    );

  const { mutate: saveUser, isLoading: isSaving } = useMutation(
    (data) => updateUser(id, data),
    {
      onSuccess: (data) => {
        if (!data) return;
        refetch();
        showSnackbar({
          variant: SNACKBAR_VARIANTS.SUCCESS,
          message: 'Successfully saved user',
        });
      },
      onError: (err) => {
        showSnackbar({
          variant: SNACKBAR_VARIANTS.ERROR,
          message: err,
        });
      },
    }
  );

  const paymentDate = useMemo(() => {
    if (!user) return undefined;
    if (!user.isFullyPaid) return undefined;
    const paymentDateValue = new Date(user.paymentDate);
    const modifiedDate = new Date(user.modifiedDate);
    if (isValid(paymentDateValue))
      return format(paymentDateValue, 'MMM dd, yyyy HH:mm');
    if (isValid(modifiedDate))
      return format(modifiedDate, 'MMM dd, yyyy HH:mm');
    return undefined;
  }, [user]);

  const handleSaveUser = (formData) => {
    saveUser(formData);
  };

  const methods = useForm({ defaultValues: getDefaultData(user) });
  const {
    formState: { errors, isDirty },
    register,
    handleSubmit,
    reset,
    control,
  } = methods;

  useEffect(() => {
    if (!user) return;
    reset(getDefaultData(user));
  }, [user]);

  const handleAddOrganization = () => {
    const orgId = uuidv4();
    setExpandedOrg(orgId);
    setNewOrg({
      id: orgId,
      name: '',
      mission: '',
      programDesign: '',
      expectedResults: '',
      organizationType: ORGANIZATION_TYPES.NON_PROFIT,
      statementOfNeed: '',
      annualBudget: '',
      employeeInternalId: '',
    });
  };

  const handleOnCancelOrg = (isNew) => {
    if (isNew) {
      setNewOrg(undefined);
      return;
    }
    refreshOrganizations();
    if (user?.roles?.includes('curator') && user.id === currentUser.id) {
      refreshOwnOrganizations();
    }
    setExpandedOrg(undefined);
  };

  const handleOnOrgExpand = (orgId) => {
    setExpandedOrg(orgId);
  };

  return (
    <>
      <PageHeader
        title={`User: ${user?.firstName || ''} ${user?.lastName || ''}`}
        hideAction
      />
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        px={3}
      >
        <FormProvider {...methods}>
          <Box
            component="form"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            onSubmit={handleSubmit(handleSaveUser)}
          >
            <Box component={Card} width={{ xs: '100%', md: 568 }} mb={4}>
              <CardContent>
                {(isInitLoading || !user) && <Spinner />}
                {user && (
                  <>
                    <Box
                      noValidate
                      autoComplete="off"
                      display="flex"
                      flexDirection="column"
                      mb={6}
                    >
                      <Text variant="h3" mb={3}>
                        Basic Information
                      </Text>
                      <Flex alignItems="center" mb={5}>
                        <Controller
                          name="profilePictureUrl"
                          control={control}
                          render={({ field }) => (
                            <ProfilePicture
                              url={field.value}
                              onChange={(response) => field.onChange(response)}
                            />
                          )}
                        />
                        <Flex
                          flexGrow={1}
                          flexDirection="column"
                          justifyContent="center"
                          ml={4}
                        >
                          <Large bold mt={0} mb={1}>
                            {user.email}
                          </Large>
                          <Body my={0} color="grey.800">
                            {user.roles?.includes('curator')
                              ? 'Curator'
                              : 'Applicant'}
                          </Body>
                        </Flex>
                      </Flex>
                      <Field label="First Name" mb={2}>
                        <TextField
                          error={!!errors?.firstName}
                          helperText={errors?.firstName?.message}
                          name="firstName"
                          {...register('firstName', {
                            required: {
                              value: true,
                              message: 'First name is required',
                            },
                          })}
                          fullWidth
                        />
                      </Field>
                      <Field label="Last Name" mb={2}>
                        <TextField
                          error={!!errors?.lastName}
                          helperText={errors?.lastName?.message}
                          name="lastName"
                          {...register('lastName', {
                            required: {
                              value: true,
                              message: 'lastName is required',
                            },
                          })}
                          fullWidth
                        />
                      </Field>
                      <Field label="Contact" mb={2}>
                        <TextField
                          error={!!errors?.phone}
                          helperText={errors?.phone?.message}
                          name="phone"
                          {...register('phone')}
                          fullWidth
                        />
                      </Field>
                    </Box>
                    <Box
                      noValidate
                      autoComplete="off"
                      display="flex"
                      flexDirection="column"
                      mb={6}
                    >
                      <Text variant="h3" mb={3}>
                        Verification
                      </Text>
                      <Controller
                        name="isFullyPaid"
                        control={control}
                        render={({ field }) => (
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) =>
                                    field.onChange(e.target.checked)
                                  }
                                  name="isFullyPaid"
                                />
                              }
                              label="Fully paid"
                            />
                            {paymentDate && (
                              <Small color="gray.600" my={0}>
                                Paid at {paymentDate}
                              </Small>
                            )}
                          </>
                        )}
                      />
                      <Controller
                        name="emailVerification.isVerified"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                                name="emailVerification.isVerified"
                              />
                            }
                            label="Email verified"
                          />
                        )}
                      />
                    </Box>
                  </>
                )}
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSaving}
                  loadingIndicator="Saving..."
                  fullWidth
                  disabled={!isDirty || isSaving}
                  sx={{ px: 5 }}
                >
                  Save
                </LoadingButton>
              </CardContent>
            </Box>
          </Box>
        </FormProvider>
        <Box component={Card} width={{ xs: '100%', md: 568 }} mb={4}>
          <CardContent>
            {(isInitLoading || !user) && <Spinner />}
            {user && (
              <Box>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text variant="h3">Organization Information</Text>
                  <IconButton onClick={() => handleAddOrganization()}>
                    <Add color="success" />
                  </IconButton>
                </Flex>
                {isFetching ? (
                  <Spinner />
                ) : (
                  <>
                    {organizations?.map((org) => (
                      <OrganizationForm
                        key={org.id}
                        userId={user?.id}
                        organization={org}
                        expanded={org.id === expandedOrg}
                        onExpand={handleOnOrgExpand}
                        onCancel={handleOnCancelOrg}
                        onSuccessDelete={handleOnCancelOrg}
                        onSuccessSave={handleOnCancelOrg}
                      />
                    ))}
                    {newOrg && (
                      <OrganizationForm
                        organization={newOrg}
                        userId={user?.id}
                        isNew
                        expanded={newOrg.id === expandedOrg}
                        onExpand={handleOnOrgExpand}
                        onCancel={handleOnCancelOrg}
                        onSuccessDelete={handleOnCancelOrg}
                        onSuccessSave={handleOnCancelOrg}
                      />
                    )}
                  </>
                )}
              </Box>
            )}
          </CardContent>
        </Box>
        <Box component={Card} width={{ xs: '100%', md: 568 }} mb={4}>
          <CardContent>
            {(isInitLoading || !user) && <Spinner />}
            {user && (
              <Box>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text variant="h3">User Plan</Text>
                </Flex>
                <UserPlanStatus
                  isFetching={isFetchingUserPlans}
                  userPlans={userPlans}
                />
              </Box>
            )}
          </CardContent>
        </Box>
        {userPlans?.length > 1 && (
          <Box component={Card} width={{ xs: '100%', md: 568 }} mb={4}>
            <CardContent>
              {(isInitLoading || !user) && <Spinner />}
              {user && (
                <Box>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text variant="h3">Payment History</Text>
                  </Flex>
                  <PaymentsHistoryTable
                    isFetching={isFetchingPaymentHistory}
                    paymentsHistory={paymentsHistory}
                  />
                </Box>
              )}
            </CardContent>
          </Box>
        )}
      </Flex>
    </>
  );
};

export default EditUsersPage;
