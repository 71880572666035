import { CheckOutlined, ExpandMore, Remove } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Small } from 'components/Text';
import { useManageOrganizations } from 'hooks/useManageOrganizations';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ORGANIZATION_TYPES } from 'utils/constants';
import BoardMembersList from '../BoardMembersList';
import Field from '../Field';
import Flex from '../Flex';
import { LocationField, LocationSearchField } from '../LocationField';
import UpdateFieldsDialog from './UpdateFieldsDialog';

const DISABLE_MULTIPLE_ORGS =
  process.env.REACT_APP_DISABLE_MULTIPLE_ORGS === 'true';

const getDefaultValues = (org) => ({
  name: org?.name || '',
  isActive: org?.isActive || '',
  mission: org?.mission || '',
  programDesign: org?.programDesign || '',
  expectedResults: org?.expectedResults || '',
  organizationType: org?.organizationType || ORGANIZATION_TYPES.NON_PROFIT,
  statementOfNeed: org?.statementOfNeed || '',
  annualBudget: org?.annualBudget || '',
  employeeInternalId: org?.employeeInternalId || '',
  boardMembers: org?.boardMembers || [],
  state: org?.state || '',
  city: org?.city || '',
  address: org?.address || '',
  zipCode: org?.zipCode || '',
  country: org?.country || '',
});

const parseRequestBody = (data, organizations = []) => {
  const req = { ...data };
  if (req.isActive === '') {
    req.isActive = organizations.length === 0;
  }
  return req;
};

const OrganizationForm = ({
  organization,
  userId,
  isNew,
  expanded,
  onExpand,
  onCancel,
  onSuccessSave,
  onSuccessDelete,
}) => {
  const {
    organizations,
    createSuccess,
    deleteSuccess,
    updateOrganization,
    deleteOrganization,
    createOrganization,
  } = useManageOrganizations(userId);
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [isUpdateUserFieldOpen, setIsUpdateUserFieldOpen] = useState();
  const methods = useForm({
    defaultValues: getDefaultValues(organization),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    register,
    getValues,
    setValue,
  } = methods;

  const getOrgName = () => {
    const values = getValues();
    return values?.name || 'Unnamed organization';
  };

  const onSubmit = (data) => {
    const body = { ...parseRequestBody(data, organizations), userId };
    if (isNew) {
      createOrganization(body);
    } else {
      setIsUpdateUserFieldOpen(true);
    }
  };

  const handleUpdateUserFieldOnSave = (updateUserFields) => {
    const data = getValues();
    const body = { ...parseRequestBody(data, organizations), userId };
    updateOrganization(organization.id, {
      ...body,
      showPrompt: true,
      updateUserFields,
    });
    setIsUpdateUserFieldOpen(false);
  };

  const handleExpand = (_, isAccordionExpanded) => {
    if (!onExpand) return;
    onExpand(isAccordionExpanded ? organization.id : undefined);
  };

  const handleDelete = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    deleteOrganization(organization.id);
  };

  useEffect(() => {
    reset(getDefaultValues(organization));
  }, [organization]);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  useEffect(() => {
    if (!createSuccess) return;
    onSuccessSave();
  }, [createSuccess]);

  useEffect(() => {
    if (!deleteSuccess) return;
    onSuccessDelete();
  }, [deleteSuccess]);

  const isOrgActive = watch('isActive');

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        key={organization.id}
      >
        <Accordion
          expanded={isExpanded}
          onChange={handleExpand}
          sx={{
            '& .MuiAccordionSummary-content': {
              '&.Mui-expanded': {
                marginTop: 1.5,
                marginBottom: 1.5,
              },
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Flex alignItems="center">
              {(!DISABLE_MULTIPLE_ORGS || !isNew) && (
                <IconButton onClick={handleDelete}>
                  <Remove color="error" />
                </IconButton>
              )}
              <Flex alignItems="center">
                {isOrgActive && (
                  <Small my={0} mr={0.5}>
                    <CheckOutlined color="primary" />
                  </Small>
                )}
                <Typography
                  color={isOrgActive ? 'primary.main' : 'text.primary'}
                >
                  {getOrgName()}
                </Typography>
              </Flex>
            </Flex>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column">
              <Box mb={2}>
                <Controller
                  name="organizationType"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      name="quiz"
                      defaultValue={organization.organizationType}
                      onChange={field.onChange}
                    >
                      <Box display="flex" flex-direction="row" width="100%">
                        <FormControlLabel
                          value={ORGANIZATION_TYPES.NON_PROFIT}
                          control={<Radio />}
                          label="Non-profit"
                          sx={{ mr: 4 }}
                        />
                        <FormControlLabel
                          value={ORGANIZATION_TYPES.FOR_PROFIT}
                          control={<Radio />}
                          label="Profit"
                        />
                      </Box>
                    </RadioGroup>
                  )}
                />
              </Box>
              <Field label="Set organization as default or default" mb={2}>
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      label="Make this organization default or active"
                      control={
                        <Checkbox
                          checked={isOrgActive}
                          onChange={(e) => {
                            const { checked } = e.target;
                            field.onChange(checked);
                          }}
                        />
                      }
                    />
                  )}
                />
              </Field>
              <Field label="Organization Name" mb={2}>
                <TextField
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  name="name"
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'Organization name is required',
                    },
                  })}
                  fullWidth
                />
              </Field>
              <Field label="Annual Budget" mb={2}>
                <TextField
                  error={!!errors?.annualBudget}
                  helperText={errors?.annualBudget?.message}
                  name="annualBudget"
                  type="number"
                  {...register('annualBudget', {
                    required: {
                      value: true,
                      message: 'Annual Budget is required',
                    },
                  })}
                  fullWidth
                />
              </Field>
              <Field label="Mission Statement" mb={2}>
                <TextField
                  multiline
                  rows={4}
                  error={!!errors?.mission}
                  helperText={errors?.mission?.message}
                  name="mission"
                  {...register('mission', {
                    required: {
                      value: true,
                      message: 'Mission statement is required',
                    },
                    maxLength: {
                      value: 500,
                      message:
                        'Mission statement must be less than 500 characters',
                    },
                  })}
                  fullWidth
                />
              </Field>
              <Field label="Program Design" mb={2}>
                <TextField
                  error={!!errors?.programDesign}
                  helperText={errors?.programDesign?.message}
                  name="programDesign"
                  {...register('programDesign', {
                    required: {
                      value: true,
                      message: 'Program design is required',
                    },
                  })}
                  fullWidth
                />
              </Field>
              <Field label="Employer Identification Number" mb={2}>
                <TextField
                  error={!!errors?.employeeInternalId}
                  helperText={errors?.employeeInternalId?.message}
                  name={`employeeInternalId`}
                  {...register(`employeeInternalId`, {
                    required: {
                      value: true,
                      message: 'Employer Identification Number is required',
                    },
                  })}
                  fullWidth
                />
              </Field>
              <Field label="Expected results / outcomes" mb={2}>
                <TextField
                  multiline
                  rows={4}
                  error={!!errors?.expectedResults}
                  helperText={errors?.expectedResults?.message}
                  name="expectedResults"
                  {...register('expectedResults', {
                    required: {
                      value: true,
                      message: 'Expected results/outcomes is required',
                    },
                    maxLength: {
                      value: 500,
                      message:
                        'Expected results must be less than 500 characters',
                    },
                  })}
                  fullWidth
                />
              </Field>
              <Field label="Statement of Need" mb={2}>
                <TextField
                  multiline
                  rows={4}
                  error={!!errors?.statementOfNeed}
                  helperText={errors?.statementOfNeed?.message}
                  name="statementOfNeed"
                  {...register('statementOfNeed', {
                    required: {
                      value: true,
                      message: 'Statement of need is required',
                    },
                    maxLength: {
                      value: 500,
                      message:
                        'Statement of need must be less than 500 characters',
                    },
                  })}
                  fullWidth
                />
              </Field>
              <Field label="Enter your organization's address" mb={2}>
                <LocationField>
                  <LocationSearchField
                    onMapSelect={(map) => {
                      setValue(`address`, map.address);
                      setValue(`city`, map.city);
                      setValue(`state`, map.state);
                      setValue(`county`, map.county);
                      setValue(`country`, map.country);
                      setValue(`zipCode`, map.zipCode);
                    }}
                  />
                </LocationField>
              </Field>
              <Field label="Address" mb={2}>
                <TextField
                  error={!!errors?.address}
                  helperText={errors?.address?.message}
                  name="address"
                  {...register('address', {
                    required: {
                      value: true,
                      message: 'Address is required',
                    },
                  })}
                  fullWidth
                />
              </Field>
              <Field label="State" mb={2}>
                <TextField
                  error={!!errors?.state}
                  helperText={errors?.state?.message}
                  name={`state`}
                  {...register(`state`, {
                    required: {
                      value: true,
                      message: 'State is required',
                    },
                  })}
                  fullWidth
                />
              </Field>
              <Field label="City" mb={2}>
                <TextField
                  error={!!errors?.city}
                  helperText={errors?.city?.message}
                  name="city"
                  {...register('city', {
                    required: {
                      value: true,
                      message: 'City is required',
                    },
                  })}
                  fullWidth
                />
              </Field>
              <Field label="Country" mb={2}>
                <TextField
                  error={!!errors?.country}
                  helperText={errors?.country?.message}
                  name="country"
                  {...register('country', {
                    required: {
                      value: true,
                      message: 'Country is required',
                    },
                  })}
                  fullWidth
                />
              </Field>
              <Field label="Zip Code" mb={2}>
                <TextField
                  error={!!errors?.zipCode}
                  helperText={errors?.zipCode?.message}
                  name="zipCode"
                  {...register('zipCode', {
                    required: {
                      value: true,
                      message: 'Zip Code is required',
                    },
                  })}
                  fullWidth
                />
              </Field>
              <BoardMembersList
                control={control}
                register={register}
                errors={errors}
                mb={4}
              />
              <Grid container spacing={2} sx={{ width: '100%' }}>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={() => onCancel(isNew)}
                    variant="outline"
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button type="submit" variant="contained" fullWidth>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <UpdateFieldsDialog
        open={isUpdateUserFieldOpen}
        onClose={() => handleUpdateUserFieldOnSave(false)}
        onConfirm={() => handleUpdateUserFieldOnSave(true)}
      />
    </>
  );
};

export default OrganizationForm;
