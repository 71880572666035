import { Box, Grid, Link as MuiLink, styled } from '@mui/material';
import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Flex from 'components/Flex';
import Link from 'components/Link';
import Text from 'components/Text';
import { format, isValid } from 'date-fns';
import { GRANTS_TYPES } from 'utils/constants';

const CardButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5),
  fontWeight: 500,
}));

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: 600,
    },
  },
}));

const getLink = (grant) => {
  if (grant.type === GRANTS_TYPES.LINK) return grant.url;
  return `/${
    grant.type === GRANTS_TYPES.GRANT ? 'my-grants' : 'my-letters'
  }/new?grantId=${grant.id}`;
};

const getButtonLabel = (type) => {
  switch (type) {
    case GRANTS_TYPES.LETTER:
      return 'Write Letter';
    default:
      return 'Use this Grant Template';
  }
};

const getGrantUrl = (grant) => {
  switch (grant.type) {
    case GRANTS_TYPES.LETTER:
      return `/my-letters/new?grantId=${grant.id}`;
    case GRANTS_TYPES.GRANT:
      return `/my-grants/new-template?grantId=${grant.id}`;
    default:
      return `/my-grants/new-opportunity?grantId=${grant.id}`;
  }
};

const GrantDialog = ({ grant, open, onClose, ...props }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Box
      component={Dialog}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      minWidth={{ xs: '100%', md: 600 }}
      {...props}
    >
      <DialogTitle>{grant?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box sx={{ mb: 4, overflow: 'auto' }}>
            {grant?.description && (
              <Text
                variant="body2"
                color="black"
                display={'block'}
                maxheight={'350px'}
              >
                {grant?.description}
              </Text>
            )}
          </Box>
          {grant.type !== GRANTS_TYPES.LETTER && (
            <Grid spacing={2} container>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Text variant="body2" color="black" display={'block'}>
                    Expiration Date:
                  </Text>
                  <Text variant="body2" color="black" fontWeight={700}>
                    {isValid(new Date(grant?.expirationDate))
                      ? format(new Date(grant.expirationDate), 'MMM dd, yyyy')
                      : 'N/A'}
                  </Text>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Text variant="body2" color="black" display={'block'}>
                    Estimated Total Program Funding:
                  </Text>
                  <Text variant="body2" color="black" fontWeight={700}>
                    ${grant.value.toFixed(2)}
                  </Text>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Flex
          flexDirection={{ xs: 'column', md: 'row' }}
          width={{ xs: 320, md: '100%' }}
          justifyContent="space-between"
          alignItems="center"
          mx={{ xs: 'auto', md: '0px' }}
        >
          <CardButton
            autoFocus
            onClick={handleClose}
            sx={{
              color: 'background.button.dark',
              width: { xs: '100%', md: 'auto' },
            }}
          >
            Close
          </CardButton>
          <Flex
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="flex-end"
            gap={{ xs: 1, md: 0 }}
          >
            {grant.type === GRANTS_TYPES.LINK && (
              <MuiLink
                variant="contained"
                underline="none"
                rel="noreferer"
                target="_blank"
                href={getLink(grant)}
                sx={{
                  width: { xs: '100%', md: 'auto' },
                }}
              >
                <CardButton
                  variant="contained"
                  sx={{
                    backgroundColor: 'secondary.main',
                    width: { xs: '100%', md: 'auto' },
                  }}
                >
                  Visit Source
                </CardButton>
              </MuiLink>
            )}
            {(grant.hasContent ||
              [GRANTS_TYPES.LETTER, GRANTS_TYPES.GRANT].includes(
                grant.type
              )) && (
              <Link
                href={getGrantUrl(grant)}
                sx={{ width: { xs: '100%', md: 'auto' } }}
              >
                <CardButton
                  variant="contained"
                  sx={{
                    backgroundColor: 'background.button.dark',
                    ml: { xs: 0, md: 1 },
                    width: { xs: '100%', md: 'auto' },
                  }}
                >
                  {getButtonLabel(grant.type)}
                </CardButton>
              </Link>
            )}
          </Flex>
        </Flex>
      </DialogActions>
    </Box>
  );
};
export default GrantDialog;
