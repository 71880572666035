/* eslint-disable arrow-body-style */
import { Box, Button, Card, CardContent, TextField } from '@mui/material';
import { deleteUserGrant, getUserGrantsQuery } from 'api';
import CompactTable from 'components/CompactTable';
import Actions from 'components/CompactTable/Actions';
import Flex from 'components/Flex';
import Link from 'components/Link';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { Body, Title2 } from 'components/Text';
import { useAuth } from 'contexts/Auth';
import { useOrganization } from 'contexts/Organization';
import { format, isValid } from 'date-fns';
import { useDebouncedInput } from 'hooks/useDebouncedInput';
import { useDownloadGrant } from 'hooks/useDownloadGrant';
import { usePrint } from 'hooks/usePrint';
import { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { BRAND_COLORS } from 'theme';
import { humanize } from 'underscore.string';
import { GRANTS_TYPES } from 'utils/constants';
import { downloadFile } from 'utils/helpers/misc';
import DeleteDialog from './DeleteDialog';
import DownloadDialog from './Editor/DownloadDialog';

const NEW_GRANT_URL_MAP = {
  [GRANTS_TYPES.GRANT]: '/my-grants/new-template',
  [GRANTS_TYPES.LETTER]: '/my-letters/new',
  [GRANTS_TYPES.LINK]: '/my-grants/new-opportunity',
};

const MyGrantsPage = ({ type }) => {
  const { user } = useAuth();
  const { activeOrganization } = useOrganization();
  const execPrint = usePrint();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const heading = useMemo(
    () => `Manage ${type === GRANTS_TYPES.GRANT ? 'Grants' : 'Letters'}`,
    [type]
  );
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const writeLabel = useMemo(() => `Write a ${type}`, [type]);
  const writeUrl = useMemo(() => NEW_GRANT_URL_MAP[type], [type]);
  const {
    value,
    debouncedValue: search,
    onChange: onSearchChanged,
    reset,
  } = useDebouncedInput();
  const { data: userGrants, refetch } = useQuery(
    getUserGrantsQuery({
      limit: 1500,
      sort: { createdDate: -1 },
      filter: {
        userId: user.id,
        organizationId: activeOrganization?.id,
        grant: {
          type:
            type === GRANTS_TYPES.GRANT
              ? [GRANTS_TYPES.GRANT, GRANTS_TYPES.LINK]
              : GRANTS_TYPES.LETTER,
        },
      },
      q: search,
    })
  );
  const items = useMemo(() => userGrants?.items ?? [], [userGrants?.items]);

  const { isDownloading, downloadPdf, downloadDocx } = useDownloadGrant({
    onDownloadPdfSuccess: (res) => {
      downloadFile(res, 'user-grant');
    },
    onDownloadDocxSuccess: (res) => {
      downloadFile(res, 'user-grant');
    },
  });
  const { mutate: deleteGrant, isLoading: isDeleting } = useMutation(
    deleteUserGrant,
    {
      onSuccess: async (res) => {
        if (!res) return;
        showSnackbar({
          message: 'Successfully deleted!',
          variant: SNACKBAR_VARIANTS.SUCCESS,
        });
        ReactGA.event({
          category: 'User Actions',
          action: 'delete_grant',
          label: `Deleted ${type} for ${selected.grant?.title} grant`,
        });
        setIsDeleteOpen(false);
        setSelected(null);
        refetch();
      },
    }
  );
  const handleDownload = (item, event) => {
    event.stopPropagation();
    setSelected(item);
    setIsDownloadDialogOpen(true);
  };

  const handleDownloadPdf = () => {
    setIsDownloadDialogOpen(false);
    if (selected) {
      downloadPdf(selected.id);
      ReactGA.event({
        category: 'User Actions',
        action: 'download_pdf',
        label: `Downloaded PDF for ${selected.grant?.title} grant`,
      });
    }
    setSelected(null);
  };

  const handleDownloadWord = () => {
    setIsDownloadDialogOpen(false);
    if (selected) {
      downloadDocx(selected.id);
      ReactGA.event({
        category: 'User Actions',
        action: 'download_word',
        label: `Downloaded Word for ${selected.grant?.title} grant`,
      });
    }
    setSelected(null);
  };

  const handlePrint = (row, event) => {
    event.stopPropagation();
    execPrint(row.grant, row.fields);
    ReactGA.event({
      category: 'User Actions',
      action: `print_${type.toLowerCase()}`,
      label: `Printed ${type} for ${row.grant?.title} grant`,
    });
  };

  const columns = useMemo(() => {
    if (!items) return [];
    return [
      {
        field: 'title',
        headerName: 'Title',
        flex: 1,
        renderCell: ({ row }) => (
          <Body my={0}>{row.grant?.title || 'N/A'}</Body>
        ),
      },
      {
        field: 'category',
        headerName: 'Category',
        flex: 1,
        renderCell: ({ row }) => (
          <Body my={0}>{humanize(row.grant?.category) || 'N/A'}</Body>
        ),
      },
      {
        field: 'createdDate',
        headerName: 'Date Created',
        flex: 1,
        renderCell: ({ row }) => (
          <Body my={0}>
            {isValid(new Date(row.createdDate))
              ? format(new Date(row.createdDate), 'MMM dd, yyyy')
              : 'N/A'}
          </Body>
        ),
      },
      {
        field: 'controls',
        headerName: 'Controls',
        flex: 1,
        renderCell: ({ row }) => (
          <Actions
            onDownload={(event) => handleDownload(row, event)}
            isDownloading={isDownloading}
            onPrint={(ev) => handlePrint(row, ev)}
            onDelete={(ev) => {
              ev.stopPropagation();
              setIsDeleteOpen(true);
              setSelected(row);
            }}
            isDeleting={isDeleting}
          />
        ),
      },
    ];
  }, [items]);

  const handleOnRowClick = (data) => {
    const { row } = data;
    if (!row) return;
    const { id } = row;
    navigate(`/my-${type}s/${id}`);
  };

  useEffect(() => reset(), [type]);

  return (
    <>
      <Box>
        <Title2
          fontFamily="Playfair Display"
          ml={2}
          mb={5.5}
          color={BRAND_COLORS.darkGray}
        >
          {heading}
        </Title2>
        <Flex
          alignItems={{ xs: 'start', md: 'center' }}
          flexDirection={{ xs: 'column', md: 'row' }}
          mb={5}
          gap={{ xs: 2, md: 0 }}
          justifyContent={{ xs: 'start', md: 'space-between' }}
        >
          <TextField
            value={value}
            placeholder={`Search ${type}`}
            sx={{ width: { xs: '100%', md: 413 } }}
            onChange={onSearchChanged}
          />
          <Link href={writeUrl} sx={{ width: { xs: '100%', md: 'auto' } }}>
            <Button
              size="small"
              variant="contained"
              sx={{ width: { xs: '100%', md: 'auto' } }}
            >
              {writeLabel}
            </Button>
          </Link>
        </Flex>
        <Card>
          <CardContent>
            <CompactTable
              columns={columns}
              rows={items}
              onRowClick={handleOnRowClick}
            />
          </CardContent>
        </Card>
      </Box>
      {isDeleteOpen && (
        <DeleteDialog
          open={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          onConfirm={() => deleteGrant(selected.id)}
          type={type}
        />
      )}
      {isDownloadDialogOpen && (
        <DownloadDialog
          open={isDownloadDialogOpen}
          onClose={() => setIsDownloadDialogOpen(false)}
          onDownloadDoc={handleDownloadWord}
          onDownloadPdf={handleDownloadPdf}
        />
      )}
    </>
  );
};

export default MyGrantsPage;
