import React, { useMemo } from 'react';
import { format, isValid } from 'date-fns';

import CompactTable from 'components/CompactTable';
import Spinner from 'components/Spinner';
import { Body } from 'components/Text';

const PaymentsHistoryTable = ({ isFetching, paymentsHistory }) => {
  const items = useMemo(
    () => paymentsHistory?.items ?? [],
    [paymentsHistory?.items]
  );

  const columns = useMemo(() => {
    if (!items) return [];
    return [
      {
        field: 'createdDate',
        headerName: 'Date Created',
        flex: 1,
        renderCell: ({ row }) => (
          <Body my={0}>
            {isValid(new Date(row.createdDate))
              ? format(new Date(row.createdDate), 'MMM dd, yyyy')
              : 'N/A'}
          </Body>
        ),
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
        renderCell: ({ row }) => <Body my={0}>{row?.amount || 'N/A'}</Body>,
      },
      {
        field: 'currency',
        headerName: 'Currency',
        flex: 1,
        renderCell: ({ row }) => <Body my={0}>{row?.currency || 'N/A'}</Body>,
      },
      {
        field: 'payment_method',
        headerName: 'Mode of Payment',
        flex: 1,
        renderCell: ({ row }) => {
          const { payment_method: paymentMethod } = row?.metadata || {};
          const type = paymentMethod?.type || 'N/A';
          const brand = paymentMethod?.brand || 'N/A';
          return <Body my={0}>{`${type} - ${brand}`}</Body>;
        },
      },
    ];
  }, [items]);

  return (
    <>
      {isFetching ? (
        <Spinner />
      ) : (
        <CompactTable columns={columns} rows={items} />
      )}
    </>
  );
};

export default PaymentsHistoryTable;
