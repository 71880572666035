import { CheckOutlined } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer as MuiDrawer,
  styled,
  useTheme,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { getUserGrantsById } from 'api';
import Flex from 'components/Flex';
import Link from 'components/Link';
import { Body, Small } from 'components/Text';
import { useAuth } from 'contexts/Auth';
import { useLayout } from 'contexts/Layout';
import { useOrganization } from 'contexts/Organization';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DRAWER_WIDTH, NAV_LINKS } from 'utils/constants';
import Logo from '../Logo';
import ChangeOrgDialog from './ChangeOrgDialog';

const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const OrganizationInfo = ({ organization, onChange, ...props }) => {
  const location = useLocation();
  const { updateOrganization, organizations, activeOrganization } =
    useOrganization();
  const isActive = useMemo(() => {
    if (organization?.isActive) return true;
    if (organizations.length === 1) return true;
    if (organization?.id === activeOrganization?.id) return true;
    return false;
  }, [organization, organizations, activeOrganization]);

  const isInEditorView = useMemo(() => {
    const routes = [
      '/my-grants/new-opportunity',
      '/my-grants/new-template',
      '/my-letters/new',
    ];
    const isInNew = routes.some((r) => location?.pathname?.startsWith(r));
    return (
      !isInNew &&
      (/^\/my-letters\/.+/.test(location?.pathname) ||
        /^\/my-grants\/.+/.test(location?.pathname))
    );
  }, [location?.pathname]);

  const handleChange = () => {
    if (isActive) return;

    if (isInEditorView && onChange) {
      onChange(organization);
      return;
    }

    updateOrganization(organization.id, { isActive: true });
  };

  return (
    <Box
      {...props}
      sx={{ cursor: isActive ? 'default' : 'pointer' }}
      onClick={handleChange}
    >
      <Flex alignItems="center">
        {isActive && (
          <Small my={0} mr={0.5}>
            <CheckOutlined color="primary" />
          </Small>
        )}
        <Body my={0} color={isActive ? 'primary.main' : 'grey.800'}>
          {organization.name}
        </Body>
      </Flex>
    </Box>
  );
};

const MenuItemWrapper = styled(({ active, ...props }) => <Box {...props} />)(
  ({ theme, active }) => ({
    padding: theme.spacing(2.5),
    backgroundColor: active ? theme.palette.primary.main : 'transparent',
    color: active ? theme.palette.common.white : theme.palette.text.primary,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(2),
  })
);

const useChangeOrgDialog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { activeOrganization: currentOrg, updateOrganization } =
    useOrganization();
  const [isChangeOrgOpen, setIsChangeOrgOpen] = useState(false);
  const [targetOrg, setTargetOrg] = useState();

  const navigateToDraft = async () => {
    try {
      let url;
      if (/^\/my-letters\/.+/.test(location?.pathname)) {
        const id = location?.pathname?.match(/\/my-letters\/(\w+)/)?.[1];
        const userGrant = await getUserGrantsById({
          queryKey: [undefined, id],
        });
        url = `/my-letters/new?grantId=${userGrant?.grantId}`;
      }
      if (/^\/my-grants\/.+/.test(location?.pathname)) {
        const id = location?.pathname?.match(/\/my-grants\/(\w+)/)?.[1];
        const userGrant = await getUserGrantsById({
          queryKey: [undefined, id],
        });
        url = `/my-grants/new-template?grantId=${userGrant?.grantId}`;
      }
      if (!url) return;

      updateOrganization(targetOrg.id, { isActive: true });
      navigate(url, { replace: true });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setIsChangeOrgOpen(false);
    }
  };

  const onOrgChange = (org) => {
    setTargetOrg(org);
    setIsChangeOrgOpen(true);
  };

  return {
    isChangeOrgOpen,
    targetOrg,
    currentOrg,
    onOrgChange,
    navigateToDraft,
    closeChangeOrgDialog: () => setIsChangeOrgOpen(false),
  };
};

const MenuItem = ({ route, onClick }) => {
  const { adminOpen, toggleAdmin } = useLayout();
  const navigate = useNavigate();
  const location = useLocation();
  const isNested = useMemo(() => route?.children?.length > 0, [route]);
  const active = useMemo(() => {
    if (isNested) return false;
    return location?.pathname === route.href;
  }, [isNested, location, route]);

  const handleClick = (isParent = false) => {
    if (isParent) {
      toggleAdmin();
      return;
    }
    if (onClick) onClick();

    if (route.isOutsideLink) {
      window.open(route.href, '_blank').focus();
    } else {
      navigate(route.href);
    }
  };

  return (
    <>
      {isNested ? (
        <>
          <MenuItemWrapper onClick={() => handleClick(true)}>
            <Body my={0}>{route.label}</Body>
            {adminOpen ? <ExpandLess /> : <ExpandMore />}
          </MenuItemWrapper>
          <Collapse in={adminOpen} timeout="auto" unmountOnExit>
            <Box pl={3}>
              {route.children.map((r, i) => (
                <MenuItem
                  key={i}
                  route={r}
                  listItemProps={{
                    sx: { pl: 4 },
                  }}
                  onClick={onClick}
                />
              ))}
            </Box>
          </Collapse>
        </>
      ) : (
        <MenuItemWrapper active={active} onClick={() => handleClick()}>
          <Box width={22} height={22} mr={2.5}>
            {route?.icon}
          </Box>
          <Body my={0} bold>
            {route.label}
          </Body>
        </MenuItemWrapper>
      )}
    </>
  );
};

const Drawer = ({ open, onToggleSidebar }) => {
  const theme = useTheme();
  const { user, fullName, signOut } = useAuth();
  const { organizations } = useOrganization();
  const {
    isChangeOrgOpen,
    onOrgChange,
    closeChangeOrgDialog,
    currentOrg,
    targetOrg,
    navigateToDraft,
  } = useChangeOrgDialog();

  const isAdmin = useMemo(() => user?.roles?.includes('curator'), [user]);
  const links = useMemo(() => {
    if (isAdmin) return NAV_LINKS;
    return NAV_LINKS.filter((link) => !link?.isAdmin);
  }, [isAdmin]);

  return (
    <MuiDrawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
        },
        borderRadius: 0,
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <Box px={4.25}>
        <Flex px={2.5} mt={3.75} mb={7.5} justifyContent="space-between">
          <Logo />
          <DrawerHeader>
            <IconButton onClick={onToggleSidebar}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
        </Flex>
        <Box px={2.5}>
          <Small my={0} color="grey.600">
            Grant Tools
          </Small>
        </Box>
        <Box my={4}>
          {links.map((route) => (
            <MenuItem key={route.label} route={route} />
          ))}
        </Box>
        <Divider />
        {organizations?.length > 0 && (
          <Box my={4.75} px={2.5}>
            <Small my={4} color="grey.600">
              Organization Name
            </Small>
            {organizations?.map((org, i) => (
              <OrganizationInfo
                key={i}
                organization={org}
                onChange={onOrgChange}
                mb={4}
                mt={i === 0 ? 0 : 4}
              />
            ))}
            <ChangeOrgDialog
              open={isChangeOrgOpen}
              onClose={closeChangeOrgDialog}
              currentOrgName={currentOrg?.name}
              targetOrgName={targetOrg?.name}
              onCreate={navigateToDraft}
            />
          </Box>
        )}
        <Divider />
        <Box my={4}>
          <Flex alignItems="center" mb={3}>
            <Box mr={2.75}>
              <Avatar variant="rounded" alt={fullName} />
            </Box>
            <Box>
              <Body my={0} color="grey.800">
                {fullName}
              </Body>
              <Link href="/account" underline="none">
                <Small my={0} color="grey.600">
                  Manage Profile
                </Small>
              </Link>
            </Box>
          </Flex>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'background.button.dark' }}
            onClick={signOut}
            fullWidth
          >
            Logout
          </Button>
        </Box>
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
