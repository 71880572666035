export const GRANT_STATUSES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
};

export const GRANT_CATEGORIES = [
  { value: 'federal_grant', label: 'Federal Grant' },
  {
    value: 'foundation_corporation_grant',
    label: 'Foundation & Corporation Grant',
  },
  { value: 'local_grant', label: 'Local Grant' },
  { value: 'state_grant', label: 'State Grant' },
];

export const GRANT_SUBCATEGORIES = [
  { value: 'aging_seniors', label: 'Aging/Seniors' },
  { value: 'arts_and_culture', label: 'Arts and Culture' },
  { value: 'awards', label: 'Awards' },
  { value: 'bipoc', label: 'BIPOC' },
  { value: 'business', label: 'Business' },
  { value: 'capital_funding', label: 'Capital Funding' },
  { value: 'children', label: 'Children' },
  { value: 'climate_change', label: 'Climate Change' },
  { value: 'college_scholarship', label: 'College Scholarship' },
  { value: 'community_services', label: 'Community Services' },
  {
    value: 'community_economic_development',
    label: 'Community/Economic Development',
  },
  { value: 'conflict_resolution', label: 'Conflict Resolution' },
  { value: 'coronavirus_covid_19', label: 'Coronavirus COVID-19' },
  { value: 'disabilities', label: 'Disabilities' },
  { value: 'disaster_relief', label: 'Disaster Relief' },
  { value: 'domestic_violence', label: 'Domestic Violence' },
  { value: 'education', label: 'Education' },
  { value: 'elementary_education', label: 'Elementary Education' },
  { value: 'energy', label: 'Energy' },
  { value: 'environment', label: 'Environment' },
  { value: 'faith_based', label: 'Faith-Based' },
  { value: 'farming_agriculture', label: 'Farming/Agriculture' },
  { value: 'financial_assistance', label: 'Financial Assistance' },
  { value: 'health_and_medical', label: 'Health and Medical' },
  { value: 'higher_education', label: 'Higher Education' },
  { value: 'hiv_aids', label: 'HIV/AIDS' },
  {
    value: 'homeland_national_security',
    label: 'Homeland & National Security',
  },
  { value: 'homeless', label: 'Homeless' },
  { value: 'housing', label: 'Housing' },
  { value: 'individual', label: 'Individual' },
  { value: 'international', label: 'International' },
  { value: 'justice_juvenile_justice', label: 'Justice & Juvenile Justice' },
  { value: 'literacy_libraries', label: 'Literacy & Libraries' },
  { value: 'mental_health', label: 'Mental Health' },
  { value: 'municipalities', label: 'Municipalities' },
  {
    value: 'non_profit_support_services',
    label: 'Non-Profit Support Services',
  },
  { value: 'nutrition', label: 'Nutrition' },
  { value: 'other', label: 'Other' },
  { value: 'pets_animal_wildlife', label: 'Pets/Animal/Wildlife' },
  { value: 'preschool', label: 'Preschool' },
  { value: 'preservation', label: 'Preservation' },
  { value: 'quality_of_life', label: 'Quality of Life' },
  { value: 'refugee_immigrant', label: 'Refugee/Immigrant' },
  { value: 'research_evaluation', label: 'Research & Evaluation' },
  { value: 'science', label: 'Science' },
  { value: 'secondary_education', label: 'Secondary Education' },
  { value: 'small_business', label: 'Small Business' },
  { value: 'social_justice', label: 'Social Justice' },
  { value: 'special_education', label: 'Special Education' },
  { value: 'sports_recreation', label: 'Sports & Recreation' },
  { value: 'students', label: 'Students' },
  { value: 'substance_abuse', label: 'Substance Abuse' },
  { value: 'teachers', label: 'Teachers' },
  { value: 'technology', label: 'Technology' },
  { value: 'transportation', label: 'Transportation' },
  { value: 'travel_tourism', label: 'Travel & Tourism' },
  { value: 'veterans', label: 'Veterans' },
  { value: 'workforce', label: 'Workforce' },
  { value: 'youth_out_of_school_youth', label: 'Youth/Out-of-School Youth' },
];

export const GRANTS_FIELD_TYPES = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  DATE: 'date',
  FILE: 'file',
  TEXT_LIST: 'textlist',
};

export const GRANTS_TYPES = {
  GRANT: 'grant',
  LETTER: 'letter',
  LINK: 'link',
};

export const COMMON_FIELDS = {
  firstName: '{{firstName}}',
  lastName: '{{lastName}}',
  email: '{{email}}',
  phoneNumber: '{{phoneNumber}}',
  company: '{{company}}',
  missionStatement: '{{missionStatement}}',
  programDesign: '{{programDesign}}',
  employerInternalNumber: '{{employerInternalNumber}}',
  expectedResults: '{{expectedResults}}',
  statementOfNeed: '{{statementOfNeed}}',
  listOfBoardMembers: '{{listOfBoardMembers}}',
  orgAddress: '{{orgAddress}}',
  orgCity: '{{orgCity}}',
  orgState: '{{orgState}}',
  orgZip: '{{orgZip}}',
  orgCountry: '{{orgCountry}}',
};

export const ORG_COMMON_FIELDS = [
  'company',
  'missionStatement',
  'programDesign',
  'employerInternalNumber',
  'expectedResults',
  'statementOfNeed',
  'listOfBoardMembers',
  'orgAddress',
  'orgCity',
  'orgState',
  'orgZip',
  'orgCountry',
];
