import { useIsTablet } from 'hooks/useIsTablet';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const LayoutContext = createContext();

export const LayoutContextProvider = ({ children }) => {
  const isTablet = useIsTablet();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [isFaqOpen, setIsFaqOpen] = useState(true);
  const [adminOpen, setAdminOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  const toggleFaq = () => {
    setIsFaqOpen((prev) => !prev);
  };

  const toggleAdmin = () => {
    setAdminOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!isTablet) return;
    setDrawerOpen(false);
  }, [location, isTablet]);

  const defaultContext = {
    drawerOpen,
    isFaqOpen,
    adminOpen,
    toggleDrawer,
    toggleFaq,
    toggleAdmin,
  };

  return (
    <LayoutContext.Provider value={defaultContext}>
      {children}
    </LayoutContext.Provider>
  );
};

export function useLayout() {
  return useContext(LayoutContext);
}
