import { Typography, Box, Button } from '@mui/material';
import Modal from 'components/Modal';

export default function ChangeOrgDialog({
  open,
  onClose,
  currentOrgName,
  targetOrgName,
  maxWidth = 574,
  onCreate,
}) {
  const handleClose = (_, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    if (onClose) onClose();
    return true;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      boxProps={{ py: 0, pb: 3 }}
    >
      <Box mb={3}>
        <Typography variant="h3" component="div" sx={{ mb: 3 }}>
          Organizations do not share grant drafts.
        </Typography>
        <Typography variant="h4">
          Would you like to continue switching to{' '}
          <Typography variant="h4" component="span" color="primary.main">
            {targetOrgName}
          </Typography>
          ? You won't see your current draft since it's in{' '}
          <Typography variant="h4" component="span" color="primary.main">
            {currentOrgName}
          </Typography>
          .
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Box component={Button} variant="contained" onClick={onCreate} mr={2}>
          Create new draft
        </Box>
        <Button variant="text" onClick={handleClose}>
          Stay here
        </Button>
      </Box>
    </Modal>
  );
}
