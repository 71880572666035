/* eslint-disable no-param-reassign */
import {
  getGrantByIdQuery,
  previewGrant,
  updateGrant,
} from 'api/services/grants';
import Error from 'components/Error';
import PageHeader from 'components/PageHeader';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import Spinner from 'components/Spinner';
import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { COMMON_FIELDS } from 'utils/constants';
import GrantForm from './Form';
import { prepRequest } from './helper';

const EditGrantPage = () => {
  const { id } = useParams();
  const { showSnackbar } = useSnackbar();
  const { data: grant, isLoading, error } = useQuery(getGrantByIdQuery(id));

  const { mutate: saveGrant, isLoading: isSaving } = useMutation(
    (data) => updateGrant(id, data),
    {
      onSuccess: (data) => {
        if (!data) return;
        showSnackbar({
          variant: SNACKBAR_VARIANTS.SUCCESS,
          message: 'Successfully saved your grant',
        });
      },
      onError: (err) => {
        showSnackbar({
          variant: SNACKBAR_VARIANTS.ERROR,
          message: err?.message || 'Failed to save grant',
        });
      },
    }
  );

  const { mutate: preview, isLoading: isDownloadingPreview } = useMutation(
    () => previewGrant(id),
    {
      onSuccess: (data) => {
        if (!data) return;
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${id}_preview.pdf`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      },
      onError: (err) => {
        showSnackbar({
          variant: SNACKBAR_VARIANTS.ERROR,
          message: err?.message || 'Failed to preview file',
        });
      },
    }
  );

  const handleSubmit = async (formData) => {
    // @TODO: disable field checks
    // const isFieldsValid = checkIfFieldsOnPages(formData);
    // if (!isFieldsValid) {
    //   showSnackbar({
    //     variant: SNACKBAR_VARIANTS.ERROR,
    //     message: 'Please fill all required fields',
    //   });
    //   return;
    // }
    const payload = await prepRequest(formData);
    saveGrant(payload);
  };

  const modifiedGrant = useMemo(() => {
    if (!grant) return undefined;
    if (grant.fields && grant.fields.length > 0) {
      grant.fields.forEach((s) => {
        if (s.name === 'listOfBoardMembers') {
          s.type = 'textlist';
        }
      });
      return grant;
    }
    const fields = [...grant.fields];
    const commonFields = Object.keys(COMMON_FIELDS).map((key) => ({
      name: key,
      type: key === 'listOfBoardMembers' ? 'textlist' : 'text',
    }));
    fields.push(
      ...commonFields.filter((s) => !fields.some((f) => f.name === s.name))
    );
    grant.fields = fields;
    return grant;
  }, [grant]);

  return (
    <>
      {isLoading && <Spinner />}
      {error && <Error description={error?.message} />}
      {!isLoading && grant && (
        <>
          <PageHeader title={grant.title} hideAction />
          <GrantForm
            grant={modifiedGrant}
            onSubmit={handleSubmit}
            isSaving={isSaving}
            onPreview={preview}
            isDownloadingPreview={isDownloadingPreview}
          />
        </>
      )}
    </>
  );
};

export default EditGrantPage;
