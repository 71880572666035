import { styled, Box, darken, Button } from '@mui/material';
import Flex from 'components/Flex';
import { Body, Title2, Title3 } from 'components/Text';
import Bg4 from 'images/bg-4.webp';
import { useNavigate } from 'react-router-dom';

const BannerOverlay = styled(Box)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, rgba(0, 0, 0, 0) 40%), url(${Bg4})`,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 185,
  borderRadius: theme.spacing(4),
}));

const WriteLetterButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  '&:hover, &:focus, &:active': {
    backgroundColor: darken(theme.palette.common.white, 0.05),
  },
}));

const WriteGrantButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.black,
  '&:hover, &:focus, &:active': {
    backgroundColor: darken(theme.palette.secondary.main, 0.05),
  },
}));

const DashboardBanner = (props) => {
  const navigate = useNavigate();

  const handleWriteLetter = () => {
    navigate('/my-letters');
  };

  const handleWriteGrant = () => {
    navigate('/my-grants');
  };

  return (
    <Box
      width="100%"
      height={{ xs: 'auto' }}
      position="relative"
      bgcolor="primary.main"
      borderRadius={5}
      boxShadow={'rgb(0 0 0 / 24%) 0px 6px 16px'}
      {...props}
    >
      <BannerOverlay />
      <Flex
        position="relative"
        px={{ xs: 4, md: 7.25 }}
        py={{ xs: 4, md: 7.25 }}
        height="100%"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box maxWidth={420} mb={{ xs: 5, md: 0 }}>
          <Title3 color="common.white" my={0}>
            Grant Suite
          </Title3>
          <Title2
            mt={0}
            mb={3}
            color="common.white"
            fontFamily="Playfair Display"
          >
            Dashboard
          </Title2>
          <Body mt={0} mb={2} color="common.white">
            You will get instant access to hundreds of letters and grant
            templates that have already been created for you to modify and send
            out thousands of Grant Opportunities for your company or
            organization.
          </Body>
          <Body mt={0} mb={2} color="common.white">
            You will not need a Grant writer because Grant Suite will do it for
            you!
          </Body>
          <Body mt={0} mb={2} color="common.white">
            Simply download, and print your documents, and keep your letters and
            applications in a secure location.
          </Body>
          <Body mt={0} mb={2} color="common.white">
            Using our software, you will definitely save time and money.
          </Body>
        </Box>
        <Box>
          <Body mt={0} mb={3} color="common.white" fontWeight={700}>
            Start Now!
          </Body>
          <Flex alignItems="center" flexDirection={{ xs: 'column', md: 'row' }}>
            <Box mr={{ xs: 0, md: 2.625 }} mb={{ xs: 2, md: 0 }}>
              <WriteLetterButton
                variant="contained"
                onClick={handleWriteLetter}
              >
                Write a letter
              </WriteLetterButton>
            </Box>
            <WriteGrantButton variant="contained" onClick={handleWriteGrant}>
              Write a grant
            </WriteGrantButton>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default DashboardBanner;
