import * as qs from 'qs';
import callApi from 'api/callApi';

const KEYS = {
  all: (filter) => ['userPlans', filter],
  byId: (id) => ['userPlanById', id],
};

export const getUserPlans = (filters) => {
  const query = qs.stringify(filters);
  return callApi(`/user-plans?${query}`, {
    method: 'GET',
    needsAuthToken: true,
  });
};

export const getUserPlansQuery = (filter) => ({
  cacheTime: 0,
  queryKey: KEYS.all(filter),
  queryFn: ({ queryKey }) => getUserPlans(queryKey[1]),
  enabled: !!filter,
});
