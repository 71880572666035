/* eslint-disable no-param-reassign */
import { Box } from '@mui/material';
import DatePicker from 'components/DatePicker';
import { Body } from 'components/Text';
import UploadFile from 'components/UploadFile';
import { Controller, useFormContext } from 'react-hook-form';
import humanize from 'underscore.string/humanize';
import { GRANTS_FIELD_TYPES } from 'utils/constants';
import FieldText from './FieldText';
import TextListField from './TextListField';

const GrantForm = ({ fields, attachments, outdatedFields }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Box pt={6.5}>
      <Body>Fill-in unique fields to populate the letter</Body>
      {fields.map((fieldItem, index) => (
        <Box key={fieldItem.id} sx={{ mb: 4 }}>
          <Controller
            name={`fields.${index}.value`}
            control={control}
            rules={{
              required: fieldItem.type !== GRANTS_FIELD_TYPES.TEXT_LIST && {
                value: true,
                message: `${humanize(fieldItem.name)} is required`,
              },
            }}
            render={({ field }) => (
              <>
                {fieldItem.type === GRANTS_FIELD_TYPES.TEXT && (
                  <FieldText
                    value={field.value}
                    onChange={field.onChange}
                    error={!!errors?.fields?.[index]?.value}
                    helperText={errors?.fields?.[index]?.value?.message}
                    label={humanize(fieldItem.name)}
                    isOutdated={outdatedFields?.includes(fieldItem.name)}
                  />
                )}
                {fieldItem.type === GRANTS_FIELD_TYPES.NUMBER && (
                  <FieldText
                    value={field.value}
                    onChange={field.onChange}
                    type="number"
                    error={!!errors?.fields?.[index]?.value}
                    helperText={errors?.fields?.[index]?.value?.message}
                    label={humanize(fieldItem.name)}
                  />
                )}
                {fieldItem.type === GRANTS_FIELD_TYPES.TEXTAREA && (
                  <FieldText
                    value={field.value}
                    onChange={field.onChange}
                    rows={4}
                    multiline
                    error={!!errors?.fields?.[index]?.value}
                    helperText={errors?.fields?.[index]?.value?.message}
                    label={humanize(fieldItem.name)}
                    isOutdated={outdatedFields?.includes(fieldItem.name)}
                  />
                )}
                {fieldItem.type === GRANTS_FIELD_TYPES.DATE && (
                  <DatePicker
                    value={field.value}
                    onChange={field.onChange}
                    textFieldProps={{
                      error: !!errors?.fields?.[index]?.value,
                      helperText: errors?.fields?.[index]?.value?.message,
                      label: humanize(fieldItem.name),
                      fullWidth: true,
                      sx: outdatedFields?.includes(fieldItem.name)
                        ? {
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'primary.main',
                              borderWidth: 1,
                              borderStyle: 'solid',
                            },
                          }
                        : {},
                    }}
                  />
                )}
                {fieldItem.type === GRANTS_FIELD_TYPES.TEXT_LIST && (
                  <TextListField
                    value={field.value}
                    onChange={field.onChange}
                    label={humanize(fieldItem.name)}
                    isOutdated={outdatedFields?.includes(fieldItem.name)}
                  />
                )}
                {fieldItem.type === GRANTS_FIELD_TYPES.FILE && (
                  <UploadFile
                    fieldId={`file-${fieldItem.id}`}
                    useImagePreview={false}
                    accept="*"
                    url={field.value?.name || field.value}
                    onChange={field.onChange}
                    textFieldProps={{
                      error: !!errors?.fields?.[index]?.value,
                      helperText: errors?.fields?.[index]?.value?.message,
                      label: humanize(fieldItem.name),
                    }}
                  />
                )}
              </>
            )}
          />
        </Box>
      ))}

      {!!attachments?.length && (
        <>
          <Body mt={5}>Attach files (optional)</Body>
          {attachments.map((attachment, index) => (
            <Box key={attachment.id} sx={{ mb: 4 }}>
              <Controller
                name={`attachments.${index}.value`}
                control={control}
                render={({ field }) => (
                  <UploadFile
                    fieldId={`attachment-${attachment.id}`}
                    useImagePreview={false}
                    accept="*"
                    url={field.value?.name || attachment.value}
                    onChange={field.onChange}
                    textFieldProps={{
                      error: !!errors?.attachments?.[index]?.value,
                      helperText: errors?.attachments?.[index]?.value?.message,
                      label: attachment.label,
                    }}
                  />
                )}
              />
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default GrantForm;
