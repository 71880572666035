import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
} from '@mui/material';
import Flex from 'components/Flex';
import Link from 'components/Link';
import { Body, Title2 } from 'components/Text';
import { useAuth } from 'contexts/Auth';
import { useForm } from 'react-hook-form';
import { VALIDATORS } from 'utils/constants/validators';

const SignIn = () => {
  const { signIn } = useAuth();
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = ({ email, password }) => {
    signIn(email, password);
  };

  return (
    <Container>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: { xs: '100%', md: '550px' }, margin: '0 auto' }}
      >
        <Card>
          <Box component={CardContent} px={{ xs: 3.75, md: 6.875 }} py={7.375}>
            <Title2 fontFamily="Playfair Display" mt={0} mb={4.5}>
              Welcome back!
            </Title2>
            <Box mb={2.5}>
              <Box
                sx={{
                  '& .MuiTextField-root': { m: 1 },
                }}
                noValidate
                autoComplete="off"
                display="flex"
                flexDirection="column"
              >
                <TextField
                  label="Email/Username"
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                  placeholder="Enter your username or email address"
                  name="email"
                  {...register('email', VALIDATORS.email)}
                />
                <TextField
                  label="Password"
                  error={!!errors?.password}
                  helperText={errors?.password?.message}
                  placeholder="Enter password"
                  name="password"
                  type="password"
                  {...register('password', VALIDATORS.password)}
                />
              </Box>
            </Box>
            <Flex
              sx={{
                mb: 4,
                alignItems: { xs: 'start', md: 'center' },
                flexWrap: 'wrap',
              }}
            >
              <Body my={0} bold color="grey.600" mr={0.5}>
                Having trouble logging in?
              </Body>
              <Link href="/auth/forgot-password" underline="none">
                <Body my={0} color="primary.main" bold>
                  Forgot Password
                </Body>
              </Link>
            </Flex>
            <Box mb={4}>
              <Button
                sx={{
                  backgroundColor: 'background.button.dark',
                  width: { xs: '100%', md: 'auto' },
                }}
                type="submit"
                variant="contained"
              >
                Login
              </Button>
            </Box>
            <Flex alignItems="center" mb={4}>
              <Body my={0} bold color="grey.600" mr={0.5}>
                Not yet a member?
              </Body>
              <Link href="/auth/sign-up" underline="none">
                <Body my={0} color="primary.main" bold>
                  Sign up
                </Body>
              </Link>
            </Flex>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default SignIn;
