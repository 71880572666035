import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  CircularProgress,
  Card,
  Button,
  TextField,
  CardContent,
  Container,
} from '@mui/material';
import { useSnackbar, SNACKBAR_VARIANTS } from 'components/Snackbar';
import { Title2, Body, Small } from 'components/Text';
import Link from 'components/Link';
import Flex from 'components/Flex';
import { useAuth } from 'contexts/Auth';
import { getPasswordValidation } from 'utils/helpers/validation.helpers';
import { MAIN_6FG_SITE_URL, VALIDATORS } from 'utils/constants';
import PasswordField from 'components/PasswordField';

const SignUp = () => {
  const { showSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
  });
  const [passwordValidation, setPasswordValidation] = useState([]);
  const { register, signupErrors, isRegisterLoading: isLoading } = useAuth();

  useEffect(() => {
    if (!signupErrors) return;
    if (signupErrors.message) {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.ERROR,
        message: signupErrors.message,
      });
      return;
    }
    showSnackbar({
      variant: SNACKBAR_VARIANTS.ERROR,
      message: signupErrors,
    });
  }, [signupErrors]);

  const onSubmit = async (payload) => {
    const request = { ...payload, roles: ['applicant'] };
    register(request);
  };

  return (
    <Container>
      <Box sx={{ width: { xs: '100%', md: '550px' }, margin: '0 auto' }}>
        <Card>
          <Box component={CardContent} px={{ xs: 3.75, md: 6.875 }} py={7.375}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Title2 fontFamily="Playfair Display" mt={0} mb={3}>
                Sign Up
              </Title2>
              <Body color="ui56" mt={0} mb={5}>
                Fill the form below to sign up. You will receive a confirmation
                email after you submit.
              </Body>
              <Box mb={2}>
                <Controller
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'First name is required',
                    },
                  }}
                  name="firstName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="First name"
                      placeholder="John"
                      error={!!errors?.firstName}
                      helperText={errors?.firstName?.message}
                      labelProps={{
                        my: 0,
                        color: 'ui',
                      }}
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  control={control}
                  rules={{
                    required: { value: true, message: 'Last name is required' },
                  }}
                  name="lastName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      placeholder="Doe"
                      label="Last name"
                      error={!!errors?.lastName}
                      helperText={errors?.lastName?.message}
                      labelProps={{
                        my: 0,
                        color: 'ui',
                      }}
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  control={control}
                  rules={VALIDATORS.email}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      placeholder="youremail@domain.com"
                      label="Email"
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                      labelProps={{
                        my: 0,
                        color: 'ui',
                      }}
                    />
                  )}
                />
              </Box>
              <Box mb={4}>
                <Box mb={1.5}>
                  <Controller
                    control={control}
                    rules={{
                      validate: (value) => {
                        const list = getPasswordValidation(value);
                        setPasswordValidation(list);
                        return list.length < 1;
                      },
                    }}
                    name="password"
                    render={({ field }) => (
                      <PasswordField
                        {...field}
                        fullWidth
                        placeholder="* * * * * *"
                        label="Password"
                        autoComplete="password"
                        type="password"
                        error={!!errors?.password}
                        labelProps={{
                          my: 0,
                          color: 'ui',
                        }}
                      />
                    )}
                  />
                </Box>
                <Small color="grey.500" bold my={0}>
                  Must be at least 8 characters
                </Small>
                <Small color="grey.500" bold my={0}>
                  Note: Use a strong password. Do not share your account to
                  anyone else.
                </Small>
                {passwordValidation?.length > 0 && (
                  <Box component="ul" color="red" pl={2}>
                    {passwordValidation.map((message, i) => (
                      <Box component="li" key={i}>
                        <Small>{message}</Small>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
              <Box mb={5}>
                <Body fontSize={14} my={0} color="grey.500" bold>
                  By tapping continue, you agree to Grant Suite{' '}
                  <Link
                    underline="always"
                    color="primary.main"
                    href={MAIN_6FG_SITE_URL}
                    component="a"
                    target="_blank"
                  >
                    Terms & Conditions
                  </Link>{' '}
                  and{' '}
                  <Link
                    underline="always"
                    color="primary.main"
                    href={MAIN_6FG_SITE_URL}
                    component="a"
                    target="_blank"
                  >
                    Privacy Notice
                  </Link>
                </Body>
              </Box>
              <Box mb={5}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                  size="large"
                  sx={{
                    backgroundColor: 'background.button.dark',
                    width: { xs: '100%', md: 'auto' },
                  }}
                >
                  Continue
                  {isLoading && (
                    <Box
                      component={CircularProgress}
                      ml={2}
                      size={16}
                      color="inherit"
                    />
                  )}
                </Button>
              </Box>
              <Flex alignItems="center" mb={4}>
                <Body my={0} bold color="grey.600" mr={0.5}>
                  I'm already a member
                </Body>
                <Link href="/auth/sign-in" underline="none">
                  <Body my={0} color="primary.main" bold>
                    Log In
                  </Body>
                </Link>
              </Flex>
            </Box>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};
export default SignUp;
