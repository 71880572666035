import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Flex from 'components/Flex';
import humanize from 'underscore.string/humanize';
import { GRANTS_FIELD_TYPES } from 'utils/constants';

const FieldItem = ({ item, onChange, onRemove, index, ...props }) => {
  const handleNameChange = (e) => {
    if (!onChange) return;
    const { value } = e.target;
    onChange({ ...item, name: value });
  };

  const handleTypeChange = (e) => {
    if (!onChange) return;
    const { value } = e.target;
    onChange({ ...item, type: value });
  };

  return (
    <Flex alignItems="center" {...props}>
      <Box component={Grid} container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            value={item?.name || ''}
            onChange={handleNameChange}
            placeholder="e.g. firstName"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            value={item?.type || GRANTS_FIELD_TYPES.TEXT}
            onChange={handleTypeChange}
            fullWidth
          >
            {Object.values(GRANTS_FIELD_TYPES).map((value) => (
              <MenuItem key={value} value={value}>
                {humanize(value)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Box>
      <IconButton onClick={() => onRemove(index)}>
        <DeleteOutlineIcon color="error" />
      </IconButton>
    </Flex>
  );
};

export default FieldItem;
