import { Box, Grid } from '@mui/material';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { Title2 } from 'components/Text';
import { useAuth } from 'contexts/Auth';
import { useUserFields } from 'contexts/Fields';
import { useOrganization } from 'contexts/Organization';
import usePrompt from 'hooks/useBlocker';
import { useDownloadGrant } from 'hooks/useDownloadGrant';
import { usePrint } from 'hooks/usePrint';
import { useSaveMyGrant } from 'hooks/useSaveMyGrant';
import mixpanel from 'mixpanel-browser';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BRAND_COLORS, getScrollbarStyle } from 'theme';
import underscored from 'underscore.string/underscored';
import { GRANTS_TYPES } from 'utils/constants';
import { downloadFile, replaceVariables } from 'utils/helpers/misc';
import ConfirmLeaveDialog from './ConfirmLeaveDialog';
import DocumentViewer from './DocumentViewer';
import DownloadDialog from './DownloadDialog';
import GrantForm from './GrantForm';
import GrantSelector from './GrantSelector';
import OutdatedOrgInfoWarning from './OutdatedOrgInfoWarning';
import UnsavedContentDialog from './UnsavedContentDialog';
import {
  ORG_FIELDS_MAPPING,
  getDefaultValue,
  isArrayEqual,
  mergeGrantAndOrgFields,
} from './helpers';

/*
  required
    type: "grant" | "letter"
  
  optional
    set either userGrant (for existing) or grant (new with set pre selected)
  */
const EditorPage = ({
  type,
  userGrant,
  grant,
  onUpdate,
  isNew,
  userFields = [],
  refetchGrant,
}) => {
  const { refetch: refetchUserFields } = useUserFields();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { activeOrganization, organizations } = useOrganization();
  const { showSnackbar } = useSnackbar();
  const [isLeaveOpen, setIsLeaveOpen] = useState(false);
  const [isLeaveConfirmed, setIsLeaveConfirmed] = useState(false);
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);
  const [selectedGrant, setSelectedGrant] = useState({
    ...userGrant?.grant,
    ...grant,
  });
  const [unsavedContentType, setUnsavedContentType] = useState();
  const {
    file,
    setFile,
    wordFile,
    setWordFile,
    isDownloading,
    downloadError,
    preview,
    previewDocx,
    downloadPdf,
    downloadDocx,
  } = useDownloadGrant();
  const execPrint = usePrint(selectedGrant);
  const defaultValues = useMemo(
    () =>
      mergeGrantAndOrgFields({
        isNew,
        user,
        userFields,
        organization: activeOrganization,
        grant: selectedGrant,
      }),
    [isNew, user, selectedGrant, userFields, activeOrganization]
  );

  const methods = useForm({ defaultValues });

  const {
    reset,
    handleSubmit,
    control,
    getValues,
    formState: { isDirty },
    watch,
  } = methods;
  const { fields } = useFieldArray({
    control,
    name: 'fields',
  });
  const { fields: attachments } = useFieldArray({
    control,
    name: 'attachments',
  });

  const watchedFields = watch('fields');

  const { createMyGrant, updateMyGrant, isSaving } = useSaveMyGrant({
    onError: () => {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.ERROR,
        message: `Failed saved your ${type}`,
      });
    },
    onCreateSuccess: (res) => {
      refetchUserFields();
      mixpanel.track('Create Grant', {
        grant: selectedGrant.title,
        grantId: res.grantId,
        userId: user.id,
        email: user.email,
      });
      showSnackbar({
        variant: SNACKBAR_VARIANTS.SUCCESS,
        message: `Successfully saved your ${type}`,
      });
      const url =
        type === GRANTS_TYPES.GRANT
          ? `/my-grants/${res.id}`
          : `/my-letters/${res.id}`;
      reset(getDefaultValue(res));
      downloadPdf(res.id);
      downloadDocx(res.id);
      setFile(undefined);
      setWordFile(undefined);
      navigate(url, { replace: true });
    },
    onUpdateSuccess: (res) => {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.SUCCESS,
        message: `Successfully saved your ${type}`,
      });
      reset(getDefaultValue(res));
      downloadPdf(res.id);
      downloadDocx(res.id);
      setFile(undefined);
      setWordFile(undefined);
      if (onUpdate) onUpdate(res);
    },
  });

  const pages = selectedGrant?.pages?.map((page) =>
    replaceVariables(page.content, watchedFields)
  );

  const grantOrg = useMemo(
    () =>
      organizations?.find((org) => org.id === selectedGrant?.organizationId) ??
      organizations?.find((org) => org.isActive),
    [organizations, selectedGrant]
  );

  const outdatedFields = useMemo(() => {
    if (!grantOrg || !selectedGrant) return [];
    const tmpOutdatedFields = [];
    for (const mapping of ORG_FIELDS_MAPPING) {
      const field = selectedGrant.fields?.find((f) => f.name === mapping.field);
      if (field) {
        let isEqual = false;
        if (field.name === 'listOfBoardMembers') {
          isEqual = isArrayEqual(
            field.value ?? [],
            grantOrg[mapping.org]?.map((b) => b.name) ?? []
          );
        } else if (field.type === 'textlist') {
          isEqual = isArrayEqual(
            field.value ?? [],
            grantOrg[mapping.org] ?? []
          );
        } else {
          isEqual = field.value === grantOrg[mapping.org];
        }
        if (!isEqual) {
          tmpOutdatedFields.push(mapping.field);
        }
      }
    }
    return tmpOutdatedFields;
  }, [selectedGrant, grantOrg]);

  const handleSelectedGrantChange = (value) => {
    setFile(null);
    setWordFile(null);
    setSelectedGrant(value);
  };

  const handlePrint = () => {
    if (isDirty) {
      setUnsavedContentType('print');
      return;
    }
    const currentFields = getValues('fields');
    execPrint(selectedGrant, currentFields);
  };

  const handleSave = (values) => {
    const { fields: updatedFields, attachments: updateAttachments } = values;
    const changes = {
      fields: updatedFields,
      attachments: updateAttachments,
      organizationId: userGrant?.organizationId || activeOrganization?.id,
    };
    if (userGrant) {
      updateMyGrant({
        id: userGrant.id,
        payload: changes,
      });
      return;
    }
    createMyGrant({
      grantId: selectedGrant.id,
      organizationId: activeOrganization?.id,
      ...changes,
    });
  };

  const handleDownload = () => {
    if (isDirty) {
      setUnsavedContentType('download');
      return;
    }
    setIsDownloadDialogOpen(true);
  };

  const handleDownloadPdf = () => {
    downloadFile(
      file,
      `${underscored(selectedGrant.title)}_${new Date().getTime()}.pdf`
    );
    setIsDownloadDialogOpen(false);
  };

  const handleDownloadWord = () => {
    downloadFile(
      wordFile,
      `${underscored(selectedGrant.title)}_${new Date().getTime()}.docx`
    );
    setIsDownloadDialogOpen(false);
  };

  const handleErrors = () => {
    showSnackbar({
      variant: SNACKBAR_VARIANTS.ERROR,
      message: `Failed saved your ${type}`,
    });
  };

  const handleCancel = () => navigate(-1);

  useEffect(() => {
    setSelectedGrant(undefined);
    setFile(null);
    setWordFile(null);
  }, [type]);

  useEffect(() => {
    reset(getDefaultValue(selectedGrant));
    if (selectedGrant?.id) {
      preview(selectedGrant.id);
      previewDocx(selectedGrant.id);
      return;
    }
    if (userGrant?.id) {
      downloadPdf(userGrant.id);
      downloadDocx(userGrant.id);
    }
  }, [selectedGrant, activeOrganization, userFields]);

  useEffect(() => {
    const updateDefaultValues = mergeGrantAndOrgFields({
      isNew,
      user,
      userFields,
      organization: activeOrganization,
      grant: selectedGrant,
    });
    reset(updateDefaultValues);
  }, [isNew, user, userFields, activeOrganization, selectedGrant]);

  useEffect(() => {
    if (userGrant) {
      setSelectedGrant(userGrant?.grant);
    }
  }, [userGrant]);

  useEffect(() => {
    if (grant) {
      setSelectedGrant(grant);
    }
  }, [grant]);

  usePrompt(() => setIsLeaveOpen(true), isLeaveConfirmed, isDirty);

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(handleSave, handleErrors)}
        sx={{
          height: '100%',
          width: '100%',
          overflow: { xs: 'auto', md: 'hidden' },
          backgroundColor: BRAND_COLORS.offWhite,
          ...getScrollbarStyle('#c3c3c3'),
        }}
      >
        <Grid sx={{ height: { xs: 'auto', md: '100%' } }} container>
          <Grid
            xs={12}
            md={4}
            sx={{
              height: '100%',
              overflow: { xs: 'hidden', md: 'auto' },
              backgroundColor: BRAND_COLORS.offWhite,
              ...getScrollbarStyle('#c3c3c3'),
            }}
            item
          >
            <Box
              sx={{
                pl: 4.625,
                pr: 3.75,
                py: 6,
              }}
            >
              {userGrant ? (
                <Title2
                  fontFamily="Playfair Display"
                  ml={2}
                  mb={5.5}
                  color={BRAND_COLORS.darkGray}
                >
                  {userGrant?.grant?.title}
                </Title2>
              ) : (
                <GrantSelector
                  type={type}
                  onChange={handleSelectedGrantChange}
                  defaultGrant={grant}
                />
              )}
              {selectedGrant && (
                <>
                  {!isNew && (
                    <OutdatedOrgInfoWarning
                      id={userGrant?.id}
                      grant={selectedGrant}
                      type={type}
                      refetchGrant={refetchGrant}
                      outdatedFields={outdatedFields}
                    />
                  )}
                  <GrantForm
                    fields={fields}
                    attachments={attachments}
                    outdatedFields={outdatedFields}
                  />
                </>
              )}
            </Box>
          </Grid>
          <Grid
            xs={12}
            md={8}
            item
            sx={{ overflow: 'auto', backgroundColor: '#fff' }}
          >
            <DocumentViewer
              pages={pages}
              type={type}
              file={file}
              isLoadingFile={isDownloading}
              loadingFileError={downloadError}
              onDownload={handleDownload}
              onPrint={handlePrint}
              onCancel={handleCancel}
              isSaving={isSaving}
              isFile={false}
              disableDownload={isNew}
            />
          </Grid>
        </Grid>
      </Box>
      {isLeaveOpen && (
        <ConfirmLeaveDialog
          open={isLeaveOpen}
          onClose={() => setIsLeaveOpen(false)}
          onSave={() => {
            handleSubmit(handleSave)();
            setIsLeaveOpen(false);
          }}
          onConfirm={() => {
            setIsLeaveConfirmed(true);
            setIsLeaveOpen(false);
          }}
        />
      )}
      {!!unsavedContentType && (
        <UnsavedContentDialog
          open={!!unsavedContentType}
          onClose={() => setUnsavedContentType(undefined)}
          type={unsavedContentType}
        />
      )}
      {isDownloadDialogOpen && (
        <DownloadDialog
          open={isDownloadDialogOpen}
          onClose={() => setIsDownloadDialogOpen(false)}
          onDownloadDoc={handleDownloadWord}
          onDownloadPdf={handleDownloadPdf}
        />
      )}
    </FormProvider>
  );
};

export default EditorPage;
