import axios from 'axios';
import { getAuthTokenFromCookie } from 'utils/helpers/auth.helpers';
import { APP_CONFIG } from 'utils/constants';

const API_BASE = APP_CONFIG.API_URL ?? '/api';
const DEFAULT_ERR_MESSAGE = 'Something went wrong. Please try again.';

class ApiError extends Error {
  constructor({ message, type, code, response }, ...params) {
    super(...params);

    if (Error?.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = 'ApiError';
    this.message = message;
    this.type = type;
    this.code = code;
    this.response = response;
  }
}

async function callApi(
  endpoint,
  {
    needsAuthToken,
    headers,
    useFullError = false,
    responseType,
    ...payload
  } = {}
) {
  const config = {
    url: endpoint,
    baseURL: API_BASE,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    responseType,
    ...payload,
  };
  if (needsAuthToken)
    config.headers.Authorization = `Bearer ${getAuthTokenFromCookie()}`;

  try {
    const response = await axios(config);
    if (responseType === 'blob') return response?.data;
    if (!response?.data) throw new Error(DEFAULT_ERR_MESSAGE);
    if (response.data?.data) return response.data.data;
    throw new Error(response.data.error?.message ?? DEFAULT_ERR_MESSAGE);
  } catch (err) {
    if (useFullError) {
      throw new ApiError(err.response.data);
    } else {
      throw new Error(err.response.data.message);
    }
  }
}

export const getValidationErrorMessage = (error) => {
  if (error.type !== 'validation_error') return error.message;
  if (error.response?.[0]?.errors?.length > 0) {
    const msg = error.response[0].errors[0];
    return msg;
  }
  return error.message;
};

export default callApi;
