import { useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import ReactGA from 'react-ga4';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { theme } from 'theme';
import { AuthContextProvider } from 'contexts/Auth';
import { LayoutContextProvider } from 'contexts/Layout';
import { SnackbarProvider } from 'components/Snackbar';
import AppRoutes from 'AppRoutes';
import queryClient from 'queryClient';
import mixpanel from 'mixpanel-browser';
import { hotjar } from 'react-hotjar';
import { OrganizationContextProvider } from 'contexts/Organization';
import { UserFieldsContextProvider } from 'contexts/Fields';
import { useLocation } from 'react-router-dom';

const App = () => {
  const location = useLocation();
  hotjar.initialize('3252877', 6);
  mixpanel.init('d15f100c6474d773154b1d99f73b89cd', { debug: true });
  ReactGA.initialize('G-5F55WNC5G6');

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <LayoutContextProvider>
            <AuthContextProvider>
              <OrganizationContextProvider>
                <UserFieldsContextProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <AppRoutes />
                  </LocalizationProvider>
                </UserFieldsContextProvider>
              </OrganizationContextProvider>
            </AuthContextProvider>
          </LayoutContextProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
