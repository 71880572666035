import { useManageOrganizations } from 'hooks/useManageOrganizations';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from './Auth';

const DISABLE_MULTIPLE_ORGS =
  process.env.REACT_APP_DISABLE_MULTIPLE_ORGS === 'true';

export const OrganizationContext = createContext();

export const OrganizationContextProvider = ({ children }) => {
  const { user } = useAuth();
  const [organizations, setOrganizations] = useState();

  const activeOrganization = useMemo(() => {
    if (!organizations || !organizations.length) return undefined;
    const active = organizations.find((org) => org.isActive);
    if (active) return active;
    const initial = organizations[0];
    return DISABLE_MULTIPLE_ORGS ? initial : undefined;
  }, [organizations]);

  const {
    isFetching,
    organizations: orgs,
    createOrganization,
    updateOrganization,
    deleteOrganization,
    refreshOrganizations,
  } = useManageOrganizations(user?.id);

  useEffect(() => {
    if (!orgs) return;
    setOrganizations(orgs);
  }, [orgs]);

  const defaultContext = {
    organizations,
    isFetching,
    activeOrganization,
    createOrganization,
    updateOrganization,
    deleteOrganization,
    refreshOrganizations,
  };

  return (
    <OrganizationContext.Provider value={defaultContext}>
      {children}
    </OrganizationContext.Provider>
  );
};

export function useOrganization() {
  return useContext(OrganizationContext);
}
