import { ArrowCircleLeftOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  IconButton,
  TextField,
} from '@mui/material';
import { changePassword, updateUser } from 'api';
import Flex from 'components/Flex';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { Title2 } from 'components/Text';
import { useAuth } from 'contexts/Auth';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { VALIDATORS } from 'utils/constants/validators';
import ReactGA from 'react-ga4';

const DEFAULT = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ChangePasswordPage = ({ isForceResetPassword = false }) => {
  const { user, refreshUser } = useAuth();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const {
    formState: { errors },
    register,
    handleSubmit,
    getValues,
    reset,
  } = useForm({
    defaultValues: DEFAULT,
  });

  const { mutate: updateUserPasswordSetting, isLoading: isSaving } =
    useMutation((data) => updateUser(user.id, data), {
      onSuccess: (data) => {
        if (!data) return;
        ReactGA.event({
          category: 'User Actions',
          action: 'change_password',
          label: `Changed password for user ${user.id}`,
        });
        refreshUser('/');
      },
      onFailure: (err) => {
        showSnackbar({
          message: err?.message || 'Failed to update your account',
          variant: SNACKBAR_VARIANTS.ERROR,
        });
      },
    });

  const { mutate, isLoading } = useMutation(
    (data) => changePassword(user.id, data),
    {
      onSuccess: (data) => {
        if (!data) return;
        reset(DEFAULT);
        showSnackbar({
          variant: SNACKBAR_VARIANTS.SUCCESS,
          message: 'Your password has been successfully updated',
        });
        if (isForceResetPassword)
          updateUserPasswordSetting({ forceResetPassword: false });
      },
      onError: (error) => {
        showSnackbar({
          variant: SNACKBAR_VARIANTS.ERROR,
          message: error?.message || 'Failed to update your password',
        });
      },
    }
  );

  const onSubmit = (data) => {
    const { oldPassword, newPassword } = data;
    mutate({ oldPassword, newPassword });
  };

  const handleBack = () => navigate(-1);

  return (
    <Container>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ maxWidth: 375, mx: 'auto' }}>
          <CardContent>
            <Flex alignItems="center" mb={3}>
              <IconButton variant="contained" onClick={handleBack}>
                <ArrowCircleLeftOutlined color="default" />
              </IconButton>
              <Title2 my={0} fontFamily="Playfair Display">
                Change my password
              </Title2>
            </Flex>
            <Box
              sx={{
                '& .MuiTextField-root': { m: 1 },
              }}
              noValidate
              autoComplete="off"
              display="flex"
              flexDirection="column"
            >
              <TextField
                label="Current Password"
                name="oldPassword"
                type="password"
                error={!!errors?.oldPassword}
                helperText={errors?.oldPassword?.message}
                {...register('oldPassword', {
                  required: {
                    value: true,
                    message: 'Old Password is required',
                  },
                })}
              />
              <TextField
                label="New Password"
                name="newPassword"
                type="password"
                error={!!errors?.newPassword}
                helperText={errors?.newPassword?.message}
                {...register('newPassword', VALIDATORS.password)}
              />
              <TextField
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                error={!!errors?.confirmPassword}
                helperText={errors?.confirmPassword?.message}
                {...register('confirmPassword', {
                  required: {
                    value: true,
                    message: 'Confirm Password is required',
                  },
                  validate: (value) =>
                    getValues('newPassword') === value ||
                    "Passwords don't match",
                })}
              />
            </Box>
          </CardContent>
          <CardActions>
            <Box display="flex" justifyContent="center" width="100%" m={2}>
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading || isSaving}
              >
                Change password
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};

export default ChangePasswordPage;
