import { useEffect } from 'react';
import * as moment from 'moment';
import { Cancel, CheckCircle } from '@mui/icons-material';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Link } from '@mui/material';
import { getUsers } from 'api';
import DataGrid from 'components/DataGrid';
import GridAction from 'components/GridAction';
import PageHeader from 'components/PageHeader';
import { useSnackbar, SNACKBAR_VARIANTS } from 'components/Snackbar';
import { Body } from 'components/Text';
import { useDataGridQuery } from 'hooks';
import { DEFAULT_DATAGRID_CONFIG } from 'utils/constants';
import Flex from 'components/Flex';

const FILTER_VALUE = [
  { name: 'name', operator: 'eq', type: 'string', value: '' },
  { name: 'email', operator: 'eq', type: 'string', value: '' },
  { name: 'role', operator: 'eq', type: 'string', value: '' },
  {
    name: 'emailVerification.isVerified',
    operator: 'eq',
    type: 'bool',
    value: undefined,
  },
  { name: 'isFullyPaid', operator: 'eq', type: 'bool', value: undefined },
  {
    name: 'createdDate',
    operator: 'eq',
    type: 'date',
    value: undefined,
  },
];

const formatFullName = (data) =>
  !data.firstName && !data.lastName
    ? 'N/A'
    : `${data.firstName} ${data.lastName}`;

const getRoleName = (data) => {
  const roles = { curator: 'Curator', applicant: 'Applicant' };
  const combilesRoles = data.roles
    .map((role) => roles[role])
    .filter((r) => !!r?.trim())
    .join('/');
  return combilesRoles;
};

const COLUMNS = [
  {
    name: 'name',
    header: 'Name',
    defaultFlex: 1,
    render: ({ data }) => {
      const name = formatFullName(data);
      return <Body my={0}>{name}</Body>;
    },
    getExportValue: formatFullName,
  },
  {
    name: 'email',
    header: 'Email',
    defaultFlex: 1,
    render: ({ data }) => (
      <Link href={`mailto:${data.email}`} underline="none">
        {data.email}
      </Link>
    ),
  },
  {
    name: 'role',
    header: 'Role',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'All',
      dataSource: [
        { id: 'curator', label: 'Curator' },
        { id: 'applicant', label: 'Applicant' },
      ],
    },
    defaultFlex: 1,
    render: ({ data }) => {
      const roleName = getRoleName(data);
      return <Body my={0}>{roleName}</Body>;
    },
    getExportValue: getRoleName,
  },
  {
    name: 'emailVerification.isVerified',
    header: 'Verified',
    filterEditor: BoolFilter,
    defaultFlex: 1,
    render: ({ data }) => (
      <Flex center>
        {data?.emailVerification?.isVerified ? (
          <CheckCircle color="success" />
        ) : (
          <Cancel color="error" />
        )}
      </Flex>
    ),
  },
  {
    name: 'isFullyPaid',
    header: 'Paid',
    defaultFlex: 1,
    filterEditor: BoolFilter,
    render: ({ data }) => (
      <Flex center>
        {data?.isFullyPaid ? (
          <CheckCircle color="success" />
        ) : (
          <Cancel color="error" />
        )}
      </Flex>
    ),
  },
  {
    name: 'createdDate',
    header: 'Created',
    defaultFlex: 1,
    filterEditor: DateFilter,
    filterEditorProps: () => ({
      dateFormat: 'yyyy-MM-DD',
      cancelButton: false,
      highlightWeekends: true,
      weekNumbers: false,
    }),
    render: ({ value }) => moment(value).format('MMM DD, YYYY hh:mm A'),
    getExportValue: ({ createdDate }) =>
      moment(createdDate).format('MMM DD, YYYY hh:mm A'),
  },
  {
    id: 'action',
    header: 'Action',
    defaultFlex: 1,
    textAlign: 'center',
    noExport: true,
    render: ({ data }) => (
      <GridAction editUrl={`/admin/manage-users/${data.id}`} hideDelete />
    ),
  },
];

const UsersPage = () => {
  const { showSnackbar } = useSnackbar();
  const { dataSource, isLoading, error, updateConfig } = useDataGridQuery(
    'users',
    getUsers,
    DEFAULT_DATAGRID_CONFIG
  );

  useEffect(() => {
    if (!error) return;
    showSnackbar({
      message: 'Unable to load users',
      variant: SNACKBAR_VARIANTS.ERROR,
    });
  }, [error]);

  return (
    <>
      <PageHeader title="Users" hideAction />
      <DataGrid
        columns={COLUMNS}
        defaultFilterValue={FILTER_VALUE}
        defaultConfig={DEFAULT_DATAGRID_CONFIG}
        dataSource={dataSource}
        loading={isLoading}
        onConfigChange={updateConfig}
        canExport={true}
        canSearch={true}
        pagination
      />
    </>
  );
};

export default UsersPage;
