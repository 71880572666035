import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  TextField,
} from '@mui/material';
import { forgotPassword } from 'api';
import Flex from 'components/Flex';
import Link from 'components/Link';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { Body, Title2 } from 'components/Text';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const { showSnackbar } = useSnackbar();
  const { mutate, isLoading, error } = useMutation(forgotPassword, {
    onSuccess: (data) => {
      if (!data) return;
      setEmail('');
      showSnackbar({
        variant: SNACKBAR_VARIANTS.SUCCESS,
        message: 'Reset Password Email is sent to your email address.',
      });
    },
  });

  const onSubmit = () => {
    if (!email) {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.ERROR,
        message: 'Please enter email address',
      });
      return;
    }
    mutate({ email });
  };

  useEffect(() => {
    if (!error) return;
    showSnackbar({
      variant: SNACKBAR_VARIANTS.ERROR,
      message: error,
    });
  }, [error]);

  return (
    <Container>
      <Box sx={{ width: { xs: '100%', md: '550px' }, margin: '0 auto' }}>
        <Card>
          <Box px={{ xs: 3.75, md: 7 }} my={5.625} width="100%">
            <Title2 fontFamily="Playfair Display" mt={0} mb={5}>
              Forgot Password
            </Title2>
            <Body color="grey.800" my={0}>
              Enter the email address you used when you signed up and we'll send
              you instructions to reset your password.
            </Body>
            <Box my={4}>
              <TextField
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
                placeholder="youremail@domain.com"
                label="Email Address"
                fullWidth
              />
            </Box>
            <Flex
              alignItems={{ xs: 'start', md: 'center' }}
              my={4}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <Body my={0} color="grey.600" bold mr={0.5}>
                Remembered your password?
              </Body>
              <Link href="/auth/sign-in" underline="none">
                <Body my={0} color="primary.main" bold>
                  Sign In
                </Body>
              </Link>
            </Flex>
            <Button
              onClick={onSubmit}
              large
              disabled={isLoading}
              variant="contained"
              sx={{
                backgroundColor: 'background.button.dark',
                px: { xs: 2, md: '55px' },
                width: { xs: '100%', md: 'auto' },
              }}
            >
              Send Reset Instructions
              {isLoading && (
                <Box
                  component={CircularProgress}
                  ml={2}
                  size={16}
                  color="inherit"
                />
              )}
            </Button>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default ForgotPasswordPage;
