import { getUserFieldsQuery } from 'api';
import { createContext, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from './Auth';
import { useOrganization } from './Organization';

export const UserFieldsContext = createContext();

export const UserFieldsContextProvider = ({ children }) => {
  const { user } = useAuth();
  const { activeOrganization } = useOrganization();
  const { data: userFields, refetch } = useQuery(getUserFieldsQuery(user?.id));
  const currentFields = useMemo(() => {
    if (!userFields || !activeOrganization) return [];
    return userFields?.filter(
      (field) => field.organizationId === activeOrganization?.id
    );
  }, [userFields, activeOrganization]);

  const defaultContext = {
    userFields,
    currentFields,
    refetch,
  };

  return (
    <UserFieldsContext.Provider value={defaultContext}>
      {children}
    </UserFieldsContext.Provider>
  );
};

export function useUserFields() {
  return useContext(UserFieldsContext);
}
