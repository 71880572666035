import { getGrantByIdQuery } from 'api';
import { useUserFields } from 'contexts/Fields';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import EditorPage from './Editor';

const NewGrantPage = ({ type }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('grantId');
  const { currentFields: userFields } = useUserFields();
  const { data: grant, isLoading } = useQuery(getGrantByIdQuery(id));

  return (
    <>
      {id && (
        <>
          {isLoading ? (
            'Loading...'
          ) : (
            <EditorPage
              type={type}
              grant={grant}
              isNew
              userFields={userFields}
            />
          )}
        </>
      )}
      {!id && <EditorPage type={type} isNew userFields={userFields} />}
    </>
  );
};

export default NewGrantPage;
