import { useState, useMemo } from 'react';
import { useMutation } from 'react-query';
import {
  downloadUserGrant,
  downloadUserGrantAsDocx,
  previewGrant,
  previewGrantAsDocx,
} from 'api';

export function useDownloadGrant({
  onPreviewSuccess,
  onPreviewDocxSuccess,
  onDownloadPdfSuccess,
  onDownloadDocxSuccess,
} = {}) {
  const [file, setFile] = useState();
  const [wordFile, setWordFile] = useState();
  const {
    mutate: preview,
    isLoading: isDownloadingPreview,
    error: previewError,
  } = useMutation((id) => previewGrant(id, false), {
    onSuccess: async (res) => {
      if (!res) return;
      setFile(new File([res], 'name'));
      if (onPreviewSuccess) onPreviewSuccess(res);
    },
  });
  const {
    mutate: previewDocx,
    isLoading: isDownloadingPreviewDocx,
    error: previewDocxError,
  } = useMutation((id) => previewGrantAsDocx(id, false), {
    onSuccess: async (res) => {
      if (!res) return;
      setWordFile(new File([res], 'name'));
      if (onPreviewDocxSuccess) onPreviewDocxSuccess(res);
    },
  });

  const {
    mutate: downloadPdf,
    isLoading: isDownloadingPdf,
    error: downloadPdfError,
  } = useMutation(downloadUserGrant, {
    onSuccess: async (res) => {
      if (!res) return;
      setFile(new File([res], 'name'));
      if (onDownloadPdfSuccess) onDownloadPdfSuccess(res);
    },
  });

  const {
    mutate: downloadDocx,
    isLoading: isDownloadingDocx,
    error: downloadDocxError,
  } = useMutation(downloadUserGrantAsDocx, {
    onSuccess: async (res) => {
      if (!res) return;
      setWordFile(new File([res], 'name'));
      if (onDownloadDocxSuccess) onDownloadDocxSuccess(res);
    },
  });

  const isDownloading = useMemo(
    () =>
      isDownloadingPreview ||
      isDownloadingPreviewDocx ||
      isDownloadingPdf ||
      isDownloadingDocx,
    [
      isDownloadingPreview,
      isDownloadingPreviewDocx,
      isDownloadingPdf,
      isDownloadingDocx,
    ]
  );

  const downloadError = useMemo(
    () =>
      previewError || previewDocxError || downloadPdfError || downloadDocxError,
    [previewError, previewDocxError, downloadPdfError, downloadDocxError]
  );

  return {
    file,
    setFile,
    wordFile,
    setWordFile,
    isDownloading,
    downloadError,
    preview,
    previewDocx,
    downloadPdf,
    downloadDocx,
  };
}
