import { Box, Button } from '@mui/material';
import Modal from 'components/Modal';
import { Body, Title2 } from 'components/Text';

export default function UpdateFieldsDialog({ open, onClose, onConfirm }) {
  return (
    <Modal maxWidth={538} open={open} onClose={onClose}>
      <Box mb={3}>
        <Title2 mt={0} mb={3} fontFamily="Playfair Display">
          Update
        </Title2>
        <Body my={0}>
          Would you like to use your new organization info for future grants?
        </Body>
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <Box
          component={Button}
          variant="contained"
          px={4.5}
          py={2}
          sx={{ backgroundColor: 'background.button.dark' }}
          onClick={onConfirm}
          mr={2}
        >
          Yes
        </Box>
        <Button variant="text" onClick={onClose}>
          No
        </Button>
      </Box>
    </Modal>
  );
}
