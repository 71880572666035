import {
  Box,
  Button,
  darken,
  Drawer as MuiDrawer,
  IconButton,
  styled,
  useTheme,
} from '@mui/material';
import Flex from 'components/Flex';
import { Body, Title2, Title3 } from 'components/Text';
import Bg3 from 'images/bg-3.png';
import { ReactComponent as CircleArrow } from 'images/icons/circle-arrow.svg';
import { ReactComponent as DoubleArrow } from 'images/icons/double-arrow.svg';
import { Navigation } from 'swiper';
import 'swiper/modules/navigation/navigation.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { FAQ_WIDTH } from 'utils/constants';

const FAQ_ITEMS = [
  {
    image: Bg3,
    title: 'Getting Started',
    content: () => (
      <>
        <Body mt={0} mb={2}>
          Ensure you won't be wasting your time before investing the effort in
          writing your grant proposal. Therefore, you must check the following
          before starting.
        </Body>
        <ul>
          <li>
            <Body my={0}>Eligibility Requirement</Body>
          </li>
          <li>
            <Body my={0}>Guidelines</Body>
          </li>
          <li>
            <Body my={0}>Funder's Focus and Priorities</Body>
          </li>
          <li>
            <Body my={0}>Geographical Area</Body>
          </li>
        </ul>
      </>
    ),
  },
];

const ToggleIcon = styled(({ reverse, ...props }) => (
  <Button variant="contained" {...props} />
))(({ theme, reverse }) => ({
  color: theme.palette.grey[800],
  transform: reverse ? 'rotate(180deg)' : 'none',
  background: theme.palette.common.white,
  height: 50,
  width: 50,
  minWidth: 50,
  padding: 0,
  boxShadow: 'rgb(0 0 0 / 24%) 0px 6px 16px',
  '&:focus, &:hover, &:active': {
    background: darken(theme.palette.common.white, 0.05),
  },
  svg: {
    width: 16,
    height: 16,
  },
}));

const ArrowIcon = styled(({ reverse, ...props }) => <IconButton {...props} />)(
  ({ theme, reverse }) => ({
    color: theme.palette.grey[800],
    transform: reverse ? 'rotate(180deg)' : 'none',
    svg: {
      width: 36,
      height: 36,
    },
  })
);

const FaqDrawer = ({ open, onToggle }) => {
  const theme = useTheme();
  return (
    <Box
      position="relative"
      height="100vh"
      right={open ? 0 : -(FAQ_WIDTH - 60)}
      bgcolor={open ? 'transparent' : 'background.drawer'}
      ml={2}
      zIndex={1200}
    >
      <MuiDrawer
        sx={{
          width: FAQ_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: FAQ_WIDTH,
            boxSizing: 'border-box',
            zIndex: 0,
            overflowX: 'hidden',
          },
          borderRadius: 0,
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Box
          py={{ xs: 3, md: 6 }}
          px={{ xs: 4, md: 7.25 }}
          sx={{
            display: 'block',
            width: FAQ_WIDTH,
            height: '100%',
            background: theme.palette.background.drawer,
          }}
        >
          <Body my={0} color="grey.600">
            Help Corner
          </Body>
          <Title2
            mt={0}
            mb={5.75}
            color="grey.800"
            fontFamily="Playfair Display"
          >
            Let us walk you through.
          </Title2>
          <Swiper
            modules={[Navigation]}
            navigation={{ nextEl: '.right-arrow', prevEl: '.left-arrow' }}
            slidesPerView={1}
            loop
          >
            {FAQ_ITEMS.map((item, i) => (
              <SwiperSlide key={i}>
                <Box mb={{ xs: 4, md: 10 }}>
                  <Box
                    component="img"
                    src={item.image}
                    sx={{ width: '100%', height: 'auto' }}
                    mb={5}
                    borderRadius={3}
                  />
                  <Title3
                    color="grey.800"
                    mb={3.5}
                    fontFamily="Playfair Display"
                  >
                    {item.title}
                  </Title3>
                  <item.content />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
          {FAQ_ITEMS.length > 1 && (
            <Flex alignItems="center" justifyContent="space-between">
              <ArrowIcon className="left-arrow" reverse>
                <CircleArrow />
              </ArrowIcon>
              <ArrowIcon className="right-arrow">
                <CircleArrow />
              </ArrowIcon>
            </Flex>
          )}
        </Box>
      </MuiDrawer>
      <Box zIndex={2000} position="absolute" top={118} left={-25}>
        <ToggleIcon reverse={!open} onClick={onToggle}>
          <DoubleArrow />
        </ToggleIcon>
      </Box>
    </Box>
  );
};

export default FaqDrawer;
