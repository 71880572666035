import { createGrant } from 'api/services/grants';
import PageHeader from 'components/PageHeader';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import Spinner from 'components/Spinner';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import GrantForm from './Form';
import { prepRequest } from './helper';

const CreateGrantPage = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { mutate: saveGrant, isLoading } = useMutation(createGrant, {
    onSuccess: (data) => {
      if (!data) return;
      showSnackbar({
        variant: SNACKBAR_VARIANTS.SUCCESS,
        message: 'Successfully saved your grant',
      });
      navigate(`/admin/manage-grants/${data.id}/edit`, { replace: true });
    },
    onError: (err) => {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.ERROR,
        message: err,
      });
    },
  });

  const handleSubmit = async (formData) => {
    // @TODO: disable field checks
    // const isFieldsValid = checkIfFieldsOnPages(formData);
    // if (!isFieldsValid) {
    //   showSnackbar({
    //     variant: SNACKBAR_VARIANTS.ERROR,
    //     message: 'Please fill all required fields',
    //   });
    //   return;
    // }
    const payload = await prepRequest(formData);
    saveGrant(payload);
  };

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <PageHeader title="New Grant" hideAction />
          <GrantForm onSubmit={handleSubmit} isSaving={isLoading} />
        </>
      )}
    </>
  );
};

export default CreateGrantPage;
