import { openPrintWindow } from 'utils/helpers/misc';

export const usePrint = () => {
  const handlePrint = (grant, fields = []) => {
    if (!grant) return;
    const content = grant.pages.reduce((template, page) => {
      let currentPageContent = page.content;
      for (const field of fields) {
        const reg = new RegExp(`\\{{${field.name}\\}}`, 'g');
        currentPageContent = currentPageContent.replace(
          reg,
          field.value || `{{${field.name}}}`
        );
      }
      return `${template}${currentPageContent}<div class='pagebreak'></div>`;
    }, '');
    openPrintWindow({ title: grant.title, content });
  };
  return handlePrint;
};
