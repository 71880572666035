import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Box, Button, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { exportCSV } from './helpers';

const gridStyle = { minHeight: 550 };

const DataGrid = ({
  idProperty = 'id',
  defaultLimit = 10,
  rowHeight = 50,
  style = gridStyle,
  canExport,
  canSearch,
  onSearch,
  onConfigChange,
  loading,
  defaultConfig,
  ...props
}) => {
  const [gridRef, setGridRef] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [config, setConfig] = useState({
    skip: 0,
    limit: 10,
    filter: null,
    search: null,
    ...defaultConfig,
  });

  const handleExportCSV = () => exportCSV(gridRef);

  const updateConfig = (value) => {
    if (isInitialLoad) setIsInitialLoad(false);
    setConfig((c) => ({
      ...c,
      ...value,
    }));
  };

  const handleSearch = _.debounce((e) => {
    updateConfig({ q: (e.target?.value || '').trim() });
  }, 2000);
  const handleSkipChange = (skip) => {
    updateConfig({ skip });
  };
  const handleLimitChange = (limit) => {
    updateConfig({ limit });
  };

  const handleSortChange = (e) => {
    if (!e.name) {
      updateConfig({ sort: undefined });
      return;
    }
    updateConfig({
      sort: {
        [e.name]: e.dir,
      },
    });
  };

  const handleFilterChange = (filters) => {
    let filter;
    if (filters?.length) {
      for (const query of filters) {
        if (
          (typeof query.value === 'string' && !!query.value) ||
          (typeof query.value !== 'string' && query.value != null)
        ) {
          filter = { ...filter, [query.name]: query.value };
        }
      }
    }
    updateConfig({ filter });
  };

  useEffect(() => {
    if (isInitialLoad) return;
    if (onConfigChange) onConfigChange(config);
  }, [onConfigChange, config, isInitialLoad]);

  return (
    <>
      {(canExport || canSearch) && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pb={2}
        >
          {canExport && (
            <Button variant="contained" size="small" onClick={handleExportCSV}>
              CSV
            </Button>
          )}
          {canSearch && (
            <TextField
              size="small"
              variant="outlined"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Box>
      )}
      <ReactDataGrid
        loading={loading}
        handle={setGridRef}
        idProperty={idProperty}
        style={style}
        defaultLimit={defaultLimit}
        rowHeight={rowHeight}
        onSortInfoChange={handleSortChange}
        onFilterValueChange={handleFilterChange}
        onSkipChange={handleSkipChange}
        onLimitChange={handleLimitChange}
        enableColumnFilterContextMenu={false}
        {...props}
      />
    </>
  );
};

export default DataGrid;
