import callApi, { getValidationErrorMessage } from 'api/callApi';

const KEYS = {
  organizations: (userId) => ['organizations', userId],
};

export const getOrganizations = async (userId) =>
  callApi(`organizations?userId=${userId}`, {
    needsAuthToken: true,
  });

export const getOrganizationsQuery = (userId) => ({
  cacheTime: 0,
  queryKey: KEYS.organizations(userId),
  queryFn: ({ queryKey }) => {
    const [, _] = queryKey;
    return getOrganizations(_);
  },
  enabled: !!userId,
});

export const createOrganization = async (data) => {
  try {
    const res = callApi(`/organizations`, {
      method: 'POST',
      data,
      needsAuthToken: true,
      useFullError: true,
    });
    return res;
  } catch (error) {
    throw new Error(getValidationErrorMessage(error));
  }
};

export const updateOrganization = async (id, data, updateUserFields) => {
  try {
    const query = updateUserFields ? 'updateUserFields=true' : '';
    const res = await callApi(`/organizations/${id}?${query}`, {
      method: 'PATCH',
      data,
      needsAuthToken: true,
      useFullError: true,
    });
    return res;
  } catch (error) {
    throw new Error(getValidationErrorMessage(error));
  }
};

export const deleteOrganization = async (id) => {
  try {
    const res = await callApi(`/organizations/${id}`, {
      method: 'DELETE',
      needsAuthToken: true,
      useFullError: true,
    });
    return res;
  } catch (error) {
    throw new Error(getValidationErrorMessage(error));
  }
};
