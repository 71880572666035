import { Box, Button, Container, styled } from '@mui/material';
import Flex from 'components/Flex';
import { Body, Title2 } from 'components/Text';
import { useAuth } from 'contexts/Auth';
import Bg2 from 'images/bg-2.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Overlay = styled(Box)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.common.white} 20%, rgba(0, 0, 0, 0) 90%), url(${Bg2})`,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  borderRadius: theme.spacing(4),
}));

const OrgConfirmation = () => {
  const navigate = useNavigate();
  const { user, loading } = useAuth();
  const handleStart = () => {
    navigate('/auth/org-setup');
  };
  useEffect(() => {
    if (loading) return;
    if (!user) {
      navigate('/auth/sign-in');
    }
  }, [user, loading]);

  return (
    <Container>
      <Box
        width={{ xs: '100%', md: 640 }}
        height={{ xs: 'auto', md: 520 }}
        position="relative"
        bgcolor="common.white"
        borderRadius={5}
        boxShadow={'rgb(0 0 0 / 24%) 0px 6px 16px'}
        mx="auto"
      >
        <Overlay />
        <Flex
          position="relative"
          px={{ xs: 4, md: 7.25 }}
          py={{ xs: 4, md: 6.375 }}
          height="100%"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box maxWidth={317} mb={{ xs: 5, md: 0 }}>
            <Title2
              mt={0}
              fontFamily="Playfair Display"
              maxWidth={{ xs: '100%', md: 271 }}
            >
              Welcome to Team Hackett!
            </Title2>
            <Body mt={0} mb={2}>
              Hi {user.firstName}!
            </Body>
            <Body mt={0} mb={2}>
              I'm J. Hackett, let me personally welcome you to Grant Suite
              Application. To further assist you, Team Hackett would like to ask
              some details regarding your organization.
            </Body>
            <Body mt={0} mb={2}>
              Thank you and hope to see you soon!
            </Body>
          </Box>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'background.button.dark' }}
            onClick={handleStart}
          >
            Start now
          </Button>
        </Flex>
      </Box>
    </Container>
  );
};

export default OrgConfirmation;
