import { Box } from '@mui/material';
import { Title1, Title3 } from 'components/Text';
import { useAuth } from 'contexts/Auth';
import DashboardBanner from './DashboardBanner';
import SearchCard from './SearchCard';

const HomePage = () => {
  const { user } = useAuth();
  return (
    <Box overflow="auto" height="100%" px={4} py={3}>
      <Title3 mt={0} mb={1.25}>
        Hi {user?.firstName}!
      </Title3>
      <Title1 fontFamily="Playfair Display" mt={0} mb={3.625}>
        Win Grants! Start today.
      </Title1>
      <DashboardBanner mb={4} />
      <SearchCard />
    </Box>
  );
};

export default HomePage;
