import * as qs from 'qs';
import callApi from 'api/callApi';

const KEYS = {
  all: (filter) => ['paymentsHistory', filter],
  byId: (id) => ['PaymentsHistoryById', id],
};

export const getPaymentsHistory = (filters) => {
  const query = qs.stringify(filters);
  return callApi(`/payments-history?${query}`, {
    method: 'GET',
    needsAuthToken: true,
  });
};

export const getPaymentsHistoryQuery = (filter) => ({
  cacheTime: 0,
  queryKey: KEYS.all(filter),
  queryFn: ({ queryKey }) => getPaymentsHistory(queryKey[1]),
  enabled: !!filter,
});
