import { useMemo, useState } from 'react';
import { Box, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useOrganization } from 'contexts/Organization';
import { useSaveMyGrant } from 'hooks/useSaveMyGrant';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { ORG_FIELDS_MAPPING } from './helpers';

const OutdatedOrgInfoWarning = ({
  id,
  grant,
  type,
  refetchGrant,
  outdatedFields,
}) => {
  const { showSnackbar } = useSnackbar();
  const { organizations } = useOrganization();
  const { updateMyGrant, isSaving } = useSaveMyGrant({
    onError: () => {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.ERROR,
        message: `Failed saved your ${type}`,
      });
    },
    onUpdateSuccess: () => {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.SUCCESS,
        message: `Successfully saved your ${type}`,
      });
      if (refetchGrant) refetchGrant();
    },
  });
  const [isConfirmationOpen, setIsConfirmationOpen] = useState();

  const grantOrg = useMemo(
    () =>
      organizations?.find((org) => org.id === grant.organizationId) ??
      organizations?.find((org) => org.isActive),
    [organizations]
  );

  const isOutdated = useMemo(() => outdatedFields.length > 0, [outdatedFields]);

  const updateGrantUserFields = () => {
    const fields = grant.fields ?? [];
    for (const mapping of ORG_FIELDS_MAPPING) {
      let item = fields.find((f) => f.name === mapping.field);
      const value =
        mapping.field === 'listOfBoardMembers'
          ? (grantOrg[mapping.org] ?? mapping.defaultValue).map((f) => f.name)
          : grantOrg[mapping.org] ?? mapping.defaultValue;
      if (item) {
        item.value = value;
      } else {
        item = {
          name: mapping.field,
          type: mapping.type,
          value,
        };
        fields.push(item);
      }
    }
    updateMyGrant({
      id,
      payload: {
        fields,
        organizationId: grantOrg.id,
      },
    });
  };

  const handleClick = () => {
    setIsConfirmationOpen(true);
  };

  const handleConfirm = () => {
    setIsConfirmationOpen(false);
    updateGrantUserFields();
  };

  if (!isOutdated) return null;

  return (
    <>
      <Card sx={{ p: 2, mb: 5 }}>
        <Box sx={{ mb: 1 }}>
          Your organization information is different from the information in
          this grant draft.
        </Box>
        <LoadingButton
          size="small"
          variant="contained"
          loading={isSaving}
          loadingIndicator="Saving…"
          color="secondary"
          onClick={handleClick}
          sx={{ py: 1.25 }}
          fullWidth
        >
          Use organization information
        </LoadingButton>
      </Card>
      <ConfirmationDialog
        open={isConfirmationOpen}
        onConfirm={handleConfirm}
        onClose={() => setIsConfirmationOpen(false)}
        content="Are you really sure you want to use organization information on this grant draft? The details you have put here will be replaced by your organization information."
        confirmLabel="Overwrite"
        cancelLabel="Cancel"
      />
    </>
  );
};

export default OutdatedOrgInfoWarning;
