import { Box } from '@mui/material';
import Flex from 'components/Flex';
import useValidateUserAccess from 'hooks/useValidateUserAccess';
import BgUnauthorized from 'images/bg-unauthorized.png';
import LogoWhite from 'images/logo-white.png';
import { Outlet } from 'react-router-dom';

const UnauthorizedLayout = () => {
  const { isInitialized } = useValidateUserAccess();
  if (!isInitialized) return null;
  return (
    <Box
      height="100vh"
      width="100vw"
      position="relative"
      overflow="auto"
      sx={{
        background: `linear-gradient(#0c50e1, rgb(34 51 84 / 70%)),url('${BgUnauthorized}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Flex center mt={'50px'} mb={1}>
        <Box component="img" width={200} src={LogoWhite} alt="6FG" />
      </Flex>
      <Outlet />
    </Box>
  );
};

export default UnauthorizedLayout;
