import {
  getUserByToken,
  getUserPlansQuery,
  getPaymentsHistoryQuery,
  updateUser,
} from 'api';
import ReactGA from 'react-ga4';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { useAuth } from 'contexts/Auth';
import { useMutation, useQuery } from 'react-query';
import UserForm from './Form';

const getUserByTokenQuery = (user) => ({
  queryKey: () => ['getUserByTokenQuery'],
  queryFn: getUserByToken,
  enabled: !!user,
  cacheTime: 0,
});

const UserProfilePage = () => {
  const { user: authUser, refreshUser } = useAuth();
  const { showSnackbar } = useSnackbar();

  const { data: userPlans, isFetching: isFetchingUserPlans } = useQuery(
    getUserPlansQuery({
      limit: Number.MAX_SAFE_INTEGER,
      sort: { createdDate: -1 },
      filter: {
        userId: authUser.id,
        active: true,
      },
    })
  );

  const { data: paymentsHistory, isFetching: isFetchingPaymentHistory } =
    useQuery(
      getPaymentsHistoryQuery({
        limit: Number.MAX_SAFE_INTEGER,
        sort: { createdDate: -1 },
        filter: {
          userId: authUser.id,
        },
      })
    );

  const { data: user, isLoading } = useQuery(getUserByTokenQuery(authUser));
  const { mutate, isLoading: isSaving } = useMutation(
    (data) => updateUser(user.id, data),
    {
      onSuccess: (data) => {
        if (!data) return;
        showSnackbar({
          message: 'Successfully updated your account',
          variant: SNACKBAR_VARIANTS.SUCCESS,
        });
        ReactGA.event({
          category: 'User Actions',
          action: 'save_user_profile',
          label: `Saved profile info for user ${user.id}`,
        });
        refreshUser();
      },
      onFailure: (err) => {
        showSnackbar({
          message: err?.message || 'Failed to update your account',
          variant: SNACKBAR_VARIANTS.ERROR,
        });
      },
    }
  );

  if (isLoading || !user) return null;

  return (
    <UserForm
      user={user}
      userPlans={userPlans}
      paymentsHistory={paymentsHistory}
      onSubmit={mutate}
      isLoading={isLoading || isSaving}
      isFetchingPaymentHistory={isFetchingPaymentHistory}
      isFetchingUserPlans={isFetchingUserPlans}
    />
  );
};
export default UserProfilePage;
