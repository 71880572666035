import { Container, Grid, InputLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getGrantFiltersQuery } from 'api';
import { useDebouncedInput } from 'hooks/useDebouncedInput';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { GRANT_SUBCATEGORIES } from 'utils/constants';

const GrantFilters = ({
  search,
  filters,
  onSearch,
  onChange,
  hasContentFilter = false,
}) => {
  const {
    value,
    debouncedValue,
    onChange: handleSearch,
  } = useDebouncedInput(search);
  const { data: grantFilters } = useQuery(getGrantFiltersQuery());

  const handleSubcategoryChanged = (event) => {
    onChange({
      ...filters,
      subcategory: [event.target.value],
    });
  };

  const handleCountryChanged = (event) => {
    onChange({
      ...filters,
      'location.country': event.target.value,
    });
  };

  const handleStateChanged = (event) => {
    onChange({
      ...filters,
      'location.state': event.target.value,
    });
  };

  const handleHasContentChanged = (event) => {
    const { value: selectValue } = event.target;
    let hasContent;
    if (selectValue === 'has-content') hasContent = true;
    if (selectValue === 'has-no-content') hasContent = false;
    onChange({ ...filters, hasContent });
  };

  // const handleTagsChanged = (tags) => {
  //   onChange({
  //     ...filters,
  //     tags,
  //   });
  // };

  useEffect(() => {
    onSearch(debouncedValue);
  }, [debouncedValue]);

  const hasContentValue = useMemo(() => {
    if (filters?.hasContent === undefined) return 'all';
    if (filters?.hasContent === true) return 'has-content';
    return 'has-no-content';
  }, [filters?.hasContent]);

  return (
    <Container>
      <Box sx={{ mb: 4 }}>
        <Grid spacing={2} container>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                onChange={handleSearch}
                value={value || search}
                placeholder="Type keyword here"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                value={filters.subcategory?.[0] || ''}
                label="Category"
                onChange={handleSubcategoryChanged}
                fullWidth
              >
                <MenuItem value="">Select a category</MenuItem>
                {GRANT_SUBCATEGORIES.map((c) => (
                  <MenuItem key={c.value} value={c.value}>
                    {c.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {grantFilters?.countries?.length > 0 && (
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Country</InputLabel>
                <Select
                  value={filters['location.country'] || ''}
                  label="Country"
                  onChange={handleCountryChanged}
                  fullWidth
                >
                  <MenuItem value="">Select a country</MenuItem>
                  {grantFilters.countries.map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {filters?.['location.country'] && grantFilters?.states?.length > 0 && (
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>State</InputLabel>
                <Select
                  value={filters['location.state'] || ''}
                  label="State"
                  onChange={handleStateChanged}
                  fullWidth
                >
                  <MenuItem value="">Select a state</MenuItem>
                  {grantFilters.states.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {hasContentFilter && (
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Templates</InputLabel>
                <Select
                  value={hasContentValue}
                  label="Templates"
                  onChange={handleHasContentChanged}
                  fullWidth
                >
                  <MenuItem value="all">General</MenuItem>
                  <MenuItem value="has-content">W/ Templates</MenuItem>
                  <MenuItem value="has-no-content">W/O Templates</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        {/* 
        <Box sx={{ mt: 2 }}>
          <Grid spacing={2} container>
            <Grid item xs={6}>
              <TagInput
                onChange={handleTagsChanged}
                defaultValue={filters.tags}
              />
            </Grid>
          </Grid>
        </Box>
        */}
      </Box>
    </Container>
  );
};

export default GrantFilters;
