import { Add } from '@mui/icons-material';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton, TextField } from '@mui/material';
import Flex from 'components/Flex';
import Text from 'components/Text';
import { useFieldArray } from 'react-hook-form';

const BoardMembersList = ({ control, register, errors, ...props }) => {
  const {
    fields: boardMembers,
    append: addBoardMember,
    remove: removeBoardMember,
  } = useFieldArray({
    control,
    name: 'boardMembers',
  });

  const handleAddBoardMember = () => {
    addBoardMember({ name: '' });
  };

  const handleRemoveBoardMember = (index) => {
    removeBoardMember(index);
  };

  return (
    <Box {...props}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="subtitle1">Board Members</Text>
        <IconButton onClick={() => handleAddBoardMember()}>
          <Add color="success" />
        </IconButton>
      </Flex>
      {boardMembers?.map((_, bmIndex) => (
        <Flex alignItems="center" key={`${bmIndex}`} mb={1.5}>
          <TextField
            error={!!errors?.boardMembers?.[bmIndex]?.name}
            helperText={errors?.boardMembers?.[bmIndex]?.name?.message}
            name={`boardMembers[${bmIndex}].name`}
            {...register(`boardMembers[${bmIndex}].name`, {
              required: {
                value: true,
                message: 'Board member name is required',
              },
            })}
            fullWidth
            sx={{ mr: 2 }}
          />
          <IconButton onClick={() => handleRemoveBoardMember(bmIndex)}>
            <RemoveIcon color="error" />
          </IconButton>
        </Flex>
      ))}
    </Box>
  );
};

export default BoardMembersList;
