import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Divider from 'components/Divider';
import Field from 'components/Field';
import Flex from 'components/Flex';
import { Title3 } from 'components/Text';
import { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

const PAYMENT_TYPES = [
  { value: 'one-time', label: 'One time payment' },
  { value: 'recurring', label: 'Recurring payment' },
];

const PaymentItem = ({ index, onDelete }) => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Field label="Payment Name">
        <TextField
          error={!!errors?.paymentModes?.[index]?.name}
          helperText={errors?.paymentModes?.[index]?.name?.message}
          name={`paymentModes.${index}.name`}
          {...register(`paymentModes.${index}.name`, {
            required: {
              value: true,
              message: 'Name is required',
            },
          })}
          fullWidth
        />
      </Field>
      <Field label="Payment Form Id" mt={2}>
        <TextField
          error={!!errors?.paymentModes?.[index]?.formId}
          helperText={errors?.paymentModes?.[index]?.formId?.message}
          name={`paymentModes.${index}.formId`}
          type="number"
          {...register(`paymentModes.${index}.formId`, {
            required: {
              value: true,
              message: 'Form Id is required',
            },
          })}
          fullWidth
        />
      </Field>
      <Field label="Payment Link" mt={2}>
        <TextField
          error={!!errors?.paymentModes?.[index]?.paymentLink}
          helperText={errors?.paymentModes?.[index]?.paymentLink?.message}
          name={`paymentModes.${index}.paymentLink`}
          {...register(`paymentModes.${index}.paymentLink`, {
            required: {
              value: true,
              message: 'Link is required',
            },
          })}
          fullWidth
        />
      </Field>
      <Field label="Payment Type" mt={2}>
        <Controller
          control={control}
          name={`paymentModes.${index}.type`}
          render={({ field: { onChange, ref, ...selectProps } }) => (
            <Select
              fullWidth
              inputRef={ref}
              onChange={(e) => onChange(e.target.value)}
              displayEmpty
              {...selectProps}
            >
              {PAYMENT_TYPES.map((item, i) => (
                <MenuItem key={i} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Field>
      <Flex justifyContent="end" mt={2}>
        <Button
          variant="outlined"
          color="error"
          onClick={onDelete}
          sx={{ height: 40 }}
        >
          Delete
        </Button>
      </Flex>
    </Box>
  );
};

const PaymentModes = () => {
  const { control } = useFormContext();
  const [itemToDelete, setItemToDelete] = useState();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'paymentModes',
  });

  const handleAdd = () => {
    append({
      name: 'New Payment',
      paymentLink: '',
      type: 'one-time',
    });
  };

  const handleDelete = () => {
    if (!itemToDelete) return;
    remove(itemToDelete.index);
    setItemToDelete(undefined);
  };

  return (
    <Box component={Card} width={{ xs: '100%', md: 568 }} mb={4}>
      <CardContent>
        <Flex alignItems="center" justifyContent="space-between">
          <Title3 my={0}>Payment forms</Title3>
          <Button variant="outlined" onClick={handleAdd} sx={{ height: 40 }}>
            Add
          </Button>
        </Flex>
        <Box my={3}>
          <Divider />
        </Box>
        {fields.map((value, idx) => (
          <Box key={`payment-mode-${idx}`}>
            <PaymentItem
              index={idx}
              onDelete={() => {
                setItemToDelete({ value, index: idx });
              }}
            />
            {idx < fields.length - 1 && (
              <Box my={3}>
                <Divider />
              </Box>
            )}
          </Box>
        ))}
      </CardContent>
      <ConfirmationDialog
        open={!!itemToDelete}
        onConfirm={handleDelete}
        onClose={() => setItemToDelete(undefined)}
        content={`Are you really sure you want to remove "${itemToDelete?.value?.name} (Form id ${itemToDelete?.value?.formId})"?`}
        confirmLabel="Delete"
        cancelLabel="Cancel"
      />
    </Box>
  );
};

export default PaymentModes;
