import { useMutation, useQuery, useQueryClient } from 'react-query';
import { USER_QUERY_KEYS } from 'api';
import {
  createOrganization as createOrganizationApi,
  deleteOrganization as deleteOrganizationApi,
  getOrganizationsQuery,
  updateOrganization as updateOrganizationApi,
} from 'api/services/organizations';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';

export const useManageOrganizations = (userId) => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const {
    data: organizations,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(getOrganizationsQuery(userId));

  const { mutate: updateMutation, isLoading: isUpdating } = useMutation(
    ({ id, showPrompt: _, updateUserFields, ...data }) =>
      updateOrganizationApi(id, data, updateUserFields),
    {
      onSuccess: (data, req) => {
        if (!data) return;
        refetch();
        queryClient.invalidateQueries({
          queryKey: USER_QUERY_KEYS.fields(userId),
        });
        if (!req?.showPrompt) return;
        showSnackbar({
          message: 'Successfully updated your organization',
          variant: SNACKBAR_VARIANTS.SUCCESS,
        });
      },
      onFailure: (err) => {
        showSnackbar({
          message: err?.message || 'Failed to update your organization',
          variant: SNACKBAR_VARIANTS.ERROR,
        });
      },
    }
  );

  const {
    mutate: createMutation,
    data: createSuccess,
    isLoading: isCreating,
  } = useMutation((data) => createOrganizationApi(data), {
    onSuccess: (data) => {
      if (!data) return;
      showSnackbar({
        message: 'Successfully created your organization',
        variant: SNACKBAR_VARIANTS.SUCCESS,
      });
      refetch();
    },
    onFailure: (err) => {
      showSnackbar({
        message: err?.message || 'Failed to create your organization',
        variant: SNACKBAR_VARIANTS.ERROR,
      });
    },
  });

  const {
    mutate: deleteMutation,
    deleteSuccess,
    isLoading: isDeleting,
  } = useMutation((id) => deleteOrganizationApi(id), {
    onSuccess: (data) => {
      if (!data) return;
      showSnackbar({
        message: 'Successfully removed your organization',
        variant: SNACKBAR_VARIANTS.SUCCESS,
      });
      refetch();
    },
    onFailure: (err) => {
      showSnackbar({
        message: err?.message || 'Failed to remove your organization',
        variant: SNACKBAR_VARIANTS.ERROR,
      });
    },
  });

  const createOrganization = (data) => {
    if (isCreating) return;
    createMutation(data);
  };

  const updateOrganization = (id, data) => {
    if (isUpdating) return;
    updateMutation({ id, ...data });
  };

  const deleteOrganization = (id) => {
    if (isDeleting) return;
    deleteMutation(id);
  };

  return {
    organizations,
    isFetching: isLoading || isFetching,
    createSuccess,
    deleteSuccess,
    refreshOrganizations: refetch,
    createOrganization,
    updateOrganization,
    deleteOrganization,
  };
};
