import Add from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton } from '@mui/material';
import Flex from 'components/Flex';
import { Body } from 'components/Text';
import { useEffect, useState } from 'react';
import FieldText from './FieldText';

const getDefault = (value) => {
  if (!value) return [];
  if (typeof value === 'string') return [value];
  return Array.isArray(value) ? value : [value.toString()];
};

const TextListField = ({ label, value, isOutdated, onChange }) => {
  const [items, setItems] = useState(getDefault(value));

  const handleItemUpdate = (text, index) => {
    const newItems = [...value];
    newItems[index] = text;
    onChange(newItems);
  };

  const handleAddItem = () => {
    const newItems = [...items, ''];
    onChange(newItems);
  };

  const handleRemoveItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onChange(newItems);
  };

  useEffect(() => {
    if (!value) return;
    if (typeof value === 'string') {
      setItems(value.split(','));
    } else {
      setItems(value);
    }
  }, [value]);

  if (!items) return null;

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Body bold my={0}>
          {label}
        </Body>
        <IconButton onClick={() => handleAddItem()}>
          <Add color="success" />
        </IconButton>
      </Flex>
      {items?.map((item, index) => (
        <Flex
          mb={1}
          key={index}
          alignItems="center"
          justifyContent="space-between"
        >
          <FieldText
            value={item}
            onChange={(e) => handleItemUpdate(e, index)}
            sx={{
              ...(isOutdated && {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'primary.main',
                  borderWidth: 1,
                  borderStyle: 'solid',
                },
              }),
            }}
          />
          <IconButton onClick={() => handleRemoveItem(index)}>
            <RemoveIcon color="error" />
          </IconButton>
        </Flex>
      ))}
    </Box>
  );
};

export default TextListField;
