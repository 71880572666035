import { styled, TextField } from '@mui/material';
import { BRAND_COLORS } from 'theme';

const StyledInput = styled(TextField)(({ theme, isOutdated }) => ({
  '& .MuiInputLabel-root': {
    color: BRAND_COLORS.darkGray,
    opacity: 0.5,
  },
  '& .MuiInputBase-root': {
    backgroundColor: BRAND_COLORS.white,
  },
  ...(isOutdated && {
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  }),
}));

const FieldText = ({ onChange, isOutdated, ...props }) => {
  const handleChange = (e) => {
    if (!onChange) return;
    onChange(e.target.value);
  };
  return (
    <StyledInput
      onChange={handleChange}
      fullWidth
      isOutdated={isOutdated}
      {...props}
    />
  );
};

export default FieldText;
