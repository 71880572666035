import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { getGrants } from 'api/services/grants';
import DataGrid from 'components/DataGrid';
import GridAction from 'components/GridAction';
import PageHeader from 'components/PageHeader';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { useDataGridQuery } from 'hooks';
import * as moment from 'moment';
import { useEffect } from 'react';
import {
  DEFAULT_DATAGRID_CONFIG,
  GRANTS_TYPES,
  GRANT_STATUSES,
} from 'utils/constants';

const getStatusesList = () =>
  Object.keys(GRANT_STATUSES).map((k) => ({
    id: GRANT_STATUSES[k],
    label: GRANT_STATUSES[k],
  }));

const getTypesList = () =>
  Object.keys(GRANTS_TYPES).map((k) => ({
    id: GRANTS_TYPES[k],
    label: GRANTS_TYPES[k],
  }));

const FILTER_VALUE = [
  { name: 'title', operator: 'eq', type: 'string', value: '' },
  { name: 'type', operator: 'eq', type: 'select' },
  { name: 'status', operator: 'eq', type: 'select' },
  { name: 'tags', operator: 'eq', type: 'string', value: '' },
  {
    name: 'createdDate',
    operator: 'eq',
    type: 'date',
    value: '',
  },
];

const COLUMNS = [
  {
    name: 'id',
    type: 'string',
    header: 'ID',
    defaultVisible: false,
  },
  { name: 'title', header: 'Title', defaultFlex: 1 },
  {
    name: 'type',
    header: 'Type',
    defaultFlex: 1,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'All',
      dataSource: getTypesList(),
    },
  },
  {
    name: 'status',
    header: 'Status',
    defaultFlex: 1,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'All',
      dataSource: getStatusesList(),
    },
  },
  {
    name: 'tags',
    header: 'Tags',
    defaultFlex: 1,
    render: ({ value }) => value?.join(', '),
  },
  {
    name: 'createdDate',
    header: 'Created',
    defaultFlex: 1,
    filterEditor: DateFilter,
    filterEditorProps: () => ({
      dateFormat: 'yyyy-MM-DD',
      cancelButton: false,
      highlightWeekends: true,
      weekNumbers: false,
    }),
    render: ({ value }) => moment(value).format('MMM DD, YYYY hh:mm A'),
    getExportValue: ({ createdDate }) =>
      moment(createdDate).format('MMM DD, YYYY hh:mm A'),
  },
  {
    id: 'action',
    header: 'Action',
    defaultFlex: 1,
    textAlign: 'center',
    noExport: true,
    render: ({ data }) => (
      <GridAction
        editUrl={`/admin/manage-grants/${data.id}/edit`}
        hideDelete={true}
      />
    ),
  },
];

const GrantsPage = () => {
  const { showSnackbar } = useSnackbar();
  const { dataSource, isLoading, error, updateConfig } = useDataGridQuery(
    'grants',
    getGrants,
    DEFAULT_DATAGRID_CONFIG
  );

  useEffect(() => {
    if (!error) return;
    showSnackbar({
      message: 'Unable to load grants',
      variant: SNACKBAR_VARIANTS.ERROR,
    });
  }, [error]);

  return (
    <>
      <PageHeader
        title="Grants"
        actionLabel="Create New"
        actionLink="/admin/manage-grants/new"
      />
      <DataGrid
        columns={COLUMNS}
        defaultFilterValue={FILTER_VALUE}
        defaultConfig={DEFAULT_DATAGRID_CONFIG}
        dataSource={dataSource}
        loading={isLoading}
        onConfigChange={updateConfig}
        canExport={true}
        canSearch={true}
        pagination
      />
    </>
  );
};

export default GrantsPage;
