import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Card, IconButton, Stack, styled } from '@mui/material';
import Flex from 'components/Flex';
import PdfViewer from 'components/PdfViewer';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { Body, Title3 } from 'components/Text';
import { useLayout } from 'contexts/Layout';
import { useComponentDimensionsFunction } from 'hooks/useComponentDimensions';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Navigation } from 'swiper';
import 'swiper/modules/navigation/navigation.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { BRAND_COLORS, getScrollbarStyle } from 'theme';
import DocumentActions from './DocumentActions';

const MEASUREMENT = {
  DOCUMENT_CONTAINER: {
    paddingTop: 85,
  },
  ACTION_FOOTER: { height: 88, bottom: 46 },
};

const ArrowIcon = styled(({ reverse, ...props }) => <IconButton {...props} />)(
  ({ theme, reverse }) => ({
    color: theme.palette.grey[800],
    transform: reverse ? 'rotate(180deg)' : 'none',
    svg: {
      width: 36,
      height: 36,
    },
  })
);

const getPdfDocumentHeight = () =>
  `calc(100vh - ${[
    MEASUREMENT.DOCUMENT_CONTAINER.paddingTop,
    MEASUREMENT.ACTION_FOOTER.height,
    MEASUREMENT.ACTION_FOOTER.bottom,
    50,
    // eslint-disable-next-line no-return-assign, no-param-reassign
  ].reduce((a, b) => (a += b), 0)}px)`;

const DocumentContainer = styled(Box)({
  height: '100%',
  paddingTop: 85,
  paddingLeft: 72,
  paddingRight: 72,

  '& .react-pdf__Document': {
    ...getScrollbarStyle(),
    overflow: 'auto',
    height: getPdfDocumentHeight(),
    '& .react-pdf__Page': {
      height: '100%',
      '& .react-pdf__Page__svg': {
        height: '100% !important',
        width: '100% !important',
      },
    },
  },
});

const SwiperStyled = styled(({ parentWidth, ...props }) => (
  <Swiper {...props} />
))(({ parentWidth }) => {
  let maxWidth = 1024;
  if (parentWidth < 900) {
    maxWidth = '100%';
  } else if (parentWidth < 1024) {
    maxWidth = 1024;
  }

  return {
    overflowY: 'auto',
    maxWidth,
    display: 'flex',
    flexGrow: 1,
    '& .swiper-slide': {
      height: 'auto',
      width: '100% !important',
    },
    '& .swiper-wrapper': {
      height: 'auto',
    },
  };
});

const DocumentViewer = ({
  pages,
  type,
  file,
  isFile = false,
  isLoadingFile,
  loadingFileError,
  onDownload,
  onPrint,
  onCancel,
  isSaving,
  disableDownload,
}) => {
  const [activePage, setActivePage] = useState(0);
  const [charCount, setCharCount] = useState(0);
  const { showSnackbar } = useSnackbar();
  const { isFaqOpen, isDrawerOpen } = useLayout();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef();
  const { getDimensions, dimensions: containerDimensions } =
    useComponentDimensionsFunction(containerRef);

  const canDownload = useMemo(
    () => !disableDownload && file,
    [disableDownload, file]
  );

  const handleFinishReadingCharCount = (count) => {
    setCharCount(count);
  };

  const handleOnSwipe = (activeIndex) => {
    const currPage = pages[activeIndex];
    if (!currPage) return;
    const str = currPage.replace(/(<([^>]+)>)/gi, '');
    setCharCount(str.length);
    setActivePage(activeIndex);
  };

  const handleDownload = () => {
    if (!onDownload) return;
    if (disableDownload) {
      showSnackbar({
        message: 'Please save this template first before downloading.',
        variant: SNACKBAR_VARIANTS.WARNING,
      });
      return;
    }
    onDownload();
  };

  useEffect(() => {
    if (!pages || pages.length === 0) {
      setCharCount(0);
      return;
    }
    const ct = pages[activePage].replace(/(<([^>]+)>)/gi, '');
    setCharCount(ct.length);
  }, [pages]);

  const hasSelectedGrant = useMemo(() => {
    if (isFile && file) return true;
    if (!isFile && pages && pages.length > 0) return true;
    return false;
  }, [isFile, file, pages]);

  useEffect(() => {
    setTimeout(() => {
      const d = getDimensions();
      setDimensions(d);
    }, 200);
  }, [isFaqOpen, isDrawerOpen, pages]);

  useEffect(() => {
    setDimensions(containerDimensions);
  }, [containerDimensions]);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        maxHeight: '100%',
        backgroundColor: BRAND_COLORS.white,
        width: '100%',
      }}
    >
      {!isFile && pages && pages.length > 0 && (
        <Stack
          flexDirection="column"
          width="100%"
          height="100%"
          overflow="auto"
          px={{ xs: 2, md: 6 }}
          gap={2}
          py={3}
          bgcolor="grey.200"
          ref={containerRef}
        >
          <SwiperStyled
            slidesPerView={1}
            modules={[Navigation]}
            onSlideChange={({ activeIndex }) => handleOnSwipe(activeIndex)}
            parentWidth={dimensions?.width}
            initialSlide={activePage}
            navigation={{
              nextEl: '.doc-right-arrow',
              prevEl: '.doc-left-arrow',
            }}
          >
            {pages.map((page, i) => (
              <SwiperSlide key={i}>
                <Box height="100%" my={{ xs: 0, md: 4 }}>
                  <Box
                    p={4}
                    bgcolor="white"
                    minWidth={{ xs: '100%', md: 768 }}
                    maxWidth={1024}
                    mx="auto"
                    borderRadius={1}
                    dangerouslySetInnerHTML={{
                      __html: page,
                    }}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </SwiperStyled>
          <DocumentActions
            canDownload={canDownload}
            handleDownload={handleDownload}
            file={file}
            onPrint={onPrint}
            onCancel={onCancel}
            isSaving={isSaving}
            type={type}
          />
        </Stack>
      )}
      {isFile && file && (
        <DocumentContainer>
          <PdfViewer
            file={file}
            pageProps={{ height: '100vh', scale: 1.6 }}
            onFinishReadingCharCount={handleFinishReadingCharCount}
          />
        </DocumentContainer>
      )}
      {!hasSelectedGrant && (
        <Flex fontFamily="Playfair Display" sx={{ height: '100%' }} center>
          <Title3 color={BRAND_COLORS.darkGray} sx={{ opacity: 0.3 }}>
            {loadingFileError && `Unable to display ${type}`}
            {!loadingFileError && (
              <>
                {isLoadingFile ? `Loading ${type}...` : `Select a ${type} type`}
              </>
            )}
          </Title3>
        </Flex>
      )}
      <Box position="absolute" top={72} right={121} zIndex={1000}>
        <Card sx={{ px: 3.625, py: 1.375, borderRadius: 2 }}>
          <Flex alignItems="center">
            <Body my={0}>{charCount} characters</Body>
          </Flex>
        </Card>
      </Box>
      {hasSelectedGrant && pages?.length > 1 && (
        <>
          <Box position="absolute" top="50%" left={16} zIndex={1000}>
            <ArrowIcon className="doc-left-arrow" reverse>
              <ArrowForwardIosIcon />
            </ArrowIcon>
          </Box>
          <Box position="absolute" top="50%" right={16} zIndex={1000}>
            <ArrowIcon className="doc-right-arrow">
              <ArrowForwardIosIcon />
            </ArrowIcon>
          </Box>
        </>
      )}
    </Box>
  );
};

export default DocumentViewer;
