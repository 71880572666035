import {
  Box,
  Button,
  Card,
  CardContent,
  Link as MuiLink,
  darken,
  styled,
} from '@mui/material';
import Flex from 'components/Flex';
import Link from 'components/Link';
import Text, { Large } from 'components/Text';
import { format, isValid } from 'date-fns';
import { useState } from 'react';
import { GRANTS_TYPES } from 'utils/constants';

const Description = styled(Text)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box !important',
  '-webkit-line-clamp': '3 !important',
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'normal',
});

const CardOverlay = styled(Box)({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  borderRadius: 16,
});

const CardButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5),
  fontWeight: 500,
}));

const OverlayButton = styled(CardButton)(({ theme }) => ({
  width: '75%',
  maxWidth: '250px',
  margin: theme.spacing(0.5),
}));

const UseButton = styled(OverlayButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.button.dark,
  color: theme.palette.common.white,
  '&:hover, &:focus, &:active': {
    backgroundColor: darken(theme.palette.common.black, 0.05),
  },
  '&[disabled]': {
    color: theme.palette.common.white,
  },
}));

const ViewDetailButton = styled(OverlayButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
  '&:hover, &:focus, &:active': {
    backgroundColor: 'transparent',
  },
}));

const VisitButton = styled(OverlayButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  '&:hover, &:focus, &:active': {
    backgroundColor: darken(theme.palette.common.white, 0.05),
  },
  '&[disabled]': {
    color: theme.palette.common.black,
  },
}));

const getLink = (grant) => {
  if (grant.type === GRANTS_TYPES.LINK) return grant.url;
  return `/${
    grant.type === GRANTS_TYPES.GRANT ? 'my-grants' : 'my-letters'
  }/new?grantId=${grant.id}`;
};

const GrantCard = ({
  grant,
  onClick,
  enableOverlay = true,
  cardProps,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const handleClickOpen = () => {
    onClick();
  };

  return (
    <Box
      sx={props}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      position="relative"
    >
      <Box onClick={handleClickOpen}>
        <Box
          component={Card}
          height={{ xs: '100%', md: 344 }}
          sx={{ px: 2, py: 3, cursor: 'pointer' }}
          {...cardProps}
        >
          <Box component={CardContent} height="100%">
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Box>
                <Large fontWeight={700}>{grant?.title}</Large>
                {grant?.description && (
                  <Description variant="body1" color="black" sx={{ mt: 2 }}>
                    {grant?.description}
                  </Description>
                )}
              </Box>
              {grant.type !== GRANTS_TYPES.LETTER && (
                <Box>
                  <Text
                    variant="body2"
                    color="black"
                    display={'block'}
                    marginTop={3}
                  >
                    Expiration Date:
                  </Text>
                  <Text variant="body2" color="black" fontWeight={700}>
                    {isValid(new Date(grant?.expirationDate))
                      ? format(new Date(grant.expirationDate), 'MMM dd, yyyy')
                      : 'N/A'}
                  </Text>
                  <Text
                    variant="body2"
                    color="black"
                    display={'block'}
                    marginTop={3}
                  >
                    Estimated Size of Grant Funding:
                  </Text>
                  <Text variant="body2" color="black" fontWeight={700}>
                    ${grant.value.toFixed(2)}
                  </Text>
                </Box>
              )}
            </Flex>
          </Box>
        </Box>
      </Box>
      {enableOverlay && hover && (
        <CardOverlay>
          <Flex
            sx={{
              flexDirection: 'column-reverse',
              alignItems: 'center',
              height: 1,
              p: 2,
              backgroundColor: 'background.transparentCard',
              borderRadius: 2,
            }}
          >
            <ViewDetailButton onClick={() => handleClickOpen()}>
              View Details
            </ViewDetailButton>
            <MuiLink
              underline="none"
              rel="noreferer"
              target="_blank"
              href={getLink(grant)}
              sx={{ width: '75%', maxWidth: '250px' }}
            >
              <VisitButton sx={{ width: '100%', m: 0 }}>
                Visit Source
              </VisitButton>
            </MuiLink>
            {grant.hasContent && (
              <Link
                sx={{ width: '100%', textAlign: 'center', mb: 1 }}
                underline="none"
                href={`/my-grants/new-${
                  grant.type === GRANTS_TYPES.LINK ? 'opportunity' : 'template'
                }?grantId=${grant.id}`}
              >
                <UseButton>Use this grant template</UseButton>
              </Link>
            )}
          </Flex>
        </CardOverlay>
      )}
    </Box>
  );
};
export default GrantCard;
