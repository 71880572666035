import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Card, CardContent, TextField } from '@mui/material';
import { getConfigQuery, updateConfig } from 'api/services/config';
import Field from 'components/Field';
import Flex from 'components/Flex';
import PageHeader from 'components/PageHeader';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import Spinner from 'components/Spinner';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import PaymentModes from './PaymentModes';

const ConfigPage = () => {
  const { showSnackbar } = useSnackbar();
  const { data: config, isLoading: isInitLoading } = useQuery(getConfigQuery());

  const { mutate: saveConfig, isLoading: isSaving } = useMutation(
    (data) => updateConfig(config.id, data),
    {
      onSuccess: (data) => {
        if (!data) return;
        showSnackbar({
          variant: SNACKBAR_VARIANTS.SUCCESS,
          message: 'Successfully saved application settings',
        });
      },
      onError: (err) => {
        showSnackbar({
          variant: SNACKBAR_VARIANTS.ERROR,
          message: err,
        });
      },
    }
  );

  const handleSaveConfig = (formData) => {
    const paymentModes = formData.paymentModes ?? [];
    saveConfig({
      ...formData,
      paymentModes: paymentModes.map((i) => ({ ...i, formId: +i.formId })),
    });
  };

  const methods = useForm({
    defaultValues: {
      paymentLink: '',
      paymentModes: [],
    },
  });

  const {
    formState: { errors, isDirty },
    register,
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    if (!config) return;
    reset(config);
  }, [config]);

  return (
    <>
      <PageHeader title="Application Settings" hideAction={true} />
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        height="100%"
        overflow="auto"
        px={3}
      >
        <FormProvider {...methods}>
          <Box component="form" onSubmit={handleSubmit(handleSaveConfig)}>
            {(isInitLoading || !config) && <Spinner />}
            {config && (
              <>
                <Box component={Card} width={{ xs: '100%', md: 568 }} mb={4}>
                  <CardContent>
                    {config && (
                      <Box
                        noValidate
                        autoComplete="off"
                        display="flex"
                        flexDirection="column"
                      >
                        <Field label="Payment Link">
                          <TextField
                            error={!!errors?.paymentLink}
                            helperText={errors?.paymentLink?.message}
                            name="paymentLink"
                            {...register('paymentLink', {
                              required: {
                                value: true,
                                message: 'Payment link is required',
                              },
                            })}
                            fullWidth
                          />
                        </Field>
                      </Box>
                    )}
                  </CardContent>
                </Box>
                <PaymentModes />
              </>
            )}

            <Box display="flex" my={3}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSaving}
                loadingIndicator="Saving..."
                size="large"
                disabled={!isDirty || isSaving}
                sx={{ px: 5 }}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </FormProvider>
      </Flex>
    </>
  );
};

export default ConfigPage;
