import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import Flex from 'components/Flex';
import { useIsTablet } from 'hooks/useIsTablet';
import { ReactComponent as DownloadIcon } from 'images/icons/download.svg';
import { ReactComponent as PrintIcon } from 'images/icons/print.svg';
import { useState } from 'react';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/swiper.min.css';
import { GRANTS_TYPES } from 'utils/constants';

const ActionButtons = ({
  canDownload,
  handleDownload,
  file,
  onPrint,
  onCancel,
  isSaving,
  type,
}) => (
  <Flex
    justifyContent="space-between"
    flexWrap="wrap"
    sx={{ gap: 1, flexDirection: { xs: 'column', md: 'row' } }}
  >
    <Flex
      flexWrap="wrap"
      sx={{
        gap: 2.375,
        flexDirection: { xs: 'column', md: 'row' },
        width: { xs: '100%', md: 'auto' },
      }}
    >
      <Tooltip
        arrow
        title={
          !canDownload ? (
            <Box>
              <Typography variant="body1" fontWeight={500}>
                Make sure to save your template first.
              </Typography>{' '}
              <Typography variant="body1">
                Once you have saved your template, you can download it.
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="body1">
                Download your template as PDF or Document.
              </Typography>
            </Box>
          )
        }
      >
        <Button
          size="large"
          variant="contained"
          onClick={handleDownload}
          disabled={!file}
          startIcon={
            !file ? (
              <CircularProgress size={24} />
            ) : (
              <SvgIcon sx={{ height: 16 }}>
                <DownloadIcon />
              </SvgIcon>
            )
          }
          sx={{ width: { xs: '100%', md: 'auto' } }}
        >
          Download
        </Button>
      </Tooltip>
      <Button
        size="large"
        variant="contained"
        onClick={onPrint}
        startIcon={
          <SvgIcon sx={{ height: 16 }}>
            <PrintIcon />
          </SvgIcon>
        }
        sx={{ width: { xs: '100%', md: 'auto' } }}
      >
        Print
      </Button>
    </Flex>
    <Flex
      justifyContent="end"
      flexWrap="wrap"
      sx={{
        gap: 2.375,
        flexDirection: { xs: 'column', md: 'row' },
        width: { xs: '100%', md: 'auto' },
      }}
    >
      <Button
        size="large"
        sx={{
          color: 'background.button.dark',
          width: { xs: '100%', md: 'auto' },
        }}
        onClick={onCancel}
      >
        Cancel
      </Button>
      <LoadingButton
        size="large"
        variant="contained"
        sx={{
          backgroundColor: 'background.button.dark',
          width: { xs: '100%', md: 'auto' },
        }}
        loading={isSaving}
        loadingIndicator="Saving…"
        type="submit"
      >
        Save {type === GRANTS_TYPES.LETTER ? 'Letter' : 'Grant'}
      </LoadingButton>
    </Flex>
  </Flex>
);

const DocumentActions = ({
  canDownload,
  handleDownload,
  file,
  onPrint,
  onCancel,
  isSaving,
  type,
}) => {
  const isTablet = useIsTablet();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'document-action-popover' : undefined;
  return (
    <>
      {!isTablet && (
        <Box zIndex={1000} width="100%">
          <Card
            sx={{
              px: 3,
              py: 2,
              borderRadius: 2,
              maxWidth: 1024,
              margin: '0 auto',
            }}
          >
            <ActionButtons
              canDownload={canDownload}
              handleDownload={handleDownload}
              file={file}
              onPrint={onPrint}
              onCancel={onCancel}
              isSaving={isSaving}
              type={type}
            />
          </Card>
        </Box>
      )}
      {isTablet && (
        <>
          <Flex
            position="absolute"
            top={46}
            left={0}
            px={2.75}
            zIndex={1000}
            width="100%"
            justifyContent="end"
          >
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Flex>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{ sx: { p: 2 } }}
          >
            <ActionButtons
              canDownload={canDownload}
              handleDownload={handleDownload}
              file={file}
              onPrint={onPrint}
              onCancel={onCancel}
              isSaving={isSaving}
              type={type}
            />
          </Popover>
        </>
      )}
    </>
  );
};

export default DocumentActions;
