import { Box, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { getGrants } from 'api';
import Flex from 'components/Flex';
import GrantCard from 'components/GrantCard';
import GrantDialog from 'components/GrantDialog';
import GrantFilters from 'components/GrantFilters';
import { Large } from 'components/Text';
import { useLayout } from 'contexts/Layout';
import { useInfinitePageList } from 'hooks';
import * as qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUnixDate, removeEmptyValuesFromArr } from 'utils/helpers/misc';
import ReactGA from 'react-ga4';

const DEFAULT_PAGE_SIZE = 9;

const convertBool = (value) => {
  if (value == null || value === '') return undefined;
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};

async function queryGrants({ q, sort, limit, filter, pageSize, page = 0 }) {
  const trimmedFilter = { ...filter };
  Object.keys(trimmedFilter).forEach((s) => {
    if (Array.isArray(trimmedFilter[s])) {
      trimmedFilter[s] = removeEmptyValuesFromArr(trimmedFilter[s]);
    }
  });
  if (!trimmedFilter?.['location.state']) {
    delete trimmedFilter['location.state'];
  }
  if (!trimmedFilter?.['location.country']) {
    delete trimmedFilter['location.country'];
  }
  const res = await getGrants({
    q,
    limit,
    filter: trimmedFilter,
    sort: sort ? { [sort]: -1 } : undefined,
    skip: page * pageSize,
  });
  return res;
}

const getGridSize = (size, isFaqOpen, isDrawerOpen) => {
  switch (size) {
    case 'lg': {
      if (isDrawerOpen && isFaqOpen) return 12;
      if (isDrawerOpen || isFaqOpen) return 6;
      return 4;
    }
    case 'md':
    case 'sm': {
      if (isDrawerOpen) return 12;
      return 6;
    }
    default:
      return 12;
  }
};

const GridScroller = React.forwardRef((props, ref) => (
  <Grid container spacing={2} sx={{ px: 2 }} {...props} ref={ref} />
));

const GrantsList = ({
  type,
  header,
  path,
  subheader,
  listHeader,
  cardProps,
  hasContentFilter = false,
  enableOverlay = true,
}) => {
  const { drawerOpen, isFaqOpen } = useLayout();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedGrant, setSelectedGrant] = useState();
  const searchRef = useRef('');
  const location = useLocation();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    category: [],
    subcategory: [],
    tags: [],
    value: [],
    type,
    'location.state': '',
    'location.country': '',
    status: 'published',
    expirationDate: getUnixDate(),
    hasContent: undefined,
  });

  const { items, isLoading, fetchNextPage, hasNextPage, total } =
    useInfinitePageList({
      key: 'public-grants',
      keyData: {
        limit: DEFAULT_PAGE_SIZE,
        sort: 'createdDate',
        filter: filters,
        q: searchRef.current,
      },
      pageSize: DEFAULT_PAGE_SIZE,
      handler: queryGrants,
    });

  const handleFilterChange = (value) => {
    const { hasContent: hasContentValue, ...rest } = value;
    const hasContent = convertBool(hasContentValue);
    const query = qs.stringify({
      filter: { hasContent, ...rest },
      q: searchRef.current,
    });
    ReactGA.event({
      category: 'User Actions',
      action: 'search_or_filter',
    });
    navigate(`/${path}?${query}`);
  };

  const handleSearchChanged = (value) => {
    searchRef.current = value;
    const query = qs.stringify({ filter: filters, q: value });
    navigate(`/${path}?${query}`);
  };

  const handleLoadMore = () => {
    if (!hasNextPage || isLoading) return;
    fetchNextPage();
  };

  useEffect(() => {
    if (!location.search) return;
    const searchParams = qs.parse(location.search.substring(1));
    if (!searchParams) return;
    const { q, filter } = searchParams;
    const { hasContent: hasContentQuery, ...rest } = filter || {};
    searchRef.current = q;
    const hasContent =
      hasContentQuery == null ? undefined : hasContentQuery === 'true';
    setFilters({ ...rest, hasContent });
  }, [location.search]);

  const handleOnClick = (grant) => {
    setIsDialogOpen(true);
    setSelectedGrant(grant);
  };

  return (
    <>
      <Flex flexDirection="column" height="100%" overflow="auto">
        <Typography
          variant="h1"
          marginBottom={5}
          fontFamily="Playfair Display"
          fontWeight={600}
        >
          {header}
        </Typography>
        <Typography variant="h5" my={5} maxWidth={600}>
          {subheader}
        </Typography>
        <Box
          position="sticky"
          width="100%"
          alignSelf="flex-start"
          top={0}
          bgcolor="common.white"
        >
          <GrantFilters
            search={searchRef.current}
            onSearch={handleSearchChanged}
            filters={filters}
            onChange={handleFilterChange}
            hasContentFilter={hasContentFilter}
          />
        </Box>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          mt={{ xs: 5, md: 10 }}
          mb={{ xs: 3, md: 5 }}
        >
          <Typography
            variant="h2"
            my={0}
            fontFamily="Playfair Display"
            fontWeight={500}
          >
            {listHeader}
          </Typography>
          <Large fontFamily="Playfair Display" my={0} pr={2} color="grey.600">
            {items.length} / {total}
          </Large>
        </Flex>
        <InfiniteScroll
          pageStart={0}
          loadMore={handleLoadMore}
          hasMore={hasNextPage}
          useWindow={false}
          element={GridScroller}
          threshold={0}
        >
          {items.map((grant, index) => (
            <Grid
              item
              xs={getGridSize('xs', isFaqOpen, drawerOpen)}
              sm={getGridSize('sm', isFaqOpen, drawerOpen)}
              lg={getGridSize('lg', isFaqOpen, drawerOpen)}
              key={index}
            >
              <GrantCard
                onClick={() => handleOnClick(grant)}
                enableOverlay={enableOverlay}
                key={grant.id}
                mb={2}
                grant={grant}
                cardProps={cardProps}
              />
            </Grid>
          ))}
          {isLoading && (
            <Flex center width="100%" my={4}>
              <CircularProgress size={40} />
            </Flex>
          )}
        </InfiniteScroll>
      </Flex>
      {isDialogOpen && selectedGrant && (
        <GrantDialog
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
            setSelectedGrant(undefined);
          }}
          grant={selectedGrant}
        />
      )}
    </>
  );
};

export default GrantsList;
