import qs from 'qs';
import callApi, { getValidationErrorMessage } from 'api/callApi';

const KEYS = {
  grants: (filter) => ['grants', filter],
  grant: (id) => [`grant_${id}`, id],
  downloadGrant: (id) => [`downloadGrant_${id}`, id],
  filters: () => ['filters'],
};

export const getGrants = async (filters) => {
  const query = qs.stringify(filters);
  return callApi(`/grants?${query}`, {
    needsAuthToken: true,
  });
};

const getFilters = async () => callApi('/grants/filters');

export const getGrantsById = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await callApi(`/grants/${id}`, {
    needsAuthToken: true,
  });
  return res;
};

export const getGrantFiltersQuery = () => ({
  cacheTime: 0,
  queryKey: KEYS.filters(),
  queryFn: getFilters,
});

export const getGrantsQuery = (filter) => ({
  cacheTime: 0,
  queryKey: KEYS.grants(filter),
  queryFn: ({ queryKey }) => {
    const [, _] = queryKey;
    return getGrants(_);
  },
});

export const getGrantByIdQuery = (id) => ({
  cacheTime: 0,
  queryKey: KEYS.grant(id),
  queryFn: getGrantsById,
  enabled: !!id,
});

export const createGrant = async (data) => {
  try {
    const res = callApi(`/grants`, {
      method: 'POST',
      data,
      needsAuthToken: true,
      useFullError: true,
    });
    return res;
  } catch (error) {
    throw new Error(getValidationErrorMessage(error));
  }
};

export const updateGrant = async (id, data) => {
  try {
    const res = await callApi(`/grants/${id}`, {
      method: 'PATCH',
      data,
      needsAuthToken: true,
      useFullError: true,
    });
    return res;
  } catch (error) {
    throw new Error(getValidationErrorMessage(error));
  }
};

export const previewGrant = async (id, useFakeData = true) =>
  callApi(`/grants/${id}/preview?${qs.stringify({ useFakeData })}`, {
    method: 'GET',
    needsAuthToken: true,
    responseType: 'blob',
  });

export const previewGrantAsDocx = async (id, useFakeData = true) =>
  callApi(`/grants/${id}/preview-docx?${qs.stringify({ useFakeData })}`, {
    method: 'GET',
    needsAuthToken: true,
    responseType: 'blob',
  });
