import { Warning } from '@mui/icons-material';
import { Box, Tab, Tabs, darken, lighten, styled } from '@mui/material';
import { useIsTablet } from 'hooks/useIsTablet';
import { ORG_SIDEBAR_HEIGHT, ORG_SIDEBAR_WIDTH } from 'utils/constants';

const OrgTab = styled(({ error, ...props }) => <Tab {...props} />)(
  ({ theme, error }) => ({
    height: 50,
    minHeight: 50,
    textTransform: 'initial',
    marginBottom: theme.spacing(1),
    backgroundColor: error
      ? theme.palette.error.main
      : lighten(theme.palette.primary.main, 0.5),
    color: theme.palette.common.white,
    '&.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: error
        ? theme.palette.error.main
        : theme.palette.primary.main,
      '&:hover': {
        backgroundColor: error
          ? darken(theme.palette.error.main, 0.15)
          : darken(theme.palette.primary.main, 0.15),
      },
    },
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: error
        ? darken(theme.palette.error.main, 0.15)
        : lighten(theme.palette.primary.main, 0.25),
    },
  })
);

const OrgTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.3,
  },
  '& .MuiTabs-indicator': {
    height: 50,
    minHeight: 50,
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      minHeight: 'auto',
    },
  },
}));

const OrgSidebar = ({
  organizations,
  setSelectedOrg,
  selectedOrg,
  tabList,
  errors,
}) => {
  const isTablet = useIsTablet();
  const isOrgHasError = (index) => {
    const orgErrors = errors?.organizations?.[index];
    return Object.keys(orgErrors || {}).length > 0;
  };

  const handleChange = (_, v) => {
    const selectedIndex = organizations.findIndex((org) => org.id === v);
    if (selectedIndex != null) {
      setSelectedOrg(organizations[selectedIndex]);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        width: { xs: '100%', md: 'auto' },
      }}
    >
      <OrgTabs
        orientation={isTablet ? 'horizontal' : 'vertical'}
        variant="scrollable"
        onChange={handleChange}
        value={selectedOrg.id}
        sx={{
          height: { xs: 'auto', md: ORG_SIDEBAR_HEIGHT },
          width: { xs: 'auto', md: ORG_SIDEBAR_WIDTH },
          minWidth: { xs: 'auto', md: ORG_SIDEBAR_WIDTH },
          overflowX: { xs: 'auto', md: 'hidden' },
        }}
        scrollButtons="auto"
      >
        {tabList.map((org, i) => (
          <OrgTab
            key={org.id}
            value={org.id}
            label={org.name || 'New Organization'}
            iconPosition="start"
            icon={isOrgHasError(i) ? <Warning /> : null}
            error={isOrgHasError(i)}
          />
        ))}
      </OrgTabs>
    </Box>
  );
};

export default OrgSidebar;
