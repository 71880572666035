import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  lighten,
  styled,
  SvgIcon,
  TextField,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import Flex from 'components/Flex';
import { LocationField, LocationSearchField } from 'components/LocationField';
import { Body } from 'components/Text';
import { ReactComponent as TooltipIcon } from 'images/icons/tooltip.svg';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { ORGANIZATION_TYPES } from 'utils/constants';
import OrgBoardMembers from './OrgBoardMembers';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 6px 16px',
    fontSize: 11,
  },
}));

const OrgTypeItem = styled(({ selected, ...props }) => <Box {...props} />)(
  ({ theme, selected }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: theme.spacing(2),
    border: `2px solid ${
      selected ? theme.palette.secondary.main : theme.palette.grey[200]
    }`,
    color: selected ? theme.palette.secondary.main : theme.palette.grey[800],
    padding: theme.spacing(2, 3.75),
    width: '100%',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      width: 212,
    },
    '&:hover, &:focus, &:active': {
      backgroundColor: selected
        ? lighten(theme.palette.secondary.main, 0.9)
        : theme.palette.grey[100],
    },
    marginRight: theme.spacing(1),
  })
);

const OrgButton = styled(({ ...props }) => (
  <Button
    {...props}
    variant="contained"
    size="medium"
    sx={{ width: { xs: '100%', md: 'auto' } }}
  />
))();

const OrgTypeSelect = ({ selected, onChange }) => {
  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <Flex flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 2, md: 0 }}>
      <OrgTypeItem
        selected={selected === ORGANIZATION_TYPES.NON_PROFIT}
        onClick={() => handleChange(ORGANIZATION_TYPES.NON_PROFIT)}
      >
        <Body bold my={0}>
          Non-profit
        </Body>
        {selected === ORGANIZATION_TYPES.NON_PROFIT ? (
          <CheckBoxIcon />
        ) : (
          <CheckBoxOutlineBlankIcon />
        )}
      </OrgTypeItem>
      <OrgTypeItem
        selected={selected === ORGANIZATION_TYPES.FOR_PROFIT}
        onClick={() => handleChange(ORGANIZATION_TYPES.FOR_PROFIT)}
      >
        <Body bold my={0}>
          Profit
        </Body>
        {selected === ORGANIZATION_TYPES.FOR_PROFIT ? (
          <CheckBoxIcon />
        ) : (
          <CheckBoxOutlineBlankIcon />
        )}
      </OrgTypeItem>
    </Flex>
  );
};

const OrgForm = ({
  control,
  setValue,
  handleSubmit,
  handleInvalidFormData,
  onSubmit,
  errors,
  isLoading,
  organizations,
  handleAddOrg,
  tabList,
  setTabList,
  removeOrg,
  selectedOrg,
  disableMultipleOrgs = false,
}) => {
  const [location, setLocation] = useState('');

  const handleRemoveOrg = () => {
    const index = organizations?.findIndex((o) => o.id === selectedOrg.id);
    if (index >= 0) {
      removeOrg(index);
    }
  };

  const handleNameChange = (e, field, org, index) => {
    const list = tabList;
    list[index] = { id: org.id, name: e.target.value };
    setTabList([...list]);
    field.onChange(e);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit, handleInvalidFormData)}
      sx={{
        ml: { xs: 0, md: 2 },
        width: '100%',
      }}
    >
      {organizations.map((org, index) => (
        <TabPanel
          key={org.id}
          value={org.id}
          index={index}
          sx={{ px: { xs: 0, md: 3 } }}
        >
          <Box mb={{ xs: 3, md: 5 }}>
            <Controller
              control={control}
              name={`organizations.${index}.organizationType`}
              render={({ field }) => (
                <OrgTypeSelect
                  selected={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              What's your organization's name?
              <BootstrapTooltip title="Enter the name of your organization">
                <SvgIcon
                  sx={{
                    height: 27,
                    verticalAlign: 'middle',
                    marginLeft: '10px',
                  }}
                >
                  <TooltipIcon />
                </SvgIcon>
              </BootstrapTooltip>
            </Body>
            <Controller
              control={control}
              rules={{
                required: { value: true, message: 'Name is required' },
              }}
              name={`organizations.${index}.name`}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Answer here"
                  error={!!errors?.organizations?.[index]?.name}
                  helperText={errors?.organizations?.[index]?.name?.message}
                  onChange={(e) => handleNameChange(e, field, org, index)}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              What's your mission statement?
              <BootstrapTooltip title="Make your mission statement precise">
                <SvgIcon
                  sx={{
                    height: 27,
                    verticalAlign: 'middle',
                    marginLeft: '10px',
                  }}
                >
                  <TooltipIcon />
                </SvgIcon>
              </BootstrapTooltip>
            </Body>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Mission statement is required',
                },
                maxLength: {
                  value: 500,
                  message: 'Mission statement must be less than 500 characters',
                },
              }}
              name={`organizations.${index}.mission`}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Answer here"
                  multiline
                  rows={4}
                  error={!!errors?.organizations?.[index]?.mission}
                  helperText={errors?.organizations?.[index]?.mission?.message}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              Program Design
              <BootstrapTooltip title="Be specific with Program Design">
                <SvgIcon
                  sx={{
                    height: 27,
                    verticalAlign: 'middle',
                    marginLeft: '10px',
                  }}
                >
                  <TooltipIcon />
                </SvgIcon>
              </BootstrapTooltip>
            </Body>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Prgoram design is required',
                },
              }}
              name={`organizations.${index}.programDesign`}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Answer here"
                  error={!!errors?.organizations?.[index]?.programDesign}
                  helperText={
                    errors?.organizations?.[index]?.programDesign?.message
                  }
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              EIN
              <BootstrapTooltip title="Employer's identification number within the organization">
                <SvgIcon
                  sx={{
                    height: 27,
                    verticalAlign: 'middle',
                    marginLeft: '10px',
                  }}
                >
                  <TooltipIcon />
                </SvgIcon>
              </BootstrapTooltip>
            </Body>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'EIN is required',
                },
              }}
              name={`organizations.${index}.employeeInternalId`}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Employer's internal number"
                  error={!!errors?.organizations?.[index]?.employeeInternalId}
                  helperText={
                    errors?.organizations?.[index]?.employeeInternalId?.message
                  }
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              Expected results/outcomes
              <BootstrapTooltip title="Specify the expected results and outcomes">
                <SvgIcon
                  sx={{
                    height: 27,
                    verticalAlign: 'middle',
                    marginLeft: '10px',
                  }}
                >
                  <TooltipIcon />
                </SvgIcon>
              </BootstrapTooltip>
            </Body>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Expected results/outcomes is required',
                },
                maxLength: {
                  value: 500,
                  message:
                    'Expected results/outcomes must be less than 500 characters',
                },
              }}
              name={`organizations.${index}.expectedResults`}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Answer here"
                  multiline
                  rows={4}
                  error={!!errors?.organizations?.[index]?.expectedResults}
                  helperText={
                    errors?.organizations?.[index]?.expectedResults?.message
                  }
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              Statement of Need
              <BootstrapTooltip title="Enter your organization's statement of need">
                <SvgIcon
                  sx={{
                    height: 27,
                    verticalAlign: 'middle',
                    marginLeft: '10px',
                  }}
                >
                  <TooltipIcon />
                </SvgIcon>
              </BootstrapTooltip>
            </Body>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Statement of need is required',
                },
                maxLength: {
                  value: 500,
                  message: 'Statement of need must be less than 500 characters',
                },
              }}
              name={`organizations.${index}.statementOfNeed`}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Answer here"
                  multiline
                  rows={4}
                  error={!!errors?.organizations?.[index]?.statementOfNeed}
                  helperText={
                    errors?.organizations?.[index]?.statementOfNeed?.message
                  }
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              Enter your organization's address
            </Body>
            <LocationField>
              <LocationSearchField
                onMapSelect={(map) => {
                  setLocation(map.name);
                  setValue(`organizations.${index}.address`, map.address);
                  setValue(`organizations.${index}.city`, map.city);
                  setValue(`organizations.${index}.state`, map.state);
                  setValue(`organizations.${index}.county`, map.county);
                  setValue(`organizations.${index}.country`, map.country);
                  setValue(`organizations.${index}.zipCode`, map.zipCode);
                }}
                location={location}
              />
            </LocationField>
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              Address
            </Body>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Address is required',
                },
              }}
              name={`organizations.${index}.address`}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Address"
                  error={!!errors?.organizations?.[index]?.address}
                  helperText={errors?.organizations?.[index]?.address?.message}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              State
            </Body>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'State is required',
                },
              }}
              name={`organizations.${index}.state`}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="State"
                  error={!!errors?.organizations?.[index]?.state}
                  helperText={errors?.organizations?.[index]?.state?.message}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              City
            </Body>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'City is required',
                },
              }}
              name={`organizations.${index}.city`}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="City"
                  error={!!errors?.organizations?.[index]?.city}
                  helperText={errors?.organizations?.[index]?.city?.message}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              Country
            </Body>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Country is required',
                },
              }}
              name={`organizations.${index}.country`}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Country"
                  error={!!errors?.organizations?.[index]?.country}
                  helperText={errors?.organizations?.[index]?.country?.message}
                />
              )}
            />
          </Box>
          <Box mb={5}>
            <Body mt={0} mb={1.75} bold>
              Zip Code
            </Body>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Zip Code is required',
                },
              }}
              name={`organizations.${index}.zipCode`}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Zip Code"
                  error={!!errors?.organizations?.[index]?.zipCode}
                  helperText={errors?.organizations?.[index]?.zipCode?.message}
                />
              )}
            />
          </Box>
          <Box>
            <OrgBoardMembers
              control={control}
              orgIndex={index}
              errors={errors}
            ></OrgBoardMembers>
          </Box>
        </TabPanel>
      ))}
      <Flex
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={{ xs: 1, md: 2 }}
        mb={5}
        py={3}
        px={{ xs: 0, md: 3 }}
      >
        {!disableMultipleOrgs && (
          <>
            <OrgButton color="primary" onClick={handleAddOrg}>
              Add
            </OrgButton>
            <OrgButton color="secondary" onClick={handleRemoveOrg}>
              Remove
            </OrgButton>
          </>
        )}
        <OrgButton
          type="submit"
          disabled={isLoading}
          sx={{ backgroundColor: 'background.button.dark' }}
        >
          Done
          {isLoading && (
            <Box
              component={CircularProgress}
              ml={2}
              size={16}
              color="inherit"
            />
          )}
        </OrgButton>
      </Flex>
    </Box>
  );
};

export default OrgForm;
