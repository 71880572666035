import Layout, { DefaultContainer } from 'components/Layout';
import NoFaqLayout from 'components/Layout/NoFaq';
import UnauthorizedLayout from 'components/Layout/Unauthorized';
import ChangePasswordPage from 'pages/ChangePassword';
import ConfigPage from 'pages/Config';
import Faq from 'pages/Faq';
import ForgotPasswordPage from 'pages/ForgotPassword';
import HomePage from 'pages/Home';
import LettersPage from 'pages/Letters';
import ManageGrantsPage from 'pages/ManageGrants';
import CreateGrantPage from 'pages/ManageGrants/Create';
import EditGrantPage from 'pages/ManageGrants/Edit';
import ManageUserGrants from 'pages/ManageUserGrants';
import MyGrantsPage from 'pages/MyGrants';
import GrantEditPage from 'pages/MyGrants/Edit';
import NewGrantPage from 'pages/MyGrants/New';
import NotFoundPage from 'pages/NotFound';
import NotFullyPaid from 'pages/NotFullyPaid';
import OpportunitiesPage from 'pages/Opportunities';
import OrgConfirmation from 'pages/OrgConfirmation';
import OrgSetup from 'pages/OrgSetup';
import ResetPasswordPage from 'pages/ResetPassword';
import SignInPage from 'pages/SignIn';
import SignUpPage from 'pages/SignUp';
import TemplatesPage from 'pages/Templates';
import UserProfilePage from 'pages/UserProfile';
import UsersPage from 'pages/Users';
import EditUsersPage from 'pages/Users/Edit';
import VerifyEmailPage from 'pages/VerifyEmail';
import { useRoutes } from 'react-router-dom';
import { GRANTS_TYPES } from 'utils/constants';

function AppRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: (
            <DefaultContainer>
              <HomePage />
            </DefaultContainer>
          ),
        },
        {
          path: 'account',
          element: (
            <DefaultContainer overflow="auto">
              <UserProfilePage />
            </DefaultContainer>
          ),
        },
        {
          path: 'change-password',
          element: (
            <DefaultContainer overflow="auto">
              <ChangePasswordPage />
            </DefaultContainer>
          ),
        },
        {
          path: 'templates',
          element: (
            <DefaultContainer height="100%" overflow="hidden">
              <TemplatesPage />
            </DefaultContainer>
          ),
        },
        {
          path: 'letters',
          element: (
            <DefaultContainer height="100%" overflow="hidden">
              <LettersPage />
            </DefaultContainer>
          ),
        },
        {
          path: 'opportunities',
          element: (
            <DefaultContainer height="100%" overflow="hidden">
              <OpportunitiesPage />
            </DefaultContainer>
          ),
        },
        {
          path: 'my-grants',
          element: (
            <DefaultContainer>
              <MyGrantsPage type={GRANTS_TYPES.GRANT} />
            </DefaultContainer>
          ),
        },
        {
          path: 'my-grants/new-opportunity',
          element: <NewGrantPage type={GRANTS_TYPES.LINK} />,
        },
        {
          path: 'my-grants/new-template',
          element: <NewGrantPage type={GRANTS_TYPES.GRANT} />,
        },
        {
          path: 'my-grants/:id',
          element: <GrantEditPage type={GRANTS_TYPES.GRANT} />,
        },
        {
          path: 'my-letters',
          element: (
            <DefaultContainer>
              <MyGrantsPage type={GRANTS_TYPES.LETTER} />
            </DefaultContainer>
          ),
        },
        {
          path: 'my-letters/new',
          element: <NewGrantPage type={GRANTS_TYPES.LETTER} />,
        },
        {
          path: 'my-letters/:id',
          element: <GrantEditPage type={GRANTS_TYPES.LETTER} />,
        },
        {
          path: 'faqs',
          element: (
            <DefaultContainer height="100%" overflow="auto">
              <Faq />
            </DefaultContainer>
          ),
        },
        {
          path: '*',
          element: (
            <DefaultContainer height="100%" overflow="auto">
              <NotFoundPage />
            </DefaultContainer>
          ),
        },
      ],
    },
    {
      path: '/admin',
      element: <NoFaqLayout />,
      children: [
        { path: 'manage-grants', element: <ManageGrantsPage /> },
        { path: 'manage-grants/:id/edit', element: <EditGrantPage /> },
        { path: 'manage-grants/new', element: <CreateGrantPage /> },
        { path: 'manage-user-grants', element: <ManageUserGrants /> },
        { path: 'manage-users', element: <UsersPage /> },
        { path: 'manage-users/:id', element: <EditUsersPage /> },
        { path: 'settings/', element: <ConfigPage /> },
        { path: '*', element: <NotFoundPage /> },
      ],
    },
    {
      path: '/auth',
      element: <UnauthorizedLayout />,
      children: [
        { path: 'forgot-password', element: <ForgotPasswordPage /> },
        { path: 'reset-password', element: <ResetPasswordPage /> },
        { path: 'sign-in', element: <SignInPage /> },
        { path: 'sign-up', element: <SignUpPage /> },
        { path: 'verify-account', element: <NotFullyPaid /> },
        { path: 'org-confirmation', element: <OrgConfirmation /> },
        { path: 'org-setup', element: <OrgSetup /> },
        { path: 'verify-email', element: <VerifyEmailPage /> },
        {
          path: 'change-password',
          element: <ChangePasswordPage isForceResetPassword />,
        },
        { path: '*', element: <NotFoundPage /> },
      ],
    },
  ]);
}

export default AppRoutes;
