import { Box, Button, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import Flex from 'components/Flex';
import Modal from 'components/Modal';
import { Body, Title2 } from 'components/Text';

export default function DownloadDialog({
  open,
  onClose,
  onDownloadPdf,
  onDownloadDoc,
}) {
  return (
    <Modal maxWidth={538} open={open} onClose={onClose}>
      <Box mb={3}>
        <Flex sx={{ mb: 3, justifyContent: 'space-between' }}>
          <Title2 my={0} fontFamily="Playfair Display">
            Download
          </Title2>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Flex>
        <Body my={0}>What file format do you want?</Body>
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <Box
          component={Button}
          variant="contained"
          px={4.5}
          py={2}
          sx={{ backgroundColor: 'background.button.dark' }}
          onClick={onDownloadPdf}
          mr={2}
        >
          PDF
        </Box>
        <Box
          component={Button}
          variant="contained"
          px={4.5}
          py={2}
          sx={{ backgroundColor: 'background.button.dark' }}
          onClick={onDownloadDoc}
          mr={2}
        >
          Word
        </Box>
      </Box>
    </Modal>
  );
}
