import { Box, Button } from '@mui/material';
import Modal from 'components/Modal';
import { Body, Title2 } from 'components/Text';

export default function ConfirmationDialog({
  open,
  onClose,
  onConfirm,
  title,
  content,
  modalProps,
  titleProps,
  contentProps,
  confirmButtonProps,
  cancelButtonProps,
  textsContainerProps,
  buttonsContainerProps,
  confirmLabel = 'Yes',
  cancelLabel = 'No',
}) {
  return (
    <Modal maxWidth={538} open={open} onClose={onClose} {...modalProps}>
      <Box mb={3} {...textsContainerProps}>
        {title && (
          <Title2 mt={0} mb={3} fontFamily="Playfair Display" {...titleProps}>
            {title}
          </Title2>
        )}
        {content && (
          <Body my={0} {...contentProps}>
            {content}
          </Body>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        {...buttonsContainerProps}
      >
        <Box
          component={Button}
          variant="contained"
          px={4.5}
          py={2}
          sx={{ backgroundColor: 'background.button.dark' }}
          onClick={onConfirm}
          mr={2}
          {...confirmButtonProps}
        >
          {confirmLabel}
        </Box>
        <Button variant="text" onClick={onClose} {...cancelButtonProps}>
          {cancelLabel}
        </Button>
      </Box>
    </Modal>
  );
}
