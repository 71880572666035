import { styled, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const StyledText = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    color: theme.palette.grey['800'],
    opacity: 0.5,
  },
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.grey['400']}`,
    backgroundColor: theme.palette.background.row,
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(2),
  },
}));

const DatePicker = ({ textFieldProps, ...props }) => (
  <DesktopDatePicker
    inputFormat="MM/dd/yyyy"
    renderInput={(params) => (
      <StyledText fullWidth {...params} {...textFieldProps} />
    )}
    fullWidth
    {...props}
  />
);

export default DatePicker;
